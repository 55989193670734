import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {UpdateService} from "../../services/update/update.service";

@Component({
  selector: 'app-client-create',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {

  @Input() modal;

  name = '';
  street = '';
  zip_code = '';

  showMore = false;

  already_requested = false;
  custom_documentation;

  constructor(private createService: CreateService, private updateService: UpdateService) {
  }

  ngOnInit(): void {
  }

  createClient() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createClient(this.name, this.street, this.zip_code).subscribe(data => {
        if (data.success) {
          this.createService.commonsService.showSuccessToast('Info', 'Der Kunde wurde erfolgreich erstellt!');
          if (this.custom_documentation != null) {
            this.updateService.updateClient(data.data, 'custom_documentation', this.custom_documentation).subscribe(
              (tdata) => {
                this.updateService.commonsService.showSuccessToast('Info', 'Kunde wurde erfolgreich bearbeitet');
              }, error => {
                this.updateService.errorHandler(error);
                this.updateService.commonsService.showErrorToast(error, 'Kunde konnte nicht bearbeitet werden [...]', -1);
              }
            );
          }
          this.modal.close();
        } else {
          this.already_requested = false;
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Kunde konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }

}
