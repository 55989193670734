import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {DataService} from '../../services/data/data.service';
import {LoginService} from '../../services/login/login.service';
import {DatePipe} from '@angular/common';
import {Project} from '../../interfaces/ProjectInterfaces';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-create-pressure-documentation',
  templateUrl: './create-pressure-documentation.component.html',
  styleUrls: ['./create-pressure-documentation.component.scss']
})
export class CreatePressureDocumentationComponent implements OnInit {

  @Input() modal;
  @Input() dispenser_id;
  @Input() project: Project;

  temperature;
  pressure = 3;
  pressure_time = 30;
  pressure_medium = 'Luft';
  pressure_passed = true;
  override_user_name = null;
  user_name = null;
  keyword = 'name';
  available = [];
  override_date;
  today;

  already_requested = false;

  constructor(private createService: CreateService, private dataService: DataService,
              private loginService: LoginService, public datePipe: DatePipe,
              private cookieService: CookieService) {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  setTemperature() {
    const tmp_cookie = this.cookieService.get('pressure_documentation');

    if (JSON.parse(tmp_cookie)[this.override_date == null ? this.today : this.override_date] != null) {
      this.temperature = JSON.parse(tmp_cookie)[this.override_date == null ? this.today : this.override_date];
    }
  }

  ngOnInit(): void {
    const jwt_data = this.loginService.getUsername();
    this.override_user_name = jwt_data.name;
    this.user_name = jwt_data.name;
    const tmp_user_name = this.cookieService.get('pressure_default_signature');
    this.override_user_name = tmp_user_name === '' || tmp_user_name.length === 0 ? jwt_data.name : tmp_user_name;
  }

  createPressureDocumentation() {
    if (this.project.client != null && this.project.client.custom_documentation) {
      window.alert('Achtung! Dieser Auftraggeber benutzt eine eigene Dokumentation');
      return;
    } else if (this.project.client != null && ((this.project.client.street == null || this.project.client.zip_code == null)
      || (this.project.client.street.length < 2 || this.project.client.zip_code.length < 2))) {
      window.alert('Bei dem Kunde wurde noch keine Adresse hinterlegt!');
      return;
    } else if (!this.already_requested) {
      this.already_requested = true;
      const tmp = this.cookieService.get('pressure_documentation');
      let value;
      if (tmp === '') {
        value = {};
      } else {
        value = JSON.parse(tmp);
      }
      value[this.override_date == null ? this.today : this.override_date] = this.temperature;

      this.cookieService.set('pressure_documentation', JSON.stringify(value));


      this.createService.createPressureDocumentation(this.dispenser_id, this.temperature, this.pressure, this.pressure_time,
        this.pressure_medium, this.pressure_passed,
        this.override_user_name === this.user_name ? null : this.override_user_name, this.override_date
      ).subscribe(data => {
        if (data.success) {
          this.modal.close();
        } else {
          this.already_requested = false;
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Dichtheitsprüfung konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }

  async onFocused(e) {

    this.available = await this.dataService.getAllUser();

  }

  selectEvent(item) {
    this.override_user_name = item.name;
  }

  set_default_signature() {
    this.cookieService.set('pressure_default_signature', this.override_user_name);
  }

}
