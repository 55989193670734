<mat-form-field [style.fontSize]="12" style="width: 100%">
  <mat-label>{{text}}</mat-label>
  <textarea #autosize="cdkTextareaAutosize"
            (input)="onChange.emit($event.target.value)"
            [value]="value"
            cdkTextareaAutosize
            cols="2"
            matInput

            rows="5"></textarea>
</mat-form-field>
