import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {Constants} from '../../Constants';
import {ServerResponse, ServerResponseSingle, ServerResponseWithData} from '../../interfaces/DefaultResponse';
import {CommonsService} from '../commons/commons.service';

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  createMode = 1;

  constructor(private http: HttpClient, private loginService: LoginService, public commonsService: CommonsService) {
  }


  async errorHandler(error) {
    if (error.status === 401) {
      if (!(await this.loginService.verifyToken())) {
        this.loginService.server_logout();
      }
    }
  }

  createProject(projectName, clientId, startDate, endDate, street: string, zipCode: string,
                description: string, secondary_client_id: number = null) {

    const token = this.loginService.getSessionToken();

    if (description === undefined || description.length === 0) {
      description = ' ';
    }


    if (street === undefined) {
      street = '';
    }
    if (zipCode === undefined) {
      zipCode = '';
    }

    return this.http.post<ServerResponseSingle<number>>(Constants.PYTHON_SERVER_URL + 'project/create', {
      session: token,
      name: projectName,
      client_id: clientId,
      start_date: startDate,
      end_date: endDate,
      street,
      zip_code: zipCode,
      description,
      secondary_client_id
    });
  }

  reUploadFloor(floor_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'documentation/reUploadFloor', {
      session: token,
      floor_id
    });
  }

  reUploadSection(section_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'documentation/reUploadSection', {
      session: token,
      section_id
    });
  }

  sendAdminCommand(
    cmd,
    admn_token,
    clientId,
    projectId,
    sectionId,
    floorId,
    dispenserId,
    documentationId,
    week_planId,
    prevent_rename = true) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'admin/send_daemon_command', {
      session: token,
      command: cmd,
      admin_token: admn_token,
      client_id: clientId,
      project_id: projectId,
      section_id: sectionId,
      floor_id: floorId,
      dispenser_id: dispenserId,
      documentation_id: documentationId,
      week_plan_id: week_planId,
      prevent_rename
    });
  }

  createSection(project_id, section_name, start_date = null, end_date = null, override_create_folder = null) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseSingle<number>>(Constants.PYTHON_SERVER_URL + 'section/create', {
      session: token,
      project_id,
      section_name,
      start_date,
      end_date,
      override_create_folder
    });
  }

  createFloor(project_id, floor_name, section_id, start_date = null, end_date = null, override_create_folder = null) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseSingle<number>>(Constants.PYTHON_SERVER_URL + 'floor/create', {
      session: token,
      project_id,
      floor_name,
      section_id,
      start_date,
      end_date,
      override_create_folder
    });
  }


  createDispenser(floor_id, name, override_create_folder = null) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseSingle<number>>(Constants.PYTHON_SERVER_URL + 'dispenser/create', {
      session: token,
      floor_id,
      name,
      override_create_folder
    });
  }

  createClient(name, street, zip_code) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponseSingle<number>>(Constants.PYTHON_SERVER_URL + 'client/create', {
      session: token,
      name,
      street,
      zip_code
    });
  }

  createClientContact(client_id, first_name, last_name, phone, mail) {

    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/contact/create', {
      session: token,
      client_id,
      first_name,
      last_name,
      phone,
      mail
    });
  }

  createClientContactWeek(week_plan_id, contact_id) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/contact/addWeekPlan', {
      session: token,
      week_plan_id,
      contact_id
    });
  }

  createShift(week_plan_id, week_index, user_name) {

    const token = this.loginService.getSessionToken();
    return this.http.post<ServerResponseWithData>(Constants.PYTHON_SERVER_URL + 'shift/create', {
      session: token,
      week_plan_id,
      week_index,
      user_name
    });
  }

  createTodoTemplate(name, shortcut, text, unit) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/template/create', {
      session: token,
      name,
      shortcut,
      text,
      unit
    });
  }

  createWeekPlanTodo(week_plan_id, template_id, value) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseWithData>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/create', {
      session: token,
      week_plan_id,
      template_id,
      value
    });
  }

  createWeekPlanTrash(week_plan_id, template_id) {

    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseWithData>(Constants.PYTHON_SERVER_URL + 'week_plan/trash/create', {
      session: token,
      week_plan_id,
      template_id,
    });
  }

  createWeekPlan(project_id, week, description, area_description, responsible_user_name, year, note, area_type, area_id) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponseWithData>(Constants.PYTHON_SERVER_URL + 'week_plan/create', {
      session: token,
      project_id,
      week,
      description,
      area_description,
      responsible_user_name,
      area_type,
      area_id,
      year,
      note
    });
  }

  createTrashTemplate(name, text) {
    const token = this.loginService.getSessionToken();
    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/trash/template/create', {
      session: token,
      name,
      text
    });
  }

  createTeam(team_name: string) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponseWithData>(Constants.PYTHON_SERVER_URL + 'team/create', {
      session: token,
      team_name
    });
  }

  createPressureDocumentation(dispenser_id, temperature, pressure, pressure_time,
                              pressure_medium, pressure_passed, override_user_name, override_date) {
    const token = this.loginService.getSessionToken();

    const body: any = {
      session: token,
      dispenser_id, temperature, pressure,
      pressure_time, pressure_medium, pressure_passed, override_date
    };
    if (override_user_name != null) {
      body.override_user_name = override_user_name;
    }

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'documentation/createPressure', body);
  }

  createDispenserDocumentation(dispenser_id, data: Array<{ key: string, value: number }>) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'documentation/create', {
      session: token,
      dispenser_id, data
    });
  }

  createUser(name, pre_name, email, phone_number, password) {
    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'auth/register', {
      last_name: name,
      pre_name,
      mail: email,
      phone: phone_number,
      password
    });

  }
}
