import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent implements OnInit {
  @Input() name;
  @Input() design = null;
  @Input() icon = null;
  @Input() exact = false;
  @Input() key;
  @Input() prevent_navigation = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  open(event: MouseEvent) {
    // prevent href to fire.
    // href will work only for middle mouse button click
    event.preventDefault();

    // open in new tab when click + ctrl
    if (event.ctrlKey) {
      return window.open('/' + this.key, '_blank');
    }

    if (!this.prevent_navigation) {
      this.router.navigate(['/' + this.key]);
    }

  }

}
