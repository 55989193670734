import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-week-input',
  templateUrl: './week-input.component.html',
  styleUrls: ['./week-input.component.scss']
})
export class WeekInputComponent implements OnInit {
  @Output() selected_kw: EventEmitter<number> = new EventEmitter<number>();

  constructor(private datePipe: DatePipe, private dataService: DataService) {
  }

  ngOnInit(): void {
  }

  input_end_date(event) {
    this.selected_kw.emit(this.dataService.getWeekNumber(event.value));
  }
}
