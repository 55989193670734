<div>
  <details *ngIf="project.visible"
           class="project {{darken ? 'dark-III' : 'blue'}} summary-box hover-animation non-clickable-object">

    <summary (click)="requestProjectData();  selected_channel.emit(project.channel_id)" class="heading">
      <div class="align-mid-vertical">
        {{project.name}} {{project.street}}, {{project.zip_code}}
      </div>

      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="openSharepointFolder()" mat-menu-item>
          <mat-icon>folder</mat-icon>
          <span>Sharepoint öffnen</span>
        </button>

        <button (click)="open(modal, $event, true)" mat-menu-item>
          <mat-icon>create</mat-icon>
          <span>Projekt bearbeiten</span>
        </button>
        <button (click)="open(createSection, $event)" mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>Bauabschnitt hinzufügen</span>
        </button>
        <button (click)="open(createFloor, $event)" mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>Etage hinzufügen (ohne Abschnitt)</span>
        </button>
        <button (click)="open(verify, $event)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Projekt löschen</span>
        </button>
      </mat-menu>


    </summary>

    <div class="summary-content-box">
      <div class="small-information-box hover-animation">
        <b>Vom</b> {{formatDate(project.start_date)}} <b>bis zum</b> {{formatDate(project.end_date)}}<br><br>
        <b>Auftraggeber</b>
        <ul *ngIf="project.client != null">
          <li *ngIf="project.client.name != null">{{project.client.name}}</li>
          <li *ngIf="project.client.street != null">{{project.client.street}}</li>
          <li *ngIf="project.client.zip_code != null">{{project.client.zip_code}}</li>
        </ul>

        <b *ngIf="project.secondary_client != null">Zweiter Auftraggeber</b>
        <ul *ngIf="project.secondary_client != null">
          <li *ngIf="project.secondary_client.name != null">{{project.secondary_client.name}}</li>
          <li *ngIf="project.secondary_client.street != null">{{project.secondary_client.street}}</li>
          <li *ngIf="project.secondary_client.zip_code != null">{{project.secondary_client.zip_code}}</li>
        </ul>

        <b>Anschrift:</b>
        <ul>
          <li>{{project.street}}</li>
          <li>{{project.zip_code}}</li>
        </ul>

        <b>Beschreibung:</b>
        <ul>
          <li *ngIf="this.noteTooLong(project.description)[1]">
            {{this.noteTooLong(project.description)[0]}}
            <p (click)="open(showMore, $event)">[ mehr anzeigen ]</p>
          </li>


          <li *ngIf="!this.noteTooLong(project.description)[1]">
            <p [innerHTML]="project.description | Nl2br"></p>
          </li>
        </ul>

        <br>
        <b>Projekt ID:</b>
        <ul>
          <li>{{project.id}}</li>
        </ul>

        <b>Projekt-Ersteller:</b>
        <ul>
          <li>{{project.creator_name}}</li>
        </ul>


      </div>

      <div class="inline-box align-mid-vertical">
        <h3>Wochenpläne:</h3>
        <span
          (click)="open(selectWeek, $event)"
          class="material-icons">
            add_box
        </span>
      </div>
      <app-week [project]="project" [weeks]="project_weeks"></app-week>

      <div class="inline-box align-mid-vertical">
        <h3>Bauabschnitte:</h3>
        <span
          (click)="open(createSection, $event, true)"
          class="material-icons">
            add_box
        </span>
      </div>

      <div *ngFor="let s of project.section">
        <app-section (channel_id)="selected_channel.emit($event)" (delete)="this.deleteSection(s)" [project]="project"
                     [section]="s"></app-section>
      </div>
      <p *ngIf="project.section == null || project.section.length == 0">Keine Bauabschnitte vorhanden</p>

      <div class="inline-box align-mid-vertical">
        <h3>Etagen:</h3>
        <span
          (click)="open(createFloor, $event)"
          class="material-icons">
            add_box
        </span>
      </div>
      <div *ngFor="let f of project.floor">
        <app-floor (channel_id)="selected_channel.emit($event)" (delete)="deleteFloor(f)" (update)="loadFloor($event)"
                   [floor]="f"
                   [project]="project"></app-floor>
      </div>
      <p *ngIf="project.floor == null || project.floor.length == 0">Keine Etagen vorhanden</p>

    </div>
  </details>

  <div (click)="open(projectCreate, $event)" *ngIf="false" class="project-create-button"> +</div>

</div>

<!-- POPUPS: -->
<ng-template #modal let-modal>
  <app-project-update [modal]="modal" [project]="project"></app-project-update>
</ng-template>

<ng-template #createSection let-modal>
  <app-section-create [modal]="modal" [project_id]="project.id"></app-section-create>
</ng-template>

<ng-template #createFloor let-modal>
  <app-create-floor [modal]="modal" [project_id]="project.id"></app-create-floor>
</ng-template>

<ng-template #verify let-modal>
  <app-verify (verified)="verified($event, project.id)"
              [description]="('Projekt ' + project.name + ' löschen?')"
              [modal]="modal"></app-verify>
</ng-template>

<ng-template #showMore let-modal>
  <app-show-more [modal]="modal" [text]="project.description" [title]="project.name"></app-show-more>
</ng-template>

<ng-template #selectWeek let-modal>
  <app-week-select (choose_week)="tmp_choose_week = $event; tmp_choose_year= 2021; open(createWeek, null)"
                   [modal]="modal"></app-week-select>
</ng-template>

<ng-template #createWeek let-modal>
  <app-create-week-plan [modal]="modal" [project]="project" [week]="tmp_choose_week"
                        [year]="tmp_choose_year"></app-create-week-plan>
</ng-template>

<ng-template #projectCreate let-modal>


  <app-project-create [modal]="modal"></app-project-create>
</ng-template>
