<div style="display: flex; width: 100%">
  <div class="informationBox" id="information-box-id">

    <div style="display:flex">

      <div>

        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; margin-top: .2vw; margin-left: .2vw">

            <app-hover-balls [index]="index" [weekItem]="weekItem"></app-hover-balls>
            <div class="align-mid-vertical" style="margin-left: 0.5em; font-size: 120%">
              <app-date [week_plan]="weekItem"></app-date>
            </div>
          </div>

        </div>


        <div style="display: flex; overflow: visible">
          <div style="display: flex; width: 100%">
            <app-info-one (request_reload)="request_reload.emit()" [pro]="pro" [weekItem]="weekItem"
                          style="width: 15%;height: 100% ; min-width: 15em"></app-info-one>

            <app-info-two [client]="weekItem.client" [secondary_client]="weekItem.secondary_client"
                          [weekItem]="weekItem"
                          style="width: 10%; min-width: 10em">

            </app-info-two>
            <app-info-five [weekItem]="weekItem" style="width: 10%; min-width: 10em"></app-info-five>

            <app-info-three (request_reload)="request_reload.emit()" [pro]="pro" [weekItem]="weekItem"
                            [week_number]="week_number"
                            [year]="year"
                            style="width: 15%; min-width: 15em">

            </app-info-three>

          </div>
        </div>
      </div>
      <div style="display: flex; width: 100%">
        <app-project-week-days (request_reload)="request_reload.emit()" [ok_pressed]="ok_pressed"
                               [project_id]="pro.id" [shifts]="weekItem.shifts" [weekItem]="weekItem"

                               [week_number]="this.week_number"
                               [year]="year"
                               style="width: calc(100% - 15em); margin-top: 0.1em; display: inline-block; float: left;">
        </app-project-week-days>
        <div style="display: flex; flex-direction: column;">
          <div style="text-align: right;">

          <span *ngIf="weekItem.week_plan_set" [matMenuTriggerFor]="menu" class="material-icons"
                style="cursor: pointer; line-height: 2em;">create</span>

            <mat-menu #menu="matMenu" style="color: black">
              <button (click)="switchEditMode()" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span style="color: black">Wochenplan bearbeiten</span>
              </button>
              <button (click)="openArea(editSection)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span style="color: black">Bauabschnitte bearbeiten</span>
              </button>
              <button (click)="openStringify(openStringifyModal)" mat-menu-item>
                <mat-icon>download_for_offline</mat-icon>
                <span style="color: black">Ausdruck öffnen</span>
              </button>
              <button (click)="open(verify)" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span style="color: black">Wochenplan löschen</span>
              </button>
            </mat-menu>
          </div>
          <app-info-four [weekItem]="weekItem"></app-info-four>
        </div>
      </div>
    </div>
    <!--<span *ngIf="weekItem.description != null && edit_mode" (click)="this.ok_pressed = true;">OK</span>-->
  </div>
</div>


<ng-template #verify let-modal>
  <app-verify (verified)="verified($event)" [description]="('Wochenplan der KW ' + weekItem.week + ' löschen?')"
              [modal]="modal"></app-verify>
</ng-template>


<ng-template #editSection let-modal>
  <app-update-week-plan-area
    [reopen_area_popup]="reopen_area_popup"
    [modal]="modal" [weekList]="weekList" [weekPlan]="weekItem"></app-update-week-plan-area>
</ng-template>

<ng-template #openStringifyModal let-modal>
  <app-show-more title="Ausdruck" [text]="stringify_week" [modal]="modal" ></app-show-more>
</ng-template>


<ng-template #weekChooser let-modal>
  <app-week-select (choose_week)="moveWeek($event)" [modal]="modal" [week]="week_number"></app-week-select>
</ng-template>
