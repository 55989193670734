<details class="summary-box blue hover-animation">
  <summary class="heading">
    <div class="align-mid-vertical">
      {{ item.name }}
    </div>
  </summary>
  <div class="summary-content-box">
    <div class="small-information-box hover-animation">
      <div class=" box-no-flex">
        <b>Name:</b>
        <app-input (valueChange)="update_key('name', $event)" text="Wert" value="{{old.get('name')}}"></app-input>
      </div>
      <div class=" box-no-flex">
        <b>Kürzel:</b>
        <app-input (valueChange)="update_key('shortcut', $event)" text="Wert"
                   value="{{old.get('shortcut')}}"></app-input>
      </div>
      <div class=" box-no-flex">
        <b>Text:</b>
        <app-textarea (onChange)="update_key('text', $event)" value="{{old.get('text')}}"></app-textarea>
      </div>
      <div class=" box-no-flex">
        <b>Einheit:</b>
        <app-input (valueChange)="update_key('unit', $event)" text="Wert" value="{{old.get('unit')}}"></app-input>
      </div>
    </div>
  </div>
</details>
