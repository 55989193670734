<div>

  <div class="modal-header">
    <h4 class="modal-title">Dichtheitsprüfung hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="margin-top: 1em">

      <app-input (valueChange)="override_date = $event; setTemperature()" [value]="today" text="Datum auswählen"
                 type="date"></app-input>

      <app-input (valueChange)="temperature = $event" [value]="temperature" text="Temparatur in °C"></app-input>
      <app-input (valueChange)="pressure = $event" [value]="pressure" text="Druck in bar"></app-input>
      <app-input (valueChange)="pressure_time = $event" [value]="pressure_time" text="Prüfzeit in min"></app-input>
      <app-input (valueChange)="pressure_medium = $event" [value]="pressure_medium" text="Prüfmedium"></app-input>

      <div style="display: flex; justify-content: space-between; width: 100%">
        <b class="align-mid-vertical">Prüfung bestanden:</b>
        <app-switch (valueChange)="pressure_passed = $event" [value]="pressure_passed"
                    class="align-mid-vertical"></app-switch>
      </div>

      <br>
      <p><b>Unterschrift wählen:</b></p>
      <div *ngIf="override_user_name == null">
        <ng-autocomplete
          (inputFocused)='onFocused($event)'
          (selected)='selectEvent($event)'
          [data]="this.available"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
          [searchKeyword]="this.keyword">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="'User nicht gefunden'"></div>
        </ng-template>
      </div>

      <div *ngIf="override_user_name != null" class="override_user">

        <p>{{override_user_name}}</p>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="override_user_name = null" mat-menu-item>
            <mat-icon>close</mat-icon>
            <span>Unterschrift wählen</span>
          </button>

          <button (click)="set_default_signature()" mat-menu-item>
            <mat-icon>favorite</mat-icon>
            <span>Unterschrift als Standard setzten</span>
          </button>
        </mat-menu>
      </div>
      <br>


    </div>

  </div>
  <div class="modal-footer">
    <div (click)="createPressureDocumentation()" class="button button-blue">
      Dokumentation erstellen
    </div>
  </div>

</div>
