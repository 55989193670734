import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {DataService} from '../../../../services/data/data.service';
import {Shift, WeekItem} from '../../../../interfaces/WeekInterface';
import {CreateService} from '../../../../services/create/create.service';
import {DeleteService} from '../../../../services/delete/delete.service';
import {User} from '../../../../interfaces/UserInterface';
import {UpdateService} from '../../../../services/update/update.service';


@Component({
  selector: 'app-project-week-days',
  templateUrl: './week-days.component.html',
  styleUrls: ['./week-days.component.scss']
})
export class WeekDaysComponent implements OnInit {


  // TODO Change this to Date in Current Week

  days: Array<Array<Shift>> = [[], [], [], [], [], [], []];
  @Input() shifts: Array<Shift>;
  @Input() week_number = -1;
  @Input() year = -1;
  edit_mode = true;
  @Input() project_id: number;
  @Input() ok_pressed: boolean;
  @Input() weekItem: WeekItem;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();
  available: Array<Array<User>> = [];
  keyword = 'name';
  selected_user: Array<Array<any>>;
  dates: any;
  current_date = new Date();
  monday_date = new Date();
  current_input = [];
  available_days = [];

  css_index = 0;


  constructor(private dataService: DataService, private createService: CreateService,
              private deleteService: DeleteService, private updateService: UpdateService) {
  }

  dummy() {

  }

  ngOnInit(): void {

    this.selected_user = Array<Array<User>>();
    if (this.shifts == null) {
      return;
    }

    this.available_days.push(this.weekItem.monday_status);
    this.available_days.push(this.weekItem.tuesday_status);
    this.available_days.push(this.weekItem.wednesday_status);
    this.available_days.push(this.weekItem.thursday_status);
    this.available_days.push(this.weekItem.friday_status);
    this.available_days.push(this.weekItem.saturday_status);
    this.available_days.push(this.weekItem.sunday_status);

    this.current_date.setDate(this.current_date.getDay() + (this.dataService.getWeekNumber(this.current_date) * 7));


    this.addRow();
    this.monday_date.setDate(this.current_date.getDate() - (this.current_date.getDay() - 1));

    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    this.shifts.forEach(item => {
      item.date = new Date(item.date.toString());

      let tmp = false;
      let i = 0;
      let j;
      if (item.date.getDay() === 0) {
        j = 6;
      } else {
        j = item.date.getDay() - 1;
      }

      while (!tmp) {
        if (this.days[j][i] === null) {
          this.days[j][i] = item;
          tmp = true;
        } else {
          if (i + 1 === this.days[j].length) {
            this.days[j].push(null);
          }
          i++;
        }
      }

    });

    for (let i = 0; i < 7; i++) {
      this.available.push([]);
      this.selected_user.push([null]);
      this.current_input.push('');
    }
    this.dates = this.dataService.getDateRangeOfWeek(this.week_number, this.year);

  }

  addRow() {
    for (let i = 0; i < 7; i++) {
      this.days[i].push(null);
    }
  }

  selectEvent(item, index) {
    this.selected_user[index].push(item);
    this.insertShift(item, index);
  }

  autocompleteCleared(index) {
    this.selected_user[index].splice(this.selected_user[index].length - 1, 1);
    this.selected_user[index] = [];
    this.selected_user[index].push([null]);

  }

  onChangeSearch(val: string, index) {
    this.current_input[index] = val;
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  async onFocused(e, i) {
    const tmp = await this.dataService.getAvailable(this.dates[i]);

    for (const a of await this.dataService.getNotAvailable(this.dates[i])) {
      tmp.push(a);
    }
    this.available[i] = tmp;
  }

  insertShift(user_name, index) {
    this.createService.createShift(this.weekItem.id, index, user_name).subscribe(data => {
      if (data.success) {
        this.days[index].push({
          id: data.data,
          week: this.week_number,
          year: this.year,
          project_id: this.project_id,
          start_time: null,
          end_time: null,
          user_name,
          canceled: false,
          date: this.dates[index],
          clear_last_name: null,
          clear_first_name: null
        });
        this.selected_user[index].splice(this.selected_user[index].length - 1, 1);
        this.selected_user[index] = [];
        this.selected_user[index].push([null]);
      }
    }, error => {
      this.createService.errorHandler(error);
      this.createService.commonsService.showErrorToast(error, 'Die Schicht konnte nicht erstellt werden. [Mehr Infos]');
    });
  }

  deleteShift(shift, day_index) {

    this.deleteService.deleteShift(shift.id).subscribe(data => {
      if (data.success) {
        this.days[day_index].splice(this.days[day_index].indexOf(shift), 1);
      }
    }, error => {
      this.deleteService.errorHandler(error);
      this.deleteService.coms.showErrorToast(error, 'Schicht', 2);
    });

  }


  handleCheck(content, event, index, status: number) {

    status = status + 1;
    if (status >= 4) {
      status = 1;
    }

    const tmpList = this.days[index].filter((el) => {
      return el != null;
    });

    if (this.available_days[index] === 3 && status === 3) {
      status = 2;
    }

    if (status !== 1) { // activate Day

      this.checkDay(index, status);

      if (status !== 3 && tmpList.length !== 0) {
        let a = 0;
        let b = 0;
        for (const shift of this.days[index]) {
          if (shift !== null) {

            a++;
            this.deleteService.deleteShift(shift.id).subscribe((data) => {
              b++;
              if (a === b) {
                this.checkDay(index, status);
                this.days[index] = [null];
              }
            }, error => {
              this.deleteService.coms.showErrorToast(error, 'Schicht', 2);
            });
          }
        }
      }
    } else {
      this.checkDay(index, status);
    }


  }

  shiftStatus(shift_id, status) {
    this.updateService.updateShiftStatus(shift_id, status).subscribe(data => {
        this.request_reload.emit();
      }


      , error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Schicht konnte nicht bearbeitet werden [...]', -1);
      }
    );

  }

  onRightClick(e, menuTrigger) {
    menuTrigger.openMenu();
    e.preventDefault();
  }

  checkDay(index, status) {
    const keys = ['monday_status',
      'tuesday_status',
      'wednesday_status',
      'thursday_status',
      'friday_status',
      'saturday_status',
      'sunday_status'];

    this.updateService.updateWeekPlan(this.weekItem.id, keys[index], status).subscribe(data => {
      this.available_days[index] = status;
    });

  }

  checkShiftOfDay(day) {
    this.css_index = 0;
    if (day.length > 1 && this.days.length > 1) {
      day.forEach(data => {
        if (data !== null) {
          this.css_index += 1;
        }
      });
    }

    return this.css_index * 1.8;
  }
}
