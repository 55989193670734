<div style="display: flex; min-width: 1600px" id="window">
  <div class="window-box" style="height: auto; max-height: calc(100vh - 100px);">
    <div class="week-box" style="min-width: 95vw">
      <div style="display: flex; justify-content: space-between">
        <h4>Wochenplanung</h4>
      </div>

      <app-home-header (next_week)="nextWeek()"
                       (open_week)="this.week_number = $event; this.reload(10)"
                       (previous_week)="this.previousWeek()"
                       (search)="search($event)"
                       [info_four_width]="info_four_width"
                       week_number="{{week_number}}"
                       year="{{year}}"
      ></app-home-header>
      <div (cdkDropListDropped)="drop($event)" cdkDropList class="div-scrollable">
        <div *ngFor="let item of week_items; let i = index" class="bv-row-container">
          <div *ngIf="item.visible" cdkDrag>
            <app-project-week

              (reopen_area_popup)="reopen_area_popup($event)"
              [reopenAreaPopup]="reopen_area_id == item.id"
              (request_reload)="reload(9)"
              [currently_editing_project]="currently_editing_project" [index]="i"
              [info_four_width]="info_four_width"
              [weekItem]="item" [weekList]="week_items" [week_number]="week_number"
              [year]="year"
            ></app-project-week>
            <div *cdkDragPlaceholder class="empty-informationBox" style="height: 12em;"></div>
          </div>


        </div>


        <div *ngFor="let item of not_running_week_items" class="bv-row-container">

          <div *ngIf="item.visible">
            <app-project-week (request_reload)="reload(7)" [currently_editing_project]="currently_editing_project"
                              [info_four_width]="info_four_width"
                              [weekItem]="item" [week_number]="week_number"
                              [year]="year"></app-project-week>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="edit_item !== null" style="">
    <p style="margin-left: 30vw"></p>
  </div>

</div>


<div *ngIf="edit_item !== null">
  <app-update #update (request_reload)="reload(8)" [currently_editing_project]="currently_editing_project"
              [weekItem]="edit_item"
              style="max-height: 80vh; overflow: scroll"></app-update>

</div>


<!-- <div (click)="open(mymodal)" class="project-create-button"> +</div>-->
