<div *ngIf="weekItem.week_plan_set" style="display: flex; height: 100%; margin-left: 1em; flex-direction: column;">

  <div class="hover-animation small-information-box" style="text-align: center">
    <div style="display: flex">
      <div style="margin-right: 1em">
        weiter in KW
      </div>
      <div *ngIf="weekItem.continue_comment != null" style="max-width: 2em; margin-right: 1em;">
        {{weekItem.continue_comment}}
      </div>
      <app-week-input (selected_kw)="updateNextKW($event)" style="max-width: 2em;"></app-week-input>
    </div>

  </div>

  <div>
    <textarea (input)="valueInput($event.target.value)"
              [value]="weekItem.accounting_comment"
              placeholder="Abrechnungs kommentar"
    ></textarea>
  </div>
</div>
