import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../interfaces/ProjectInterfaces';
import {DataService} from '../../services/data/data.service';
import {FileService} from '../../services/file/file.service';
import {FileInterface} from '../../interfaces/FileInterface';
import {CommonsService} from '../../services/commons/commons.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

  @Input() modal;
  @Input() project: Project;
  files: any[] = [];
  server_files: Array<FileInterface> = [];

  constructor(private dataService: DataService, private fileService: FileService, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
    this.loadPlans();
  }

  loadPlans() {
    this.fileService.getPlans(this.project.id).subscribe((data) => {
      this.server_files = data.data;
    });
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.uploadFiles($event);
  }

  fileBrowseHandler(files) {
    this.uploadFiles(files);
  }


  uploadFiles(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }

    for (const item of this.files) {
      this.fileService.uploadPlan(item, this.project.id).subscribe((data) => {
        if (data.success) {
          this.loadPlans();
        } else {
          this.commonsService.showInfoToast('Datei konnte nicht hochgeladen werden', 'Achtung', 0);
        }
      });
    }
    this.files = [];
  }

  deleteFile(file_name) {
    this.fileService.deletePlan(file_name).subscribe((data) => {
      if (data.success) {
        this.loadPlans();
      }
    });
  }


  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
