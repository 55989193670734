import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../../../services/update/update.service';
import {WeekItem} from "../../../../interfaces/WeekInterface";
import {DataService} from "../../../../services/data/data.service";

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() week_plan: WeekItem;


  start_date;
  end_date;
  input_mode = false;

  constructor(private updateService: UpdateService, private dataService: DataService) {
  }

  ngOnInit(): void {
    let tmp_start_date;
    let tmp_end_date;

    for (const areaElement of this.areaThreeToList(this.week_plan.area)) {

      const s_tmp = new Date(this.dataService.get_area(areaElement).start_date).getTime();
      const e_tmp = new Date(this.dataService.get_area(areaElement).end_date).getTime();
      if (tmp_end_date === undefined ||
        (s_tmp < tmp_start_date && this.dataService.get_area(areaElement).start_date != null)) {
        tmp_start_date = s_tmp;
      }
      if (tmp_end_date === undefined ||
        (e_tmp > tmp_end_date && this.dataService.get_area(areaElement).end_date != null)) {
        tmp_end_date = e_tmp;
      }
    }

    this.start_date = new Date().setTime(tmp_start_date);
    this.end_date = new Date().setTime(tmp_end_date);
  }

  areaThreeToList(areaThree: any) {
    const tmp = [];
    tmp.push(areaThree);
    for (const areaElement of areaThree.sub_areas) {
      tmp.push(areaElement);

      if (areaElement.sub_areas != null) {
        for (const areaElement2 of areaElement.sub_areas) {
          tmp.push(areaElement2);
        }
      }
    }
    return tmp;

  }


  dateValid(date) {
    return date !== null;
  }

  stringToDate(data: string) {
    return new Date(data);
  }

  updateDate(key, value) {
    this.updateService.updateProject(this.week_plan.project_id, value, key).subscribe(data => {
        this.input_mode = false;

        this.updateService.commonsService.showSuccessToast('Info', 'Projekt wurde erfolgreich bearbeitet');
      }, error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Team konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }
}
