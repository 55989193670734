<div>
  <div style="display: flex; min-width: 1480px">
    <div style="display: flex; font-weight: 600; padding: 0; width: 100%">


      <div
        style="display: flex; flex-direction: column; justify-content: space-between; min-width: calc(50.55em + 0.1vw - 1%);"
      >


        <div (mouseenter)="override_icon_visible = true; check_fix_week()"
             (mouseleave)="override_icon_visible= false; check_fix_week()"
             class="align-mid-vertical hover-wrapper"
             style="display: flex; flex-direction: row;">
          <p (click)="previous_week.emit()" class="material-icons" style="padding-right: 1em;">
            chevron_left</p>


          <div class="align-mid-vertical" style="cursor: pointer; display: flex; justify-content: center">
            <p class="align-mid-vertical">KW {{this.week_number}}</p>
            <span (click)="open(weekChooser)"
                  class="material-icons open-icon">
            event
           </span>

            <span (click)="change_fix_week()" *ngIf="override_icon_visible || fix_week"
                  class="material-icons open-icon" style="color: {{fix_week? 'green':'black'}}">
            {{!fix_week ? 'check_box_outline_blank' : 'check'}}
           </span>

          </div>
          <p (click)="next_week.emit()" class="material-icons" style="padding-left: 1em; cursor: pointer">
            chevron_right</p>
        </div>


        <div style="display: flex; flex-direction: row; ">

          <label>
            <input (change)="search.emit($event.target.value)" (input)="search.emit($event.target.value)"
                   class="search-field"
                   placeholder="suchen..."
                   style=""
                   type="text">
          </label>
          <div style="width: 2em"></div>
          <div (click)="openNewTab('/available-user?week_number=' + week_number + '&year=' + year)"
               class="material-icons align-mid-vertical" style="color: #939393; cursor: pointer">groups
          </div>
        </div>
      </div>


      <p (click)="previous_week.emit()"
         style="margin-top: 5px;width: 1%; cursor: pointer; font-weight: bold; font-size: 150%"><</p>


      <div style="width: calc(100% - 67em); display: flex;">

        <div *ngFor="let day of day_names; let j = index" style="margin: 0; width: 14%; text-align: center;">
          <p *ngIf="!(j%2)" class="day">{{day}}</p>
          <p *ngIf="j%2" class="day" style="background-color: rgb(98, 100, 98, 0.1); border-radius: 0.25em;">{{day}}</p>
          <p *ngIf="days[j] != null">{{ dateToString(days[j].date)}}</p>

          <div>
            <div *ngIf="days[j] != null" class="day-box myDIV">

        <textarea (input)="dayInput(j ,$event.target.value)"
                  *ngIf="days.length !=0 && days[j] != null && days[j].info != null" [value]="days[j].info"
                  class="day-input"
                  rows="6" style="font-size: 80%; line-height: 0.75">
        </textarea>


            </div>


          </div>


          <div *ngIf="days[j] == null">Daten konnten nicht geladen werden</div>


        </div>
      </div>

      <p (click)="next_week.emit()"
         style="margin-top: 5px;width: 1%; cursor: pointer; font-weight: bold; font-size: 150%">
        ></p>


    </div>
    <!--<div style="width:{{info_four_width}}px; "></div>-->
  </div>
</div>

<ng-template #weekChooser let-modal>
  <app-week-select (choose_week)="open_week.emit($event)" [modal]="modal" [week]="week_number"></app-week-select>
</ng-template>
