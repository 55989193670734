import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../interfaces/ClientInterface';
import {Floor, Project, Section} from '../../interfaces/ProjectInterfaces';
import {CreateService} from '../../services/create/create.service';
import {DataService} from '../../services/data/data.service';
import {LoginService} from '../../services/login/login.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-project-picker',
  templateUrl: './project-picker.component.html',
  styleUrls: ['./project-picker.component.scss']
})
export class ProjectPickerComponent implements OnInit {

  @Input() modal;
  @Input() create_mode = false;
  client: Client = null;
  all_clients = [];
  project: Project = null;
  all_projects = [];
  section: Section = null;
  all_section = [];
  floor: Floor = null;
  all_floor = [];
  dispenser_id: number;

  constructor(private createService: CreateService, private dataService: DataService,
              private loginService: LoginService, private modalService: NgbModal, public datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.load_data();
  }

  async load_data() {
    this.all_clients = await this.dataService.getClients();
    const jwt_data = this.loginService.getUsername();
  }


  async selectClient(client: Client) {
    this.client = client;
    this.all_projects = await this.dataService.getProjectsByClient(client.id);
  }

  async selectProject(project: Project) {
    this.project = project;
    this.all_section = await this.dataService.getSectionByProject(project.id);

    this.all_floor = await this.dataService.getFloorByProject(project.id);
  }

  async selectSection(section: Section) {
    this.section = section;
    this.all_floor = await this.dataService.getFloorBySection(section.id);
  }

  selectFloor(floor: Floor) {
    this.floor = floor;
  }


  openCreateFloor(content) {
    if (this.project == null) {
      window.alert('Bitte wähle zuerst ein Projekt aus');
    } else {
      this.modalService.open(content).result.then(async (result) => {
        if (this.section == null) {
          this.all_floor = await this.dataService.getFloorByProject(this.project.id);
        } else {
          this.all_floor = await this.dataService.getFloorBySection(this.section.id);
        }


      });
    }
  }

  openCreateSection(content) {
    if (this.project == null) {
      window.alert('Bitte wähle zuerst ein Projekt aus');
    } else {


      this.modalService.open(content).result.then(async (result) => {
        this.all_section = await this.dataService.getSectionByProject(this.project.id);
      });
    }
  }


  closeWithResult() {
    if (this.floor == null) {
      window.alert('Es muss eine Etage ausgewählt werden!');
      return;
    }
    this.modal.close(
      {
        client_id: this.client.id,
        project_id: this.project.id,
        section_id: this.section == null ? -1 : this.section.id,
        floor_id: this.floor.id
      }
    );
  }

}
