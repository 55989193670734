import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../services/update/update.service';
import {DataService} from '../../services/data/data.service';
import {User} from '../../interfaces/UserInterface';
import {Team} from '../../interfaces/TeamInterface';

@Component({
  selector: 'app-user-update',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  @Input() modal;
  @Input() user: User;
  @Input() update_mode: number;
  // 0 -> Team
  // 1 -> stats (Name etc.)
  selected_team: number;
  list: Array<Team> = [];
  old: Map<string, any>;
  updatedUser: User;

  constructor(private updateService: UpdateService, private dataService: DataService) {
  }

  async ngOnInit(): Promise<void> {
    this.list = await this.dataService.getTeams();

    this.old = new Map<string, any>();

    Object.keys(this.user).forEach(key => {
      this.old.set(key, this.user[key]);
    });
  }


  submit() {
    if (this.old.get('team_id') !== this.selected_team && this.selected_team !== null) {
      this.update('team_id', this.selected_team);
    }
    if (this.old.get('phone_number') !== this.user.phone_number) {
      this.update('phone_number', this.old.get('phone_number'));
    }
    if (this.old.get('clear_last_name') !== this.user.clear_last_name) {
      this.update('clear_last_name', this.old.get('clear_last_name'));
    }
    if (this.old.get('clear_first_name') !== this.user.clear_first_name) {
      this.update('clear_first_name', this.old.get('clear_first_name'));
    }

    this.modal.close();

  }

  update(key, value) {
    this.updateService.updateUser(this.user.name, key, value).subscribe(data => {
        if (data.success) {
          this.modal.close();

          this.updateService.commonsService.showSuccessToast('Info', 'Nutzer wurde erfolgreich bearbeitet');
        } else {
          window.alert('Error: Client Update');
        }

      }, error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Nutzer konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }
}
