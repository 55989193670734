import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TrashTemplate} from '../../../interfaces/TrashTemplateInterface';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss']
})
export class TrashComponent implements OnInit {

  list: Array<TrashTemplate> = [];

  constructor(private dataService: DataService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.load_data();
  }

  async load_data() {
    this.list = await this.dataService.getAllTrashTemplates();
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.load_data();
    });
  }

}
