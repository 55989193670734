import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './routes/login/login.component';
import {HomeComponent} from './routes/home/home.component';
import {NavigationComponent} from './routes/navigation/navigation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './security/auth.guard';
import {ProjectWeekComponent} from './routes/home/project_week/project-week.component';
import {WeekDaysComponent} from './routes/home/project_week/week-days/week-days.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CreateProjectComponent} from './popup/create-project/create-project.component';
import {DashBoardComponent} from './routes/dash-board/dash-board.component';
import {ClientComponent} from './routes/client/client.component';
import {CreateClientComponent} from './popup/create-client/create-client.component';
import {CreateTrashTemplateComponent} from './popup/create-trash-template/create-trash-template.component';
import {CreateTodoTemplateComponent} from './popup/create-todo-template/create-todo-template.component';
import {TodoValueInputComponent} from './popup/todo-value-input/todo-value-input.component';
import {ProjectComponent} from './routes/project/project.component';
import {FloorComponent} from './routes/project/floor/floor.component';
import {SectionComponent} from './routes/project/section/section.component';
import {DispenserComponent} from './routes/project/dispenser/dispenser.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {TeamComponent} from './routes/team/team.component';
import {UserComponent} from './routes/user/user.component';
import {UpdateProjectComponent} from './popup/update-project/update-project.component';
import {UpdateUserComponent} from './popup/update-user/update-user.component';
import {UpdateClientComponent} from './popup/update-client/update-client.component';
import {ChatComponent} from './routes/project/chat/chat.component';
import {VerifyComponent} from './popup/verify/verify.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ChatService} from './services/chat/chat.service';
import {CreateService} from './services/create/create.service';
import {DataService} from './services/data/data.service';
import {DeleteService} from './services/delete/delete.service';
import {LoginService} from './services/login/login.service';
import {UpdateService} from './services/update/update.service';
import {ShowMoreComponent} from './popup/show-more/show-more.component';
import {UpdateComponent} from './routes/home/update/update.component';
import {ResetComponent} from './routes/login/reset/reset.component';
import {WeekSelectComponent} from './popup/week-select/week-select.component';
import {Nl2brPipe} from './Nl2br.pipe';
import {CreateWeekPlanComponent} from './popup/create-week-plan/create-week-plan.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {ContactComponent} from './routes/client/contact/contact.component';
import {CreateClientContactComponent} from './popup/create-client-contact/create-client-contact.component';
import {InputComponent} from './global/input/input.component';
import {HomeHeaderComponent} from './routes/home/home-header/home-header.component';
import {DatePipe} from '@angular/common';
import {CreateSectionComponent} from './popup/create-section/create-section.component';
import {WeekComponent} from './routes/project/week/week.component';
import {WeekDescriptionComponent} from './routes/home/project_week/week-description/week-description.component';
import {AutocompleteComponent} from './global/autocomplete/autocomplete.component';
import {CreateTodoWeekPlanComponent} from './popup/create-todo-week-plan/create-todo-week-plan.component';
import {UpdateWeekPlanTodoComponent} from './popup/update-week-plan-todo/update-week-plan-todo.component';
import {CreateTeamComponent} from './popup/create-team/create-team.component';
import {CreateFloorComponent} from './popup/create-floor/create-floor.component';
import {AvailableUserTabComponent} from './routes/home/available-user-tab/available-user-tab.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {CreateDispenserComponent} from './popup/create-dispenser/create-dispenser.component';
import {UpdateSectionComponent} from './popup/update-section/update-section.component';
import {
  CreateDispenserDocumentationComponent
} from './popup/create-dispenser-documentation/create-dispenser-documentation.component';
import {
  CreatePressureDocumentationComponent
} from './popup/create-pressure-documentation/create-pressure-documentation.component';
import {SwitchComponent} from './global/switch/switch.component';
import {InfoOneComponent} from './routes/home/project_week/info-one/info-one.component';
import {InfoThreeComponent} from './routes/home/project_week/info-three/info-three.component';
import {InfoTwoComponent} from './routes/home/project_week/info-two/info-two.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {RegisterComponent} from './routes/login/register/register.component';
import {LoginUserComponent} from './routes/login/login-user/login-user.component';
import {FileManagerComponent} from './popup/file-manager/file-manager.component';
import {ProgressComponent} from './popup/file-manager/progress/progress.component';
import {DndDirective} from './dnd.directive';
import {InfoFourComponent} from './routes/home/project_week/info-four/info-four.component';
import {HoverBallsComponent} from './routes/home/project_week/hover-balls/hover-balls.component';
import {TextareaComponent} from './global/textarea/textarea.component';
import {TemplateSelectorComponent} from './global/template-selector/template-selector.component';
import {InfoFiveComponent} from './routes/home/project_week/info-five/info-five.component';
import {ProjectItemComponent} from './routes/project/project-item/project-item.component';
import {
  CreateAdvancedDocumentationComponent
} from './popup/create-advanced-documentation/create-advanced-documentation.component';
import {ShowClientContactComponent} from './popup/show-client-contact/show-client-contact.component';
import {UploadSignatureComponent} from './popup/upload-signature/upload-signature.component';
import {SettingsComponent} from './routes/settings/settings.component';
import {UpdateSignatureComponent} from './routes/settings/user-settings/update-signature/update-signature.component';
import {UpdatePasswordComponent} from './routes/settings/user-settings/update-password/update-password.component';
import {UserPropertiesComponent} from './routes/settings/user-properties/user-properties.component';
import {UserSettingsComponent} from './routes/settings/user-settings/user-settings.component';
import {NavButtonComponent} from './routes/navigation/nav-button/nav-button.component';
import {TopNavComponent} from './routes/navigation/top-nav/top-nav.component';
import {SideNavComponent} from './routes/navigation/side-nav/side-nav.component';
import {AdminComponent} from './routes/admin/admin.component';
import {ProjectPickerComponent} from './popup/project-picker/project-picker.component';
import {CreateTestRowComponent} from './routes/admin/create-test-row/create-test-row.component';
import {SendCommandComponent} from './routes/admin/send-command/send-command.component';
import {UpdateTodoTemplateComponent} from './popup/update-todo-template/update-todo-template.component';
import {DaemonComponent} from './routes/admin/daemon/daemon.component';

import {TemplatesComponent} from './routes/templates/templates.component';
import {TrashComponent} from './routes/templates/trash/trash.component';
import {TodoComponent} from './routes/templates/todo/todo.component';
import {SingleTodoTemplateComponent} from './routes/templates/todo/single-todo-template/single-todo-template.component';
import {
  SingleTrashTemplateComponent
} from './routes/templates/trash/single-trash-template/single-trash-template.component';
import {DateComponent} from './routes/home/project_week/date/date.component';
import {UpdateFloorComponent} from './popup/update-floor/update-floor.component';
import {ImportWeekPlanComponent} from './popup/create-week-plan/import-weekplan/import-week-plan.component';
import {CDK_DRAG_CONFIG, DragDropModule} from "@angular/cdk/drag-drop";
import {DatePickerComponent} from './global/date-picker/date-picker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {CustomDateAdapter} from "./CustomDateAdapter";
import {CookieService} from "ngx-cookie-service";
import {SharepointDeleteComponent} from './popup/sharepoint-delete/sharepoint-delete.component';
import {WeekInputComponent} from './global/week-input/week-input.component';
import {CreateUserComponent} from './popup/create-user/create-user.component';
import {ToastrModule} from "ngx-toastr";

import {UpdateWeekPlanAreaComponent} from './popup/update-week-plan-area/update-week-plan-area.component';
import { SmallAreaComponent } from './popup/update-week-plan-area/small-area/small-area.component';


const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavigationComponent,
    ProjectWeekComponent,
    WeekDaysComponent,
    CreateProjectComponent,
    DashBoardComponent,
    ClientComponent,
    CreateClientComponent,
    Nl2brPipe,


    ProjectComponent,
    FloorComponent,
    SectionComponent,
    DispenserComponent,
    TeamComponent,
    UserComponent,
    UpdateProjectComponent,
    UpdateUserComponent,
    UpdateClientComponent,
    ChatComponent,
    VerifyComponent,
    ShowMoreComponent,
    UpdateComponent,
    ResetComponent,
    WeekSelectComponent,
    CreateWeekPlanComponent,
    CreateTrashTemplateComponent,
    CreateTodoTemplateComponent,
    TodoValueInputComponent,
    ContactComponent,
    CreateClientContactComponent,
    InputComponent,
    HomeHeaderComponent,
    CreateSectionComponent,
    WeekComponent,
    WeekDescriptionComponent,
    AutocompleteComponent,
    CreateTodoWeekPlanComponent,
    UpdateWeekPlanTodoComponent,
    CreateTeamComponent,
    CreateFloorComponent,
    AvailableUserTabComponent,
    CreateDispenserComponent,
    UpdateSectionComponent,
    CreateDispenserDocumentationComponent,
    CreatePressureDocumentationComponent,
    SwitchComponent,
    InfoOneComponent,
    InfoThreeComponent,
    InfoTwoComponent,
    RegisterComponent,
    LoginUserComponent,
    FileManagerComponent,
    ProgressComponent,
    DndDirective,
    InfoFourComponent,
    HoverBallsComponent,
    TextareaComponent,
    TemplateSelectorComponent,
    InfoFiveComponent,
    ProjectItemComponent,
    CreateAdvancedDocumentationComponent,
    ShowClientContactComponent,
    UploadSignatureComponent,
    SettingsComponent,
    UpdateSignatureComponent,
    UpdatePasswordComponent,
    UserPropertiesComponent,
    UserSettingsComponent,
    NavButtonComponent,
    TopNavComponent,
    SideNavComponent,
    AdminComponent,
    ProjectPickerComponent,
    CreateTestRowComponent,
    SendCommandComponent,
    UpdateTodoTemplateComponent,
    DaemonComponent,
    TemplatesComponent,
    TrashComponent,
    TodoComponent,
    SingleTodoTemplateComponent,
    SingleTrashTemplateComponent,
    DateComponent,
    UpdateFloorComponent,
    ImportWeekPlanComponent,
    DatePickerComponent,
    SharepointDeleteComponent,
    WeekInputComponent,
    CreateUserComponent,
    UpdateWeekPlanAreaComponent,
    SmallAreaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    AutocompleteLibModule,
    TextFieldModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatInputModule,
    ReactiveFormsModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    CookieService,
    {provide: CDK_DRAG_CONFIG, useValue: DragConfig},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    AuthGuard, HttpClient, ChatService, CreateService, DataService, DeleteService,
    LoginService, UpdateService, DatePipe, Nl2brPipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
