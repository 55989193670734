<div>
  <div class="modal-header">
    <h4 class="modal-title">Projekt bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">
    <app-input (valueChange)="valueInput('name', $event)" [value]="project.name" text="Projekt Name"></app-input>


    <div style="display: flex">
      <app-input (valueChange)="valueInput('street',  $event)"
                 placeholder="{{project.street == null || project.street.length == 0? 'Straße': project.street}}"
                 style="width: 60%;"
                 text="Straße + Nr" value="{{project.street}}"></app-input>

      <div style="width: 1em"></div>
      <app-input (valueChange)="valueInput('zip_code',  $event)"
                 placeholder="{{project.zip_code== null || project.zip_code.length == 0? 'PLZ + Ort': project.zip_code}}"
                 text="PLZ + Ort" value="{{project.zip_code}}"></app-input>
    </div>


    <app-date-picker
      (end_dateChange)="valueInput('end_date',  $event); this.end_date = $event"
      (start_dateChange)="valueInput('start_date',  $event); this.start_date = $event"
      end_date_string="{{this.end_date}}"
      start_date_string="{{this.start_date}}"
    >
    </app-date-picker>


    <p>Beschreibung:</p>
    <label title="Beschreibung">
      <textarea (input)="valueInput('description',  $event.target.value)"
                [value]="project.description"
                placeholder="{{project.description== null|| project.description.length == 0? 'Beschreibung': project.description}}"></textarea>
    </label>

  </div>

  <div class="modal-footer">
    <div (click)="update()" class="button button-blue">Ok</div>
  </div>
</div>
<ng-template #clientCreateModal let-modal>
  <app-client-create [modal]="modal"></app-client-create>
</ng-template>
