<div class="login100-form ">
					<span>
            <h1>Mitglieder Login</h1>
					</span>

  <div class="wrap-input100 validate-input">
    <app-input [(value)]="username" text="Nutzername / Email" type="email"></app-input>
  </div>

  <div class="wrap-input100 validate-input" data-validate="Password is required">
    <app-input (onEnter)="login()" [(value)]="password" text="Password" type="password"></app-input>
  </div>

  <div class="container-login100-form-btn">
    <button (click)="login()" class="login100-form-btn">
      Login
    </button>
  </div>

  <div class="text-center">
						<span style="font-size: 15px">
							Forgot
						</span>
    <a (click)="openReset.emit(true)" style="font-size: 15px">
      Username / Password?
    </a>
  </div>

  <div class="text-center">
    <p (click)="openRegister.emit(true)" style="font-size: 15px">
      Registrieren
    </p>
  </div>
</div>
