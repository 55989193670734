<div class="template-container div-scrollable">
  <div *ngFor="let i of selected; let j = index">
    <div (click)="remove_action.emit(i)" class="template-box template-active  hover-animation"
         style="cursor: pointer;">

      {{i[search_word]}}
      <div *ngIf="second_search_word != null">{{i[second_search_word]}}</div>
    </div>
  </div>

  <div *ngFor="let i of available; let j = index">
    <div (click)="add_action.emit(i)" class="template-box template-inactive  hover-animation"
         style="cursor: pointer;">

      {{i[search_word]}}
      <div *ngIf="second_search_word != null">{{i[second_search_word]}}</div>
    </div>
  </div>


  <div (click)="create_action.emit(true)" class="template-box template-inactive hover-animation"
       style="cursor: pointer">+
  </div>
</div>
