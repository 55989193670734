<div>

  <div class="modal-header">
    <h4 class="modal-title">Etage bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <h6>{{floor.floor_name}}</h6>
    <app-date-picker
      (end_dateChange)="valueInput('end_date',  $event); this.floor.end_date = $event"
      (start_dateChange)="valueInput('start_date',  $event); this.floor.start_date = $event"
      end_date_string="{{this.floor.end_date}}"
      start_date_string="{{this.floor.start_date}}">
    </app-date-picker>

  </div>
  <div class="modal-footer">
    <div (click)="update()" class="button button-blue">
      Fertig
    </div>
  </div>
</div>
