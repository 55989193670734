import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TodoTemplate} from '../../../interfaces/WeekInterface';
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {UpdateService} from "../../../services/update/update.service";

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss']
})
export class TodoComponent implements OnInit {

  list: Array<TodoTemplate> = [];

  constructor(private dataService: DataService,
              private modalService: NgbModal,
              private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.load_data();
  }

  async load_data() {
    this.list = await this.dataService.getAllTodoTemplates();
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.load_data();
    });
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.currentIndex === event.previousIndex) {
      return;
    }
    const moving_todo = this.list[event.previousIndex].id;
    const target_todo = this.list[event.currentIndex].id;


    this.updateService
      .updateToDoOrder(moving_todo, target_todo)
      .subscribe((data) => {
        if (data.success) {
          this.load_data()
        } else {
          window.alert('Fehler!');
        }
      }, error => {
        window.alert('Fehler!');
      });
  }
}
