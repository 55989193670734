import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() value: boolean;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    this.valueChange.subscribe((data) => {
      this.value = data;
    });
  }

  emitValue(value: boolean) {
    this.valueChange.emit(value);

  }

}
