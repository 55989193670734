import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../services/update/update.service';
import {Todo} from '../../interfaces/WeekInterface';


@Component({
  selector: 'app-week-plan-todo-update',
  templateUrl: './update-week-plan-todo.component.html',
  styleUrls: ['./update-week-plan-todo.component.scss']
})
export class UpdateWeekPlanTodoComponent implements OnInit {

  @Input() modal;
  @Input() todo: Todo;

  old: Map<string, any>;
  showMore = false;

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.old = new Map<string, any>();

    Object.keys(this.todo).forEach(key => {
      this.old.set(key, this.todo[key]);
    });

  }

  updateTodo() {
    if (this.old.get('value') !== this.todo.value) {
      this.update(this.old.get('value').length === 0 ? null : this.old.get('value'));
    }
    this.modal.close();
  }

  update(value) {
    this.updateService.updateWeekPlanTodo(value, this.todo.id).subscribe(data => {
        if (!data.success) {
          window.alert('Error: todoUpdate');
        } else {
          this.todo.value = this.old.get('value');
        }
      },
      error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Wochenplan konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }
}
