import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../services/update/update.service';


@Component({
  selector: 'app-client-update',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent implements OnInit {

  @Input() modal;
  @Input() client;

  old: Map<string, any>;
  showMore = false;

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {


    this.old = new Map<string, any>();

    Object.keys(this.client).forEach(key => {
      this.old.set(key, this.client[key]);
    });

  }

  updateClient() {

    if (this.old.get('name') !== this.client.name) {
      this.update('name', this.old.get('name'));
    }
    if (this.old.get('street') !== this.client.street) {
      this.update('street', this.old.get('street'));
    }
    if (this.old.get('zip_code') !== this.client.zip_code) {
      this.update('zip_code', this.old.get('zip_code'));
    }
    if (this.old.get('custom_documentation') !== this.client.custom_documentation) {
      this.update('custom_documentation', this.old.get('custom_documentation'));
    }
    if (this.old.get('documentation_type') !== this.client.documentation_type) {
      this.update('documentation_type', this.old.get('documentation_type'));
    }

    this.modal.close();

  }

  switchShowMore() {
    this.showMore = !this.showMore;
  }

  update(key, value) {
    this.updateService.updateClient(this.client.id, key, value).subscribe(
      (tdata) => {
        this.updateService.commonsService.showSuccessToast('Info', 'Kunde wurde erfolgreich bearbeitet');
      }, error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Kunde konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }
}
