import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeekItem} from '../../../../interfaces/WeekInterface';
import {Project} from '../../../../interfaces/ProjectInterfaces';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../../services/data/data.service';
import {Constants} from '../../../../Constants';
import {LoginService} from '../../../../services/login/login.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-info-one',
  templateUrl: './info-one.component.html',
  styleUrls: ['./info-one.component.scss']
})
export class InfoOneComponent implements OnInit {

  show_more_data = false;
  @Input() pro: Project;
  @Input() weekItem: WeekItem;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, public dataService: DataService, private loginService: LoginService,
              private config: NgbModalConfig, private router: Router) {
  }

  ngOnInit(): void {
  }


  async switchMore() {
    if (this.show_more_data) {
      this.show_more_data = false;
    } else {
      this.weekItem.project.client = await this.dataService.getClientById(this.weekItem.project.client_id);

      this.show_more_data = true;
    }
  }


  open(content, reload) {
    this.modalService.open(content).result.then((result) => {
      if (reload) {
        this.request_reload.emit(true);
      }
    });
  }

  openSharepointFolder() {
    const token = this.loginService.getSessionToken();

    const url = 'sharepoint/getPath?client_id=' + this.pro.client_id.toString()
      + '&project_id=' + this.pro.id.toString() + '&session=' + token;


    window.open(Constants.PYTHON_SERVER_URL + url);
  }


  loadPlan(content) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;

    this.modalService.open(content, {size: 'xl'});

    this.config.backdrop = true;
    this.config.keyboard = true;
  }

  openProjectTab() {
    this.router.navigate(['/project'], {queryParams: {project_id: this.weekItem.project.id}}).then();
  }
}
