import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../interfaces/UserInterface';
import {DeleteService} from '../../services/delete/delete.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  user: Array<User> = [];
  search_word = '';

  constructor(private dataService: DataService, private modalService: NgbModal, private deleteService: DeleteService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  async refresh() {

    this.user = await this.dataService.getAllUser();
    this.user.forEach(item => {
      item.visible = true;
    });

    if (this.search_word.length >= 1) {
      this.search(this.search_word);
    }

  }

  getKeys() {
    return ['name', 'team_name'];
  }

  search(text) {
    this.user = this.setVisibility(this.user, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        item.visible = (
          item.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.phone !== undefined && item.phone.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.preName !== undefined && item.preName.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.realName !== undefined && item.realName.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.email !== undefined && item.email.toLowerCase().search(compareStr.toLowerCase()) >= 0);
      });
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  deleteUser(bool, user_name) {
    if (bool) {
      this.deleteService.deleteUser(user_name).subscribe(data => {
        if (data) {
          this.refresh();
        }
      }, error => {
        this.deleteService.coms.showErrorToast(error, 'Nutzer', 2);
        this.deleteService.errorHandler(error);
      });
    }
  }

  open(content, event) {
    event.preventDefault();
    this.modalService.open(content).result.then((result) => {
      this.refresh();
    });
  }
}
