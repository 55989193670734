import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {LoginService} from '../../../services/login/login.service';


@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  @Output() active: EventEmitter<boolean> = new EventEmitter();
  @Output() openLogin: EventEmitter<boolean> = new EventEmitter();

  token_received = false;
  email: string;
  password: string;
  repeat_password: string;
  first_access = true;
  token: string;

  constructor(private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.active.emit(true);
  }

  emailSent() {
    // TODO email sent?
  }

  sendToken() {
    this.loginService.sendResetMail(this.email);
  }

  resetPassword() {
    this.active.emit();
    this.loginService.resetPassword(this.token, this.password, this.repeat_password);
  }


  back_to_login() {
    this.openLogin.emit(true);
  }

  disabled() {
    window.alert('Achtung!\n' +
      'Bitte melde dich bei einem Admin. Diese Funktion ist derzeit deaktiviert!');
  }
}
