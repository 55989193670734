<div>

  <div class="modal-header">
    <h4 class="modal-title">ACHTUNG!</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <p style="margin-bottom: 2em; font-size: 1.25em; color: #535353">Du möchtest {{type}} löschen. <br>
      Standardmäßig werden dadurch aber auch alle dazugehörigen Dokumentationen und Fotos gelöscht. Möchtest du diese
      Daten löschen?
    </p>
    <div class="box hover-animation non-clickable-object">
      <div class="align-mid-vertical">Daten löschen</div>
      <app-switch [(value)]="delete"></app-switch>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="this.sharepoint_delete.emit(this.delete); modal.close()"
         class="button button-blue" style="width: unset;">
      {{ delete ? type + " & Daten löschen" : "Nur " + type + " löschen"}}
    </div>

  </div>


</div>
