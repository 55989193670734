import {AfterViewInit, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';

@Component({
  selector: 'app-create-dispenser',
  templateUrl: './create-dispenser.component.html',
  styleUrls: ['./create-dispenser.component.scss']
})
export class CreateDispenserComponent implements OnInit, AfterViewInit {

  @Input() modal;
  @Input() floor_id;

  name;
  request_focus: EventEmitter<boolean> = new EventEmitter<boolean>();
  override_create_folder;

  already_requested = false;

  constructor(private createService: CreateService) {
  }

  ngOnInit(): void {
  }

  createDispenser() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createDispenser(this.floor_id, this.name, this.override_create_folder).subscribe(data => {
        if (data.success) {
          this.name = '';
          this.already_requested = false;
          this.createService.commonsService.showSuccessToast('Info', 'Der Verteiler wurde Erfolgreich erstellt!')
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Verteiler konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }

  switch_override_create_folder() {
    this.override_create_folder = this.override_create_folder == null ? false : !this.override_create_folder;
  }

  ngAfterViewInit() {
    this.request_focus.emit(true);
  }
}
