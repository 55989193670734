<div>

  <div class="modal-header">
    <h4 class="modal-title">ToDo hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <input (change)="search($event.target.value)" (input)="search($event.target.value)"
         [(ngModel)]="todo_search_text"
         class="search-field"
         placeholder="suchen..."
         style="padding: 0.5em !important; margin: 1em; width: calc(100% - 2em);"
         type="text"
  >
  <div class="div-scrollable" style="height: 60vh">
    <div class="template-container" style="padding: 0.5em;">
      <div *ngFor="let todo of weekItem.todo; let j = index">

        <div *ngIf="todo.visible" class="template-box template-active hover-animation">
          <div class="template-text">
            {{ todo.template.name > 200 ? todo.template.name.substr(0, 10) + ' [...] ' : todo.template.name }}
          </div>
          <span (click)="open(showTodoTemplateText, $event)"
                class="material-icons icon-white">
          remove_red_eye
        </span>
          <span (click)="deleteWeekPlanTodo(todo.id)"
                class="material-icons icon-white">
          delete
        </span>
        </div>

        <ng-template #showTodoTemplateText let-modal>
          <app-week-plan-todo-update [modal]="modal" [todo]="todo"></app-week-plan-todo-update>
        </ng-template>
      </div>
      <div *ngFor="let template of todo_templates; let j = index">
        <div (click)="open(todoValueInput, $event)" *ngIf="!isTodoInWeek(template)&& template.visible"
             class="template-box template-inactive hover-animation">
          <div class="template-text">
            {{ template.name > 200 ? template.name.substr(0, 10) + ' [...] ' : template.name }}
          </div>

          <span (click)="open(updateTodoTemplate, $event)"
                class="material-icons icon-blue" style="z-index: 10">
            remove_red_eye
          </span>
          <!--<span (click)="deleteWeekPlanTodoTemplate(template.id)" class="material-icons icon-blue">
            delete
          </span>-->
          <ng-template #todoValueInput let-modal>
            <app-todo-value-input
              (value)="addWeekPlanTodo(true, template, $event)" [modal]="modal"
              [template]="template"></app-todo-value-input>
          </ng-template>

          <ng-template #updateTodoTemplate let-modal>
            <app-update-todo-template (reload)="reloadTodoTemplates($event)" [modal]="modal"
                                      [todo_template]="template"></app-update-todo-template>
          </ng-template>
        </div>
      </div>
      <div (click)="open(newTodoTemplate, $event)" class="template-box template-inactive hover-animation"
           style="cursor: pointer; margin: auto 0">
        +
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="closeModal()" class="button button-blue">
      Schließen
    </div>
  </div>

</div>


<ng-template #newTodoTemplate let-modal>
  <app-todo-template-create (done)="reloadTodoTemplates($event)" [modal]="modal"></app-todo-template-create>
</ng-template>
