<div style="width: 100%; display: flex">
  <label (click)="end_picker.open()" class="pure-material-textfield-outlined"
         style="margin-bottom: 0; width: 100%; position: relative">
    <input (dateInput)="input_end_date($event)" [matDatepicker]="end_picker" disabled
           matInput placeholder="NE" style="opacity: 0">

    <div style="position: absolute; right: 0; top: -.75em">
      <mat-datepicker-toggle [for]="end_picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #end_picker disabled="false"></mat-datepicker>
    </div>

  </label>
</div>
