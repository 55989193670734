<div>

  <div class="modal-header">
    <h4 class="modal-title">Todo-Template bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <app-input (valueChange)="valueInput('name', $event)" text="Name"
               value="{{todo_template.name}}"></app-input>

    <app-input (valueChange)="valueInput('shortcut', $event)" text="Kürzel (max. 4 Zeichen)"
               value="{{todo_template.shortcut}}"></app-input>

    <app-input (valueChange)="valueInput('order', $event)" text="Reihenfolge" type="number"
               value="{{todo_template.order}}"></app-input>

    <div style="margin-top: 1em">
      <p>Text</p>
      <label>
        <textarea (input)="valueInput('text', $event)" placeholder="Text"></textarea>
      </label>
    </div>

    <app-input (valueChange)="valueInput('unit', $event)" text="Bauabschnitt name"
               value="{{todo_template.unit}}"></app-input>


  </div>
  <div class="modal-footer">
    <div (click)="update()" class="button button-blue">
      Fertig
    </div>
  </div>
</div>
