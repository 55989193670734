import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {UpdateService} from '../../services/update/update.service';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss']
})
export class CreateTeamComponent implements OnInit {

  @Input() modal;

  team_name: string;
  default_color = '#62B7DF';

  already_requested = false;

  constructor(private createService: CreateService, private updateService: UpdateService) {
  }

  ngOnInit(): void {
  }

  createTeam() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createTeam(this.team_name).subscribe(data => {
        if (data.success) {


          this.updateService.updateTeam(data.data, 'default_color', this.default_color).subscribe(
            (tdata) => {
              this.updateService.commonsService.showSuccessToast('Info', 'Team wurde erfolgreich bearbeitet');
            }, error => {
              this.updateService.errorHandler(error);
              this.updateService.commonsService.showErrorToast(error, 'Team konnte nicht bearbeitet werden [...]', -1);
            }
          );


          this.modal.close();
        } else {
          this.already_requested = false;
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Das Team konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }
}
