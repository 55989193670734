<div *ngIf="project.client != null">
  <b>Kunde:</b>
  <ul>
    <li>{{project.client.name}}</li>
    <li>{{project.client.street}}</li>
    <li>{{project.client.zip_code}}</li>

  </ul>
</div>


<div *ngIf="weekItem.client_contact != undefined && weekItem.client_contact.length != 0">


  <b>Ansprechpartner:</b>
  <ul>
    <li
      *ngFor="let c of weekItem.client_contact"> {{c.first_name}} {{c.last_name}} {{c.phone_number != null && c.phone_number.length > 0 ? ", " + c.phone_number : ""}}</li>
  </ul>
</div>

<div *ngIf="weekItem.week_plan_set">
  <div>
    <b>Projektleiter:</b>
    <ul>
      <li *ngIf="weekItem.responsible_user_name != null">{{weekItem.responsible_user_name}}</li>
      <li *ngIf="weekItem.responsible_user_name == null">/</li>
    </ul>
  </div>
</div>
<div *ngIf="weekItem.manufacture != null">
  <b>Fabrikat:</b>
  <ul>
    <li>
      {{weekItem.manufacture}}
    </li>
  </ul>
</div>
<div *ngIf="weekItem.agreements != null">
  <b>Absprachen:</b>
  <ul>
    <li [innerHTML]="weekItem.agreements | Nl2br">
    </li>
  </ul>
</div>

<div *ngIf="weekItem.area_comment != null">
  <b>Bauabschnitt:</b>
  <ul>
    <li [innerHTML]="weekItem.area_comment | Nl2br">
    </li>
  </ul>
</div>


<div *ngIf="weekItem.special_tools != null">
  <b>Spezialwerkzeug:</b>
  <ul>
    <li [innerHTML]="weekItem.special_tools | Nl2br">
    </li>
  </ul>
</div>

<div *ngIf="weekItem.note != null">

  <b>Materialbedarf:</b>
  <ul>
    <li *ngIf="this.noteTooLong(weekItem.note)[1]">
      {{this.noteTooLong(weekItem.note)[0]}}
      <p
        (click)="show_more_text = weekItem.note; show_more_title = 'Wochennotiz: ' + weekItem.project.name; open(showMore, false)">
        [ mehr anzeigen ]</p>
    </li>

    <li *ngIf="!this.noteTooLong(weekItem.note)[1]" [innerHTML]="this.noteTooLong(weekItem.note)[0] | Nl2br">
    </li>
  </ul>
</div>

<div *ngIf="weekItem.trash != null && weekItem.trash.length > 0">
  <b>Müllbeseitigung:</b>
  <div class="template-container" style="width: 100%; table-layout: fixed; padding-top: 0.5em">

    <div *ngFor="let trash of weekItem.trash"
         class="template-div template-box clickable-object hover-animation" style="width: 100%">

      <div (click)="show_more_text = trash.template.name; show_more_title = 'Vorlagenname'; open(showMore, false)"
           class="template-border-right" style="width: calc(100% - {{getWidthOfDiv('second-trash-box')}}px - 0.25em);">
        {{ trash.template.name.length > 13 ? trash.template.name.substr(0, 15) + ' [...] ' : trash.template.name }}
      </div>
      <div (click)="show_more_text = trash.template.text; show_more_title = 'Vorlagentext'; open(showMore, false)"
           id="second-trash-box">
        {{ trash.template.text.length > 13 ? trash.template.text.substr(0, 15) + ' [...] ' : trash.template.text }}
      </div>

    </div>
  </div>
</div>

<ng-template #showMore let-modal>
  <app-show-more [modal]="modal" [text]="show_more_text"
                 [title]="show_more_title"></app-show-more>
</ng-template>
