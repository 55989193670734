<details [open]="is_open" class="summary-box dark-II hover-animation">
  <summary (click)="loadFloor(); channel_id.emit(section.channel_id);">


    <div class="align-mid-vertical">
      {{section.name}}
    </div>

    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="open(createFloor,$event)" mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>Etage erstellen</span>
      </button>
      <button (click)="open(updateSection, $event, true)" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Bauabschnitt bearbeiten</span>
      </button>
      <button (click)="reUploadSection()" mat-menu-item>
        <mat-icon>cached</mat-icon>
        <span>Protokolle erneut hochladen</span>
      </button>

      <button (click)="open(sectionDelete, $event)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Bauabschnitt löschen</span>
      </button>
    </mat-menu>

  </summary>
  <div class="summary-content-box">

    <div class="small-information-box hover-animation">
      <b>Vom</b> {{formatDate(section.start_date)}} <b>bis zum</b> {{formatDate(section.end_date)}}<br>

      <b>SectionID:</b>
      <ul>
        <li>
          {{section.id}}
        </li>
      </ul>
      <b>Verteiler:</b> {{calc_dispenser()}} <br>
      <b>Etagen:</b> {{calc_floors()}}
      <div *ngIf="section.creator_name != null">
        <br><br>
        <b>Bauabschnitt-Ersteller:</b>
        <ul>
          <li>{{section.creator_name}}</li>
        </ul>
      </div>
    </div>
    <div *ngFor="let floor of section.floor">
      <app-floor (delete)="deleteFloor(floor)" [floor]="floor" [project]="project"></app-floor>
    </div>
  </div>

</details>

<ng-template #sectionDelete let-modal>
  <app-sharepoint-delete (sharepoint_delete)="verified($event)" [modal]="modal"
                         [type]="'Bauabschnitt (' + section.name + ')'"></app-sharepoint-delete>

</ng-template>


<ng-template #createFloor let-modal>
  <app-create-floor [modal]="modal"
                    [project_id]="section.project_id"
                    [section_id]="section.id"></app-create-floor>
</ng-template>


<ng-template #updateSection let-modal>
  <app-update-section [modal]="modal"
                      [section]="section"></app-update-section>
</ng-template>
