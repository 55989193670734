<div>
  <div *ngIf="first_access">
    <h2>Haben sie bereits einen Token erhalten?</h2>
    <div class="inline-box">
      <div (click)="token_received = true; first_access = false;" class="button button-grey" style="margin-right: 1em">
        Ja
      </div>
      <div (click)="disabled() //first_access = false;" class="button button-grey">Nein</div>
    </div>
  </div>

  <div *ngIf="!token_received && !first_access">
    <div>Geben sie bitte Ihre Email Adresse ein</div>
    <input [(ngModel)]="email" placeholder="E-mail" type="text">

    <div (click)="first_access = true; sendToken()" class="button button-grey" style="margin-top: 1em">OK</div>
  </div>

  <div *ngIf="token_received">
    <div class="text-div">Bitte Token eingeben</div>
    <input [(ngModel)]="token" placeholder="Token" type="text">
    <div class="text-div">Bitte ein neues Passwort eingeben</div>
    <input [(ngModel)]="password" placeholder="Passwort" type="password">
    <div class="text-div">Passwort bestätigen</div>
    <input [(ngModel)]="repeat_password" placeholder="Passwort bestätigen" type="password">

    <div class="inline-box">
      <div (click)="resetPassword()" class="button button-grey" style="margin-top: 1em">OK</div>
    </div>
  </div>
  <div class="inline-box" style="margin-top: 0.5em">
    <div (click)="back_to_login()" class="button button-grey">ZURÜCK</div>
  </div>

</div>
