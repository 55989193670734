<p *ngIf="weeks == null || weeks.length == 0">Keine Wochenpläne vorhanden</p>

<div *ngFor="let week of weeks" class="hover-animation summary-box dark-I">
  <details>
    <summary style="justify-content: space-between; display: flex">
      <div style="display: flex">
        <p>{{week.year}} KW{{week.week}}</p>
        <div *ngIf="week.area_comment != null" style="margin-left: 3em">
          <p *ngIf="week.area_comment.length < 200">{{week.area_comment}}</p>
          <p *ngIf="week.area_comment.length > 200">{{week.area_comment.slice(0, 200) + ' [ .. ]'}}</p>
        </div>
      </div>
      <span (click)="openWeek(week.year,week.week, $event)" class="material-icons">
        arrow_right_alt
        </span>

    </summary>

    <div class="summary-content-box">
      <div class="small-information-box hover-animation">
        <app-week-description [project]="project" [weekItem]="week"></app-week-description>
      </div>

    </div>
  </details>
</div>
