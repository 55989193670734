<div>

  <div class="modal-header">
    <h4 class="modal-title">Kunde bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <label title="Kunden Name">
      <app-input (valueChange)="old.set('name', $event)" text="Kundenname"
                 value="{{old.get('name')}}"></app-input>
    </label>

    <div style="display:flex;">
      <app-input (valueChange)="old.set('street', $event)" style="width: 60%" text="Straße + Nr"
                 value="{{old.get('street')}}"></app-input>
      <div style="width: 1em"></div>
      <app-input (valueChange)="old.set('zip_code', $event)" text="PLZ + Ort"
                 value="{{old.get('zip_code')}}"></app-input>
    </div>
    <div class="box hover-animation non-clickable-object">
      <div class="align-mid-vertical">Externe Dokumentation:</div>
      <app-switch (valueChange)="old.set('custom_documentation', $event)"
                  [value]="old.get('custom_documentation')"></app-switch>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="updateClient()" class="button button-blue">
      Fertig
    </div>
  </div>
</div>
