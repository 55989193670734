<div style="display: flex; min-width: 1000px">
  <div class="window-box" style="width: 100%">
    <h1>Admin</h1>

    <app-create-test-row></app-create-test-row>

    <app-send-command></app-send-command>

    <app-daemon></app-daemon>
  </div>
</div>
