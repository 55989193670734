import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dispenser, Floor, Project} from '../../../interfaces/ProjectInterfaces';
import {Constants} from '../../../Constants';
import {LoginService} from '../../../services/login/login.service';
import {DeleteService} from '../../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {formatDate} from '@angular/common';
import {CommonsService} from '../../../services/commons/commons.service';


@Component({
  selector: 'app-dispenser',
  templateUrl: './dispenser.component.html',
  styleUrls: ['./dispenser.component.scss']
})
export class DispenserComponent implements OnInit {


  @Input() dispenser: Dispenser;
  @Input() floor: Floor;
  @Input() dispenser_picture = true;
  @Input() project: Project;

  disable_pic = true;
  @Output() reload = new EventEmitter<boolean>();

  constructor(private loginService: LoginService, private deleteService: DeleteService,
              private modalService: NgbModal, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
  }

  deleteDispenser(delete_data) {
    this.deleteService.deleteDispenser(this.dispenser.id, delete_data).subscribe(data => {
      if (data.success) {
        this.reload.emit(true);
      }
    }, error => {
      this.commonsService.showErrorToast(error, 'Verteiler', 2);
      this.deleteService.errorHandler(error);

    });
  }


  verified(delete_data) {
    this.deleteDispenser(delete_data);
    this.reload.emit(true);
  }

  open(content, event) {
    event.preventDefault();
    this.modalService.open(content).result.then((result) => {
      this.reload.emit(true);
    });
  }

  openDispenserDocumentation() {
    if (this.dispenser.dispenser_documentation) {

      const token = this.loginService.getSessionToken();
      const url = Constants.PYTHON_SERVER_URL + 'documentation/getByQuery?dispenser_id=' + this.dispenser.id.toString() + '&documentation_type=0&session=' + token;
      window.open(url, '_blank');
    } else {

      this.commonsService.showInfoToast('Es existiert noch keine Verteilerdokumentation', 'Achtung', 2);
    }
  }

  openPressureDocumentation(dispenser_id) {
    if (this.dispenser.pressure_documentation) {
      const token = this.loginService.getSessionToken();
      const url = Constants.PYTHON_SERVER_URL + 'documentation/getByQuery?dispenser_id=' + this.dispenser.id.toString() + '&documentation_type=1&session=' + token;
      window.open(url, '_blank');
    } else {
      this.commonsService.showInfoToast('Es existiert noch keine Dichtheitsprüfung', 'Achtung', 2);
    }
  }

  openPictures() {
    const token = this.loginService.getSessionToken();

    const url = 'sharepoint/openPictures?dispenser_id=' + this.dispenser.id + '&session=' + token;


    window.open(Constants.PYTHON_SERVER_URL + url);
  }

  formatDate(date) {
    if (date !== null) {
      return formatDate(date, 'dd.MM.yyyy', 'DE');
    } else {
      return 'nicht gesetzt';
    }
  }
}
