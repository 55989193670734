import {Component, Input, OnInit} from '@angular/core';
import {WeekItem} from '../../../interfaces/WeekInterface';
import {Router} from '@angular/router';
import {Project} from '../../../interfaces/ProjectInterfaces';

@Component({
  selector: 'app-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit {

  @Input() weeks: Array<WeekItem>;
  @Input() project: Project;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  openWeek(year, week, event) {
    event.preventDefault();
    this.router.navigate(
      ['/home'],
      {
        queryParams: {week},
        queryParamsHandling: 'merge'
      });
  }

}
