<div
  style="display: flex; align-content: space-between; min-width: 100%; align-items: baseline; justify-content: space-between;">
  <div style="display: flex; justify-content: space-between">
    <h2>Todo:</h2>
  </div>
</div>
<div (cdkDropListDropped)="drop($event)" cdkDropList>
  <div *ngFor="let item of list" cdkDrag>
    <app-single-todo-template [item]="item"></app-single-todo-template>
    <div *cdkDragPlaceholder class="empty-informationBox"
         style="height: 4em; margin-left: 2em; margin-right: 2em"></div>
  </div>

</div>
