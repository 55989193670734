import {Injectable} from '@angular/core';
import {ServerResponse, ServerResponseArray} from '../../interfaces/DefaultResponse';
import {Constants} from '../../Constants';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {CommonsService} from '../commons/commons.service';
import {FileInterface} from '../../interfaces/FileInterface';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient, private loginService: LoginService, private commonsService: CommonsService) {
  }


  getPlans(project_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponseArray<FileInterface>>(Constants.PYTHON_SERVER_URL + 'file/getFiles', {
      session: token,
      query: 'project_id_plan',
      value: project_id
    });
  }

  uploadPlan(fileToUpload: File, project_id) {
    const endpoint = Constants.PYTHON_SERVER_URL + 'file/uploadPlan';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('session', this.loginService.getValue('session_token'));
    formData.append('project_id', project_id);

    return this.http
      .post<ServerResponse>(endpoint, formData,);
  }

  deletePlan(file_name) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'file/delete', {
      session: token,
      name: file_name
    });
  }

  uploadSignature(fileToUpload: File) {
    const endpoint = Constants.PYTHON_SERVER_URL + 'user/uploadUserSignature';
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('session', this.loginService.getValue('session_token'));

    return this.http
      .post<ServerResponse>(endpoint, formData,);
  }

  deleteSignature(file_name) {

  }

}
