<div class="window-box">
  <div class="inline-box">
    <h4>Vorlagen</h4>
  </div>
  <div style="display: flex;">
    <div class="templates-nav">
      <div (click)="currently_active_component = 0"
           class="box hover-animation clickable-object {{ (currently_active_component == 0) ? 'dark-II' : 'dark-I' }}">
        Todo
      </div>
      <div (click)="currently_active_component = 1"
           class="box hover-animation clickable-object {{ (currently_active_component == 1) ? 'dark-II' : 'dark-I' }}">
        Entsorgung
      </div>
    </div>
    <div class="templates-content small-information-box hover-animation">
      <!-- NOCH SIND BEIDE COMPONENTS AEQUIVALENT (nicht wundern) -->
      <app-todo *ngIf="currently_active_component == 0"></app-todo>
      <app-trash *ngIf="currently_active_component == 1"></app-trash>
    </div>
    <!-- TODO Name, Vorname, Nachname, telefonnummer, passwort -->
    <!-- TODO unterschrift -->
  </div>


</div>
