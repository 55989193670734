<div>

  <div class="modal-header">
    <h4 class="modal-title">Wochenplan Abschnitt bearbeiten</h4>
    <button (click)="close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <table style="width: 100%">
      <tr>
        <td>
          <b>Bauabschnitte ohne Planung</b>
        </td>
        <td *ngFor="let item of week_list; let j = index"><b>Bauabschnitte im Wochenplan {{j + 1}}</b></td>
        <td><b>Neuen Wochenplan erstellen</b></td>

      </tr>
      <tr cdkDropListGroup style="height: 100%">


        <td *ngFor="let area of query_area_list">
            <ul
              [cdkDropListData]="area"
              (cdkDropListDropped)="drop($event)"
              style="height: 100%;   min-height: 300px;"
              cdkDropList>
              <li
                class="drag-item"
                *ngFor="let item of area[1].sub_areas; let i = index">
                <div>
                  <div cdkDrag [cdkDragDisabled]="item.sub_areas.length != 0" [cdkDragData]="[area[0], item, 'section']">
                    <app-small-area [area]="item"></app-small-area>
                    <div *cdkDragPlaceholder class="empty-informationBox" style="height: 5em;"></div>
                  </div>
                  <ul>
                    <li *ngFor="let sub_item of item.sub_areas" >
                      <div cdkDrag [cdkDragData]="[area[0], sub_item, 'floor']">
                        <app-small-area [area]="sub_item"></app-small-area>
                        <div *cdkDragPlaceholder class="empty-informationBox" style="height: 5em;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

            </ul>
        </td>

        <td>
          <div style="height: 100%;   min-height: 60px;"
               (cdkDropListDropped)="dropCreate($event,createWeekPlan )"
               cdkDropList>

          </div>
        </td>
      </tr>

    </table>
  </div>


  <div class="modal-footer">
    <div (click)="close()" class="button button-blue">
      OK
    </div>
  </div>
</div>

<ng-template #createWeekPlan let-modal>
  <app-create-week-plan [modal]="modal" [project]="weekPlan.project" [week]="weekPlan.week"
                        [override_selected_area_id]="override_area_id"
                        [override_selected_area_list]="override_area_list"
                        [override_selected_area_type]="override_area_type"
                        [year]="weekPlan.year" [week_plan]="tmp_week_item"></app-create-week-plan>
</ng-template>
