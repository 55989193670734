<div>

  <div class="modal-header">
    <h4 class="modal-title">Projekt auswählen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="display: flex; justify-content: space-between">
      <div style="width: 40%">
        <app-autocomplete (onObject)="selectClient($event)" *ngIf="client === null" [objectList]="all_clients"
                          [request_focus]="true" [searchObjects]="true" [use_round_design]="true"
                          objectKey="name"
                          text="Kundenname">
        </app-autocomplete>


        <div *ngIf="client != null" class="showData">{{client.name}}</div>
      </div>

      <div style="display: flex; align-items: center; width: 20%; justify-content: center">
        <div>

              <span class="material-icons">
              chevron_right
              </span>

        </div>
      </div>
      <div style="width: 40%">
        <app-autocomplete (onObject)="selectProject($event)" *ngIf="project === null" [objectList]="all_projects"
                          [searchObjects]="true" [use_round_design]="true" objectKey="name"
                          text="Projektname">

        </app-autocomplete>
        <div>
          <div *ngIf="project!= null" class="showData">{{project.name}}</div>
        </div>
      </div>
    </div>
    <app-autocomplete (onCreate)="openCreateSection(createSection)" (onObject)="selectSection($event)"
                      *ngIf="section === null"
                      [createMode]="create_mode" [objectList]="all_section" [searchObjects]="true"
                      [use_round_design]="true"
                      objectKey="name"
                      text="Bauabschnitt">

    </app-autocomplete>
    <div>
      <div *ngIf="section != null" class="showData">{{section.name}}</div>
    </div>


    <app-autocomplete (onCreate)="openCreateFloor(createFloor)" (onObject)="selectFloor($event)"
                      *ngIf="floor === null"
                      [createMode]="create_mode"
                      [objectList]="all_floor" [searchObjects]="true" [use_round_design]="true" objectKey="floor_name"
                      text="Etagen"></app-autocomplete>
    <div *ngIf="floor != null" class="showData">{{floor.floor_name}}</div>


    <ng-template #createFloor let-modal>
      <app-create-floor [modal]="modal"
                        [project_id]="this.project == null? -1: this.project.id"
                        [section_id]="this.section == null? null: this.section.id"></app-create-floor>
    </ng-template>

    <ng-template #createSection let-modal>
      <app-section-create [modal]="modal" [project_id]="this.project == null? -1: this.project.id"></app-section-create>
    </ng-template>
  </div>

  <div class="modal-footer" style="justify-content: space-between">
    <div (click)="closeWithResult()" class="button button-blue">
      weiter
    </div>
  </div>
</div>
