<div class="window-box">
  <div class="inline-box">
    <h4>Teams</h4>

    <div class="inline-box">
      <div class="align-mid-vertical" style="display: flex">
        <span (click)="open(teamCreate, $event)" class="material-icons align-mid-vertical icon-big icon-grey"
              style="padding-right: 0.5em;">
              add_box
        </span>
      </div>
      <label class="align-mid-vertical">
        <input (change)="search($event.target.value)" (input)="search($event.target.value)" class="search-field"
               placeholder="suchen..."
               type="text">
      </label>
    </div>
  </div>
  <div *ngFor="let team of teams; let k = index">
    <details *ngIf="team.visible" class="summary-box hover-animation" style="background-color: {{team.default_color}}1A;
  color: {{team.default_color}};">
      <summary class="heading">
        <div class="align-mid-vertical">
          {{team['team_name']}}
        </div>
      </summary>

      <div style="padding: 1em">
        <div class="small-information-box hover-animation">

          <div *ngFor="let i of keys; let j = index" class="box hover-animation non-clickable-object">
            <div class="align-mid-vertical">{{i}}</div>
            <app-switch (valueChange)="changePermissionValue(team['team_id'],i,$event, k)"
                        [value]="team[i]"></app-switch>
          </div>

          <div *ngFor="let i of value_keys; let j = index" class="box hover-animation non-clickable-object">
            <div class="align-mid-vertical">{{i}}</div>
            <app-input (valueChange)="changeNonPermissionValue(team['team_id'],i,$event, k)" [value]="team[i]"
                       text="Farbe"></app-input>
          </div>

        </div>
      </div>

    </details>
  </div>
</div>


<ng-template #teamCreate let-modal>
  <app-create-team [modal]="modal"></app-create-team>
</ng-template>
