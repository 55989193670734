<div style="display: flex">
  <div style="width: 50%; padding-right: 1em">

    <label (click)="start_picker.open()" class="pure-material-textfield-outlined"
           style="margin-bottom: 0; width: 100%; position: relative">
      <input (dateInput)="input_start_date($event)" [matDatepicker]="start_picker" [value]="start_date" disabled
             matInput placeholder="Start Datum">
      <div style="position: absolute; right: 0; top: .75em">
        <mat-datepicker-toggle [for]="start_picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #start_picker disabled="false"></mat-datepicker>
      </div>
    </label>

  </div>
  <div style="width: 50%;">

    <label (click)="end_picker.open()" class="pure-material-textfield-outlined"
           style="margin-bottom: 0; width: 100%; position: relative">
      <input (dateInput)="input_end_date($event)" [matDatepicker]="end_picker" [value]="end_date" disabled
             matInput placeholder="End Datum">
      <div style="position: absolute; right: 0; top: .75em">
        <mat-datepicker-toggle [for]="end_picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #end_picker disabled="false"></mat-datepicker>
      </div>
    </label>
  </div>
</div>
