<div>

  <div class="modal-header">
    <h4 class="modal-title" style="width: 100%">Wochennummer eingeben</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <div style="display: flex; justify-content: space-around">

      <div>
        <label>
          <app-input (valueChange)="week_number = $event" [change_value]="changeNumber" [request_focus]="true"
                     text="Wochennummer"
                     type="number"
                     (onEnter)="submit()"
                     value="{{week}}"></app-input>
        </label>


      </div>
      <div>
        <label>
          <app-input (valueChange)="calculateWeek($event)" text="Datum" type="date"></app-input>
        </label>


      </div>


    </div>


  </div>
  <div class="modal-footer">
    <div (click)="submit()" class="button button-blue">
      OK
    </div>
  </div>

</div>
