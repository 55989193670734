<details class="summary-box blue hover-animation">
  <summary>
    <h2>Daemon Befehle schicken</h2>
  </summary>
  <div class="summary-content-box">
    <div class="small-information-box">
      <div style="display: flex; justify-content: space-between; width: 50%"><p>Parameter behalten</p>
        <div style="width: 2em"></div>
        <app-switch [(value)]="prevent_rename"></app-switch>
      </div>

      <div (click)="openPicker(projectPicker)" class="button button-blue" style="width: 10em; height: 3em">
        <p>Picker</p>
      </div>
      <div style="width: 50%">
        <app-input [(value)]="admin_token" text="Admin Token"></app-input>
      </div>

      <div style="width: 50%">
        <p *ngIf="check_command(command)"><b>Befehl:<br>
          {{command}}
          <br>
          <br>
        </b></p>
        <app-autocomplete (onChange)="command= $event" *ngIf="!check_command(command) && finished_loading"
                          [data]="commands"
                          [use_round_design]="true" text="Befehl"></app-autocomplete>
      </div>

      <div style="display: flex">
        <app-input [(value)]="client_id" text="client_id"></app-input>
        <app-input [(value)]="project_id" text="project_id"></app-input>
        <app-input [(value)]="section_id" text="section_id"></app-input>
        <app-input [(value)]="floor_id" text="floor_id"></app-input>
        <app-input [(value)]="dispenser_id" text="dispenser_id"></app-input>
        <app-input [(value)]="documentation_id" text="documentation_id"></app-input>
      </div>

      <div (click)="send_command()" class="button button-blue" style="width: 18em; height: 3em">
        Befehl schicken
      </div>

    </div>
  </div>
</details>
<ng-template #projectPicker let-modal>
  <app-project-picker [modal]="modal"></app-project-picker>
</ng-template>
