import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';

@Component({
  selector: 'app-create-floor',
  templateUrl: './create-floor.component.html',
  styleUrls: ['./create-floor.component.scss']
})
export class CreateFloorComponent implements OnInit {

  @Input() modal;
  @Input() project_id;
  @Input() section_id;

  floor_name: string;
  start_date;
  end_date;
  override_create_folder;

  already_requested = false;

  constructor(private createService: CreateService) {
  }

  ngOnInit(): void {
  }

  createFloor() {
    if (this.project_id <= 0) {
      window.alert('Ein Fehler ist aufgetreten. Bitte öffne das Fenster erneut');
    } else {
      if (!this.already_requested) {
        this.already_requested = true;
        this.createService.createFloor(this.project_id, this.floor_name, this.section_id, this.start_date, this.end_date, this.override_create_folder).subscribe(data => {
          this.floor_name = '';
          this.already_requested = false;
          this.createService.commonsService.showSuccessToast("Info", 'Die Etage wurde Erfolgreich erstellt');
        }, error => {
          this.createService.errorHandler(error);
          this.createService.commonsService.showErrorToast(error, 'Die Etage konnte nicht erstellt werden. [Mehr Infos]');
          this.already_requested = false;
        });
      }
    }
  }

  switch_override_create_folder() {
    this.override_create_folder = this.override_create_folder == null ? false : !this.override_create_folder;
  }

  try_set_enddate() {
    if (this.end_date == null) {
      let tmp = this.start_date.toString().split("-");
      tmp.pop()
      this.end_date = tmp.join("-") + "-01"
    }
  }
}
