import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit {

  value = '';
  @ViewChild('autoInputElement') inputAuto: ElementRef;
  @ViewChild('inputElement') inputNormal: ElementRef;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  current_search_value = '';
  @Input() init_value = '';
  @Input() finish_loading;
  @Input() data: Array<string> = [];
  @Input() use_round_design = false;
  @Input() placeholder;
  @Input() request_focus = false;

  @Input() text;

  @Input() searchObjects = false;
  @Input() objectList = [];


  @Input() objectKey = '';
  @Input() objectKeyList = [];
  @Input() objectDisplayKeyList = [];
  @Input() blurr_key;
  @Input() createMode = false;
  @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();


  my_control = new FormControl();
  filtered_options: Observable<string[]>;


  constructor() {

  }

  ngOnInit(): void {
    this.my_control.setValue(this.init_value);

    this.onChange.subscribe(data => {
      this.current_search_value = data;
    });

    this.my_control.valueChanges.subscribe((data) => {

      if (!this.searchObjects) {
        this.onChange.emit(data);
      } else {
        // check if object was selecet or just text
        if (typeof data !== 'string') {
          this.onObject.emit(data);
        }
      }

    });

    this.filtered_options = this.my_control.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );


  }


  ngAfterViewInit() {
    if (this.request_focus) {
      if (this.use_round_design) {
        this.inputAuto.nativeElement.focus();
      } else {
        this.inputNormal.nativeElement.focus();
      }
    }
  }

  displayFn(object) {
    if (typeof object === "string") {
      return object;
    } else {
      return object && object[this.objectKey] ? object.object[this.objectKey] : '';
    }
  }

  build_option(option) {
    let tmp;
    for (const objectDisplayKeyListElement of this.objectDisplayKeyList) {
      if (tmp == null) {
        tmp = option[objectDisplayKeyListElement];
      } else {
        tmp = tmp + ' ' + option[objectDisplayKeyListElement];
      }
    }
    if (tmp == null) {
      return option[this.objectKey];
    }
    return tmp;
  }

  private _filter(value: string): string[] {
    // sometimes its a selfcall, when you update the value.
    // disabled this to not get exception
    if (typeof (value) !== 'string') {
      return;
    }
    const filterValue = value.toLowerCase();


    if (!this.searchObjects) {
      return this.data.filter(option => option.toLowerCase().includes(filterValue));
    } else {
      return this.objectList.filter(option => {

          for (const objectKeyListElement of this.objectKeyList) {
            if (option[objectKeyListElement].toLowerCase().includes(filterValue)) {
              return true;
            }
          }
          return false;
        }
      );
    }
  }
}
