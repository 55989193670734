import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  currently_active_component = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

}
