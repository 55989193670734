<div style="grid-area: head; min-width: 13em;border-right: solid grey 1px;  padding: 0.25em;height: 100%; margin: 0">

  <div class="template-container div-scrollable" style=" max-height: 140px; width: 100%; table-layout: fixed">
    <div (click)="open(showTodoTemplateText, $event)" (contextmenu)="onRightClick($event, menuTrigger)"
         *ngFor="let todo of sort_template_array(weekItem.todo)"
         class="template-box-no-flex template-div clickable-object hover-animation">
      <div>
        {{ todo.template.shortcut }}
      </div>


      <div style="padding-left: 0.5em">
        {{todo.value}} {{todo.template.unit}}
      </div>


      <div>
        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
        <mat-menu #menu="matMenu" style="color: black">
          <button (click)="open(showTodoTemplateText, $event)" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span style="color: black;">To Do bearbeiten</span>
          </button>

          <button (click)="deleteWeekPlanTodo(todo)" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span style="color: black">To Do löschen</span>
          </button>
        </mat-menu>
        <ng-template #showTodoTemplateText let-modal>
          <app-week-plan-todo-update [modal]="modal" [todo]="todo"></app-week-plan-todo-update>
        </ng-template>
      </div>
    </div>

  </div>

  <div>

    <div (click)="open(createTodoTemplateWeekPlan, false)" *ngIf="weekItem.week_plan_set"
         class="smallBox align-mid-vertical hover-animation"
         style="justify-content: space-between; display: flex; cursor: pointer;">
      <div style="margin: auto;">ToDo hinzufügen</div>
      <div class="material-icons">add</div>
    </div>
  </div>
  <div *ngIf="!weekItem.week_plan_set" style="height: 100%">


    <div (click)="open(createWeekPlan, true)" *ngIf="weekItem.week_plan_set == false"
         class="insertPlusButton descriptionBox" style="text-align: center;">
      <div class="plusButton">+</div>
    </div>


  </div>


</div>


<ng-template #createWeekPlan let-modal>
  <app-create-week-plan [modal]="modal" [project]="pro" [week]="week_number" [week_plan]="weekItem"
                        [year]="year"></app-create-week-plan>
</ng-template>


<ng-template #createTodoTemplateWeekPlan let-modal>
  <app-create-todo-week-plan-create [modal]="modal" [weekItem]="weekItem"></app-create-todo-week-plan-create>
</ng-template>
