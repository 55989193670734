import {Component, OnInit} from '@angular/core';

import {DataService} from '../../services/data/data.service';
import {UpdateService} from '../../services/update/update.service';
import {Team} from '../../interfaces/TeamInterface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  teams: Array<Team> = [];
  keys: Array<string> = [];
  value_keys: Array<string> = [];
  tmp_values = {};

  constructor(private dataService: DataService, private updateService: UpdateService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.teams = await this.dataService.getTeams();
    await this.getKeys();
    for (const item of this.teams) {
      item.visible = true;
    }
  }

  async getKeys() {
    let tmp = await this.dataService.getTeamKeys();
    this.keys = tmp.permission_keys;
    this.value_keys = tmp.value_keys;

  }


  changePermissionValue(id, key, value, index) {
    this.updateService.updateTeam(id, key, value).subscribe(
      (tdata) => {
        if (tdata.success) {
          this.teams[index][key] = value;
        }
        this.updateService.commonsService.showSuccessToast('Info', 'Team wurde erfolgreich bearbeitet');
      }, error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Team konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }

  changeNonPermissionValue(id, key, value, index) {
    this.tmp_values[id] = value;
    const wordSearch = value;
    setTimeout(() => {
      if (wordSearch === this.tmp_values[id]) {
        this.changePermissionValue(id, key, value, index);


      }
    }, 2000);
  }

  search(text) {
    this.teams = this.setVisibility(this.teams, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        item.visible = (
          item.team_name.toLowerCase().search(compareStr.toLowerCase()) >= 0
        );
      });
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  open(content, event) {
    if (event != null) {
      event.preventDefault();
    }

    this.modalService.open(content).result.then((result) => {
      this.getData();
    });
  }
}
