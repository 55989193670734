import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UpdateService} from '../../services/update/update.service';
import {TodoTemplate} from '../../interfaces/WeekInterface';

@Component({
  selector: 'app-update-todo-template',
  templateUrl: './update-todo-template.component.html',
  styleUrls: ['./update-todo-template.component.scss']
})
export class UpdateTodoTemplateComponent implements OnInit {

  @Input() modal;
  @Input() todo_template: TodoTemplate;
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  update_values: Map<string, any> = new Map<string, any>();


  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
  }

  valueInput(key, value) {
    this.update_values[key] = value;

  }

  update() {

    let a = 0;
    let b = 0;
    let c = true;
    for (const [k, v] of Object.entries(this.update_values)) {
      a++;

      this.updateService.updateTodoTemplate(this.todo_template.id, k, v).subscribe(data => {
          b++;
          if (a === b) {
            this.close();
          }
        }


        , error => {
          this.updateService.errorHandler(error);
          this.updateService.commonsService.showErrorToast(error, 'Vorlage konnte nicht bearbeitet werden [...]', -1);
        }
      );
      c = false;
    }
    if (c) {
      this.close();
    }

  }

  close() {
    this.reload.emit(true);
    this.modal.close();
  }
}
