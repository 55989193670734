import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-sharepoint-delete',
  templateUrl: './sharepoint-delete.component.html',
  styleUrls: ['./sharepoint-delete.component.scss']
})
export class SharepointDeleteComponent implements OnInit {

  @Input() modal;
  @Input() type;
  @Input() delete = true;

  @Output() sharepoint_delete = new EventEmitter<boolean>();


  verify = '';

  constructor() {
  }

  ngOnInit(): void {
  }
}
