import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  @Input() modal;
  @Input() description;
  @Output() verified = new EventEmitter<boolean>();

  verify = '';

  constructor() {
  }

  ngOnInit(): void {
    this.verified.emit(false);
  }

}
