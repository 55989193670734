<div>
  <div class="modal-header">
    <h4 class="modal-title">Projekt erstellen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <app-input (valueChange)="project_name = $event" [request_focus]="true"
                 text="Name des BV (kein Bauabschnitt, Etage, VT im Namen)" type="text"></app-input>
    </div>

    <p style="font-weight: bold; margin-top: 1em">Anschrift:</p>
    <div style="display: flex">

      <app-input (valueChange)="street = $event" style="width: 60%;" text="Straße + Nr"></app-input>
      <div style="width: 1em;"></div>
      <app-input (valueChange)="plz = $event" text="PLZ + Ort"></app-input>
    </div>
    <br>

    <div style="display: flex; justify-content: space-between">
      <p style="width: 50%; font-weight: bold">Bitte Auftraggeber auswählen:</p>
      <span (click)="open(clientCreateModal)" class="material-icons icon-blue align-mid-vertical"
            style="color: #62B7DF; padding-bottom: 0.25em">add_box</span>
    </div>
    <div style="width: 100%; display: flex; justify-content: space-evenly;">
      <div class="ng-autocomplete" style="margin-bottom: 0.25em">
        <ng-autocomplete
          (inputChanged)='onChangeSearch($event)'
          (inputCleared)="autocompleteCleared()"
          (inputFocused)='onFocused($event)'
          (selected)='selectEvent($event)'
          [data]="this.list"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
          [searchKeyword]="'name'">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>

        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div></div>
        </ng-template>

      </div>
    </div>


    <div style="display: flex; justify-content: space-between">
      <p style="width: 50%; font-weight: bold">Bitte Zweiten Auftraggeber auswählen:</p>
      <span (click)="open(clientCreateModal)" class="material-icons icon-blue align-mid-vertical"
            style="color: #62B7DF; padding-bottom: 0.25em">add_box</span>
    </div>

    <div style="width: 100%; display: flex; justify-content: space-evenly;">
      <div class="ng-autocomplete" style="margin-bottom: 0.25em">
        <ng-autocomplete
          (inputChanged)='onChangeSearch($event)'
          (inputCleared)="autocompleteCleared()"
          (inputFocused)='onFocused($event)'
          (selected)='selectSecondEvent($event)'
          [data]="this.list"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
          [searchKeyword]="'name'">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>

        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div></div>
        </ng-template>

      </div>
    </div>

    <br>

    <app-date-picker [(end_date)]="end_date" [(start_date)]="start_date"></app-date-picker>

    <br>

    <p style="font-weight: bold; margin-top: 1em">Beschreibung:</p>
    <label>
      <textarea (input)="description = $event.target.value" placeholder="Beschreibung"></textarea>
    </label>


    <div>
      <b (click)="getSharepointPath()">Sharepoint Pfad berechnen</b>
      <div (click)="getSharepointPath()" style="border: 1px solid black; border-radius: 25px; padding: 1em">
        <p>{{sharepoint_path}}</p>
      </div>
    </div>
  </div>


  <div class="modal-footer">
    <div (click)="submit()" class="button button-blue">Ok</div>
  </div>
</div>

<ng-template #clientCreateModal let-modal>
  <app-client-create [modal]="modal"></app-client-create>
</ng-template>
