import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LoginService} from '../services/login/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
  isLoggedIn = false;

  constructor(private router: Router, private loginService: LoginService) {
    this.isLoggedIn = this.loginService.is_logged_in;
    this.loginService.logged_in_state.subscribe(data => {
      this.isLoggedIn = data;
    });


  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isLoggedIn) {
      this.router.navigate(['/login'], {queryParams: next.queryParams});
      return false;
    }
    return this.isLoggedIn;
  }
}
