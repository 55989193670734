<div>
  <div class="modal-header">
    <h4 class="modal-title">Nutzer bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">

    <div *ngIf="update_mode == 0" style="display: flex; align-items: baseline">
      <p style="width: 50%">Team auswählen:</p>

      <div style="width: 50%">
        <select (input)="selected_team = $event.target.value" class="custom-select">
          <option selected>Bitte Auswählen</option>
          <option *ngFor="let t of list" value="{{t['team_id']}}">{{t['team_name']}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="update_mode == 1">
      <app-input (input)="old.set('clear_first_name', $event.target.value)" style="width: 100%" text="Vorname"
                 type="text" value="{{old.get('clear_first_name')}}"></app-input>
      <app-input (input)="old.set('clear_last_name', $event.target.value)" style="width: 100%" text="Nachname"
                 type="text" value="{{old.get('clear_last_name')}}"></app-input>
      <app-input (input)="old.set('phone_number', $event.target.value)" style="width: 100%" text="Telefonnummer"
                 type="text" value="{{old.get('phone_number')}}"></app-input>
    </div>
    <br>
  </div>

  <div class="modal-footer">
    <div (click)="submit()" class="button button-blue" style="width: 100%" type="button">Ok</div>
  </div>
</div>
