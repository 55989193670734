import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent implements OnInit {

  @Input() modal;
  @Input() text;
  @Input() title;

  constructor() {
  }

  ngOnInit(): void {

  }
}
