import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currently_active_component = 0;
  user: any = [];


  constructor(private dataService: DataService) {
    this.user.push({
      name: '/',
      clear_last_name: '/',
      clear_first_name: '/',
      joined_at: Date,
      canceled_on: Date,
      team_id: '/',
      mail: '/',
      phone_number: '/',
      visible: '/',
      // only in getAllUser!!
      team_name: '/',

      // only in Available
      available: '/',
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  async refresh() {
    this.user = await this.dataService.getUser();
  }

}
