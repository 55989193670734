<div>

  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <p [innerHTML]="text | Nl2br" style="margin-bottom: 2em; font-size: 1.5em; color: #535353"></p>
  </div>
  <div class="modal-footer">
    <div (click)="modal.close()" class="button button-blue">
      OK
    </div>
  </div>
</div>
