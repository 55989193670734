<div>

  <div class="modal-header">
    <h4 class="modal-title">Verteilerdokumentation hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <!-- First Part -->
    <div *ngIf="inputMainValue" style="margin-top: 1em">
      <div style="display: flex; justify-content: space-between">
        <div style="width: 40%">
          <app-autocomplete (onObject)="selectClient($event)" *ngIf="client === null" [objectList]="all_clients"
                            [request_focus]="true" [searchObjects]="true" [use_round_design]="true"
                            objectKey="name"
                            text="Kundenname">
          </app-autocomplete>


          <div *ngIf="client != null" class="showData">{{client.name}}</div>
        </div>

        <div style="display: flex; align-items: center; width: 20%; justify-content: center">
          <div>

            <span class="material-icons">
            chevron_right
            </span>

          </div>
        </div>
        <div style="width: 40%">
          <app-autocomplete (onObject)="selectProject($event)" *ngIf="project === null" [objectList]="all_projects"
                            [searchObjects]="true" [use_round_design]="true" objectKey="name"
                            text="Projektname">

          </app-autocomplete>
          <div>
            <div *ngIf="project!= null" class="showData">{{project.name}}</div>
          </div>
        </div>
      </div>
      <app-autocomplete (onCreate)="openCreateSection(createSection)" (onObject)="selectSection($event)"
                        *ngIf="section === null"
                        [createMode]="true" [objectList]="all_section" [searchObjects]="true" [use_round_design]="true"
                        objectKey="name"
                        text="Bauabschnitt">

      </app-autocomplete>
      <div>
        <div *ngIf="section != null" class="showData">{{section.name}}</div>
      </div>


      <app-autocomplete (onCreate)="openCreateFloor(createFloor)" (onObject)="selectFloor($event)"
                        *ngIf="floor === null"
                        [createMode]="true"
                        [objectList]="all_floor" [searchObjects]="true" [use_round_design]="true" objectKey="floor_name"
                        text="Etagen"></app-autocomplete>
      <div *ngIf="floor != null" class="showData">{{floor.floor_name}}</div>

    </div>

    <!-- Second Part -->
    <div *ngIf="!inputMainValue" style="margin-top: 1em">

      <app-input [(value)]="this.dispenser_name" text="Verteiler"></app-input>
      <br>

      <!-- temerature + Pressure -->
      <div style="display: flex; justify-content: space-between">
        <app-input [(value)]="temperature" text="Temparatur in °C"></app-input>
        <div style="width: 1em"></div>
        <app-input [(value)]="pressure" text="Druck in bar"></app-input>
      </div>


      <!-- Time + medium -->
      <div style="display: flex; justify-content: space-between">
        <app-input [(value)]="pressure_time" text="Prüfzeit in min"></app-input>
        <div style="width: 1em"></div>
        <app-input [(value)]="pressure_medium" text="Prüfmedium"></app-input>
      </div>

      <br>
      <!-- passed check -->
      <div style="display: flex">

        <div style="display: flex; justify-content: space-between; width: 50%">
          <b class="align-mid-vertical">Prüfung bestanden:</b>
          <app-switch (valueChange)="pressure_passed = $event" [value]="pressure_passed"
                      class="align-mid-vertical"></app-switch>
        </div>
        <div style="width: 1em"></div>

        <app-input (valueChange)="override_date = $event" [value]="today" style="align-self: end" text="Datum auswählen"
                   type="date"></app-input>


      </div>

      <br>
      <!-- Signature Part -->
      <div>
        <p><b>Unterschrift wählen:</b></p>
        <div *ngIf="override_user_name == null">
          <ng-autocomplete
            (inputFocused)='onFocused($event)'
            (selected)='selectEvent($event)'
            [data]="this.available"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            [searchKeyword]="this.keyword">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="'User nicht gefunden'"></div>
          </ng-template>
        </div>

        <div (click)="override_user_name = null" *ngIf="override_user_name != null" class="hover-animation box dark-I"
             style="padding: 1em">
          <div class="align-mid-vertical">{{override_user_name}}</div>
        </div>


      </div>


      <br>
      <b>Heikreise:</b>
      <!-- Row Part -->
      <div>
        <div *ngFor="let row of list; let c = index"
             style="display: flex; justify-content: space-between; margin-top: .2em">
          <app-autocomplete (onChange)="row['key'] = $event" [data]="auto_complete"
                            [request_focus]="c !== 0"
                            [use_round_design]="true"
                            text="Heizkreis"></app-autocomplete>
          <app-input (onEnter)="addRow()" (valueChange)="row['value'] = $event" style="margin-left: 1em"
                     text="Länge in m"></app-input>
        </div>

        <div style="display: flex; justify-content: space-between">
          <div (click)="addRow()" style="cursor:pointer;">
            <u>Reihe hinzufügen</u>
          </div>


          <div (click)="removeRow()" style="cursor:pointer;">
            <u>letzte Reihe löschen</u>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="modal-footer" style="justify-content: space-between">

    <div *ngIf="inputMainValue">
      <mat-icon (click)="clearInput()">
        delete
      </mat-icon>
    </div>

    <div *ngIf="client == null || project == null || floor == null" class="button" disabled
         style="width: 48%">
      {{ inputMainValue ? 'weiter' : 'zurück' }}
    </div>
    <div (click)="inputMainValue = !inputMainValue"
         *ngIf="!(client == null || project == null || floor == null)" class="button button-blue" style="width: 48%">
      {{ inputMainValue ? 'weiter' : 'zurück' }}
    </div>


    <div (click)="createDocumentation()" *ngIf="!inputMainValue" class="button button-blue" style="width: 48%">
      Dokumentation erstellen
    </div>

  </div>

</div>


<ng-template #createFloor let-modal>
  <app-create-floor [modal]="modal"
                    [project_id]="this.project == null? -1: this.project.id"
                    [section_id]="this.section == null? null: this.section.id"></app-create-floor>
</ng-template>

<ng-template #createSection let-modal>
  <app-section-create [modal]="modal" [project_id]="this.project == null? -1: this.project.id"></app-section-create>
</ng-template>
