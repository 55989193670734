import {Component, OnInit} from '@angular/core';
import {CreateService} from "../../../services/create/create.service";
import {LoginService} from "../../../services/login/login.service";
import {DataService} from "../../../services/data/data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-create-test-row',
  templateUrl: './create-test-row.component.html',
  styleUrls: ['./create-test-row.component.scss']
})
export class CreateTestRowComponent implements OnInit {

  client_name;
  project_name;


  constructor(private createServie: CreateService, private loginService: LoginService,
              private dataService: DataService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }


  createTestRow() {
    this.createServie.createClient(
      this.client_name == null ? 'TEST_KUNDE' : this.client_name,
      'Teststraße',
      '01156 Dresden'
    ).subscribe((data) => {
      if (data.success) {
        this.createServie.createProject(
          this.project_name == null ? 'TEST_PROJECT' : this.project_name,
          data.data, '1999-11-10',
          '2121-11-10',
          'Teststraße',
          '01156 Dresden',
          'Automatisches Testprojekt').subscribe((project_data) => {


          this.createServie.createSection(project_data.data, 'Haus A').subscribe((section_data) => {

            this.createServie.createFloor(
              project_data.data,
              'EG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

            this.createServie.createFloor(
              project_data.data,
              'OG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

            this.createServie.createFloor(
              project_data.data,
              'DG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

          });

          this.createServie.createSection(project_data.data, 'Haus B').subscribe((section_data) => {

            this.createServie.createFloor(
              project_data.data,
              'EG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

            this.createServie.createFloor(
              project_data.data,
              'OG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

            this.createServie.createFloor(
              project_data.data,
              'DG',
              section_data.data
            ).subscribe((floor_data) => {
              this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
              this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
                this.createServie.createDispenserDocumentation(
                  dispenser_data.data, [
                    {key: 'Wohnen 1', value: 199},
                    {key: 'Wohnen 2', value: 199},
                    {key: 'Wohnen 3', value: 199},
                    {key: 'Wohnen 4', value: 199},
                    {key: 'Wohnen 5', value: 199},
                  ]
                );
                this.createServie.createPressureDocumentation(dispenser_data.data,
                  35, 3, 30, 'Luft', true, null, null);
              });
            });

          });

          this.createServie.createFloor(
            project_data.data,
            'EG',
            null
          ).subscribe((floor_data) => {
            this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
          });

          this.createServie.createFloor(
            project_data.data,
            'OG',
            null
          ).subscribe((floor_data) => {
            this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
          });


          this.createServie.createFloor(
            project_data.data,
            'DG',
            null
          ).subscribe((floor_data) => {
            this.createServie.createDispenser(floor_data.data, 'VT 01').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 02').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 03').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
            this.createServie.createDispenser(floor_data.data, 'VT 04').subscribe((dispenser_data) => {
              this.createServie.createDispenserDocumentation(
                dispenser_data.data, [
                  {key: 'Wohnen 1', value: 199},
                  {key: 'Wohnen 2', value: 199},
                  {key: 'Wohnen 3', value: 199},
                  {key: 'Wohnen 4', value: 199},
                  {key: 'Wohnen 5', value: 199},
                ]
              );
              this.createServie.createPressureDocumentation(dispenser_data.data,
                35, 3, 30, 'Luft', true, null, null);
            });
          });
        });
      } else {
        window.alert('Client wurde nicht erstellt');
      }
    });
  }
}
