<div
  style="grid-area: head; border-right: solid grey 1px; height: 100%;width: 100%;">

  <div style="height: auto; min-height: 50%">


    <div *ngIf="client != null" style="padding: 0.25em; height: 50%; border-bottom: solid grey 1px;">
      Kunde:
      <div *ngIf="client.name != null"><b>{{client.name}}</b></div>
      <div *ngIf="client.name == null">/</div>
    </div>

    <div *ngIf="secondary_client != null" style="width: 100%; padding: 0.25em;">
      <div *ngIf="secondary_client.name != null"><b>{{secondary_client.name}}</b></div>
    </div>
  </div>


</div>
