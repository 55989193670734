<div class="week-plan-update update-box">
  <div>

    <div style="display: flex; justify-content: end">
      <p (click)="close()" style="cursor: pointer">X</p>
    </div>

    <h1>Wochenplan</h1>
    <div style="width: 100%">
      <h3 (click)="open(showMore)"
          *ngIf="weekItem.project.name != null && weekItem.project.name != ' '"
          style="width: 100%;margin-top: 25px; word-wrap: break-word;">
        {{ weekItem.project.name.length > 20 ? weekItem.project.name.substr(0, 20) + ' [...] ' : weekItem.project.name }}
      </h3>
    </div>


    <p style="font-size: 125%; font-weight: bold">Status:</p>

    <div style="margin-left: .5em">
      <div class="checkbox-frame">
        <p>Material bestellen</p>
        <div class="checkbox-container">
          <input (input)="valueInput('material_order', $event.target.checked)" [checked]="weekItem.material_order"
                 id="confirm0"
                 type="checkbox">
          <label class="confirm" for="confirm0"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Material bestellt</p>
        <div class="checkbox-container">
          <input (input)="valueInput('material_ordered', $event.target.checked)" [checked]="weekItem.material_ordered"
                 id="confirm1"
                 type="checkbox">
          <label class="confirm" for="confirm1"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Material aus Lager</p>
        <div class="checkbox-container">
          <input (input)="valueInput('material_stock', $event.target.checked)" [checked]="weekItem.material_stock"
                 id="confirm2"
                 type="checkbox">
          <label class="confirm" for="confirm2"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Termin angefragt</p>
        <div class="checkbox-container">
          <input (input)="valueInput('date_requested', $event.target.checked)" [checked]="weekItem.date_requested"
                 id="confirm3"
                 type="checkbox">
          <label class="confirm" for="confirm3"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Termin bestätigt</p>
        <div class="checkbox-container">
          <input (input)="valueInput('date_confirmed', $event.target.checked)" [checked]="weekItem.date_confirmed"
                 id="confirm4"
                 type="checkbox">
          <label class="confirm" for="confirm4"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Infos vollständig</p>
        <div class="checkbox-container">
          <input (input)="valueInput('info_completed', $event.target.checked)" [checked]="weekItem.info_completed"
                 id="confirm5"
                 type="checkbox">
          <label class="confirm" for="confirm5"></label>
        </div>
      </div>

      <div class="checkbox-frame">
        <p>Dokumentation erledigt</p>
        <div class="checkbox-container">
          <input (input)="valueInput('documentation_done', $event.target.checked)"
                 [checked]="weekItem.documentation_done"
                 id="confirm6"
                 type="checkbox">
          <label class="confirm" for="confirm6"></label>
        </div>
      </div>
    </div>
  </div>


  <h5>Projektbeschreibung:</h5>
  <div>
    <div class="content-div">
      <h6>Ansprechpartner:</h6>

      <app-template-selector
        (add_action)="changeClientContact($event, true)"
        (create_action)="open(newClientContact)"
        (remove_action)="changeClientContact($event, false)"
        [available]="all_client_contacts"
        [selected]="weekItem.client_contact"
        search_word="first_name"
        second_search_word="last_name"></app-template-selector>
    </div>

    <div class="content-div">
      <h6>Projektleiter:</h6>
      <div class="input-autocomplete-width">
        <app-autocomplete (onChange)="valueInput('responsible_user_name', $event)"
                          [data]="allUserNamesToList(this.all_user_names)"
                          [init_value]="weekItem.responsible_user_name">

        </app-autocomplete>
      </div>
    </div>

    <div class="content-div">
      <h6>Fabrikat</h6>
      <div class="input-autocomplete-width">
        <app-autocomplete (onChange)="valueInput('manufacture', $event)"
                          [data]="allManufactureNamesToList(this.all_manufacture)"
                          [init_value]="weekItem.manufacture">

        </app-autocomplete>
      </div>
    </div>


    <div class="content-div">
      <h6>Absprachen</h6>
      <textarea (input)="valueInput('agreements', $event.target.value)"
                placeholder="Übereinstimmungen/Absprachen" style="width: 100%"
                value="{{weekItem.agreements}}"></textarea>
    </div>

    <div class="content-div">
      <h6>Spezialwerkzeug</h6>
      <textarea (input)="valueInput('special_tools', $event.target.value)"
                placeholder="Spezialwerkzeug"
                style="width: 100%"

                value="{{weekItem.special_tools}}"></textarea>
    </div>


    <div class="content-div">
      <h6>Materialbedarf:</h6>
      <textarea

        (input)="valueInput('note', $event.target.value)"
        [value]="weekItem.note"
        cdkAutosizeMaxRows="10"
        cdkAutosizeMinRows="2" cdkTextareaAutosize
        placeholder="Materialbedarf" style="white-space: pre-wrap;"></textarea>
    </div>

    <div class="content-div">

      <div>
        <p style="margin-top: 1em; font-weight: bold">Müllbeseitigung:</p>
        <app-template-selector (add_action)="changeTrashTemplate($event, true); trash_div_disabled = true;"
                               (create_action)="open(newTrashTemplate)"
                               (remove_action)="changeTrashTemplate($event, false)"
                               [available]="trash_templates"

                               [selected]="trashToTemplate()"
                               search_word="name"
                               style="{{ trash_div_disabled ? 'pointer-events: none' : '' }}"
        >

        </app-template-selector>
      </div>
    </div>


  </div>
</div>


<ng-template #newTrashTemplate let-modal>
  <app-trash-template-create (done)="reloadTrashTemplates($event)" [modal]="modal"></app-trash-template-create>
</ng-template>
<ng-template #showMore let-modal>
  <app-show-more [modal]="modal" [text]="'Projektname: ' + weekItem.project.name"
                 [title]="'Mehr anzeigen'"></app-show-more>
</ng-template>

<ng-template #newClientContact let-modal>
  <app-client-contact-create (done)="reloadAll()" [client_id]="weekItem.project.client_id"
                             [modal]="modal"></app-client-contact-create>
</ng-template>
