import {Component, OnInit} from '@angular/core';

import {LoginService} from '../../services/login/login.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  isLoggedIn = false;

  constructor(private loginService: LoginService) {
    this.loginService.logged_in_state.subscribe(data => {
      this.isLoggedIn = data;
    });
    this.isLoggedIn = this.loginService.is_logged_in;
  }

  ngOnInit(): void {
  }


}
