import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Integrations} from '@sentry/tracing';
import {Constants} from './app/Constants';

if (environment.production) {
  Sentry.init({
    dsn: Constants.SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://montagespezis.app/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
