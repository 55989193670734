<label class="pure-material-textfield-outlined {{valid? '': 'border-red'}}"
       style="margin-bottom: 0; width: 100%; position: relative">
  <input #inputElement
         (input)="valueInput($event.target.value)"
         (keyup.enter)="onEnter.emit()" [(ngModel)]="value"
         [disabled]="disabled"
         [type]="type"
         placeholder=" {{placeholder}}"
  >
  <span>{{text}}</span>
  <div (click)="openHelp(showHelp)" *ngIf="!valid" style="position: absolute; top: 1.5em; right: 1em">
    <mat-icon>help_outline</mat-icon>
  </div>
</label>


<ng-template #showHelp let-modal>
  <app-show-more [modal]="modal" [text]="failed_validation[0].get_error_message()"
                 [title]="'Information'"></app-show-more>
</ng-template>
