<details class="summary-box dark-II hover-animation" >
  <summary style="display: block">
    {{area.section_id != null ? area.section.name : area.floor.floor_name}}

  </summary>

  <div class="summary-content-box">
    <div class=" small-information-box">
      <p>Startdatum:</p>
      <ul *ngIf="area.section != null"><li>{{ formatDate(area.section.start_date)}}</li></ul>
      <ul *ngIf="area.floor != null"><li>{{ formatDate(area.floor.start_date)}}</li></ul>
      <p>Enddatum:</p>
      <ul *ngIf="area.section != null"><li>{{ formatDate(area.section.end_date)}}</li></ul>
      <ul *ngIf="area.floor != null"><li>{{ formatDate(area.floor.end_date)}}</li></ul>
    </div>
  </div>


</details>
