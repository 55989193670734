import {Component, EventEmitter, OnInit} from '@angular/core';
import {Project} from '../../interfaces/ProjectInterfaces';
import {DataService} from '../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteService} from '../../services/delete/delete.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  list: Array<Project> = [];
  is_open: Array<boolean> = [];
  selected_channel: number;
  channel_opener: EventEmitter<number> = new EventEmitter<number>();

  search_text = '';


  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService,
              private modalService: NgbModal, private deleteService: DeleteService) {
  }

  ngOnInit(): void {
    this.refresh(true);
    this.route.queryParams.subscribe(data => {
      if (data.project_id != null) {
        this.search_text = 'id:' + data.project_id.toString();
      }
    });
  }

  async refresh(bool) {
    if (bool) {
      const data = await this.dataService.getAllProjects();
      this.list = data;
      this.list.forEach(item => {
        item.visible = true;
      });
      this.is_open = [];
      for (const i of data) {
        this.is_open.push(false);
      }

      if (this.search_text.length > 0) {
        this.search(this.search_text);
      }


      this.channel_opener.subscribe(data => {
        this.selected_channel = data;
      });
    }
  }


  open(content, event, reload = true) {
    if (event != null) {
      event.preventDefault();
    }

    this.modalService.open(content).result.then((result) => {
      if (reload) {
        this.refresh(true);
      }
    });
  }


  search(text: string) {
    if (!text.startsWith('id:')) {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {},

        }).then();
    }
    this.list = this.setVisibility(this.list, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        item.visible = (
            item.description !== null && item.description.toLowerCase().search(compareStr.toLowerCase()) >= 0) ||
          item.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.street.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.zip_code.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          'id:' + item.id.toString() === compareStr.toLowerCase() ||
          item.id.toString() === compareStr.toLowerCase() ||
          (item.client != null && item.client.name.toLowerCase().search(compareStr.toLowerCase()) >= 0) ||
          (item.creator_name != null && item.creator_name.toLowerCase().search(compareStr.toLowerCase()) >= 0);
      });
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  deleteProject(project) {
    this.list = this.list.filter(obj => obj !== project);
  }

}
