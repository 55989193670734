<div>

  <div class="modal-header">
    <h4 class="modal-title">Ansprechpartner hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">


    <app-input (valueChange)="first_name = $event" [request_focus]="true" text="Vorname"></app-input>
    <app-input (valueChange)="last_name = $event" text="Nachname"></app-input>
    <app-input (valueChange)="phone_number = $event" text="Telefonnummer"></app-input>
    <app-input (valueChange)="mail = $event" text="Mail"></app-input>


  </div>
  <div class="modal-footer">
    <div (click)="createClient()" class="button button-blue">
      Ansprechpartner hinzufügen
    </div>
  </div>

</div>
