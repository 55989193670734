import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseRule} from "../../services/validation/Validation";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, AfterViewInit {

  @ViewChild('inputElement') yourControl: ElementRef;
  @Input() text;
  @Input() type;
  @Input() placeholder;
  @Input() request_focus = false;
  @Input() disabled = false;
  @Input() change_value: EventEmitter<any>;
  @Input() manual_request_focus: EventEmitter<boolean>;

  @Input() value;
  last_value;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();


  @Input() manual_check_rules: EventEmitter<boolean>;
  valid = true;
  failed_validation: Array<BaseRule> = []
  @Input() validationRules: Array<BaseRule> = [];
  @Output() validInput: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.valueChange.subscribe((data) => {
      this.value = data;
    });

    if (this.change_value != null) {
      this.change_value.subscribe(data => {
        this.value = data;
      });
    }
    if (this.manual_request_focus != null) {
      this.manual_request_focus.subscribe((data) => {
        this._manual_request_focus();
      });
    }
    if (this.manual_check_rules != null) {
      this.manual_check_rules.subscribe((data) => {
        this.checkValidation(this.last_value);
      });
    }
  }


  ngAfterViewInit() {
    if (this.request_focus) {
      this.yourControl.nativeElement.focus();
    }
  }

  _manual_request_focus() {
    this.yourControl.nativeElement.focus();
  }

  checkValidation(value) {
    this.failed_validation = [];
    let check = true;
    for (const validationRule of this.validationRules) {
      if (!validationRule.check_value(value)) {
        check = false;
        this.failed_validation.push(validationRule);
        this.validInput.emit(false);
      }
    }
    this.valid = check;
  }

  valueInput(value) {
    this.checkValidation(value);
    this.last_value = value;
    this.valueChange.emit(value);
  }

  openHelp(content) {
    if (this.failed_validation.length > 0) {
      this.modalService.open(content).result.then(() => {
      });
    }
  }
}
