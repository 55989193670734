<div (mouseenter)="navHovered(false)" (mouseleave)="navHovered(true)" class="nav-default nav">
  <ul class="button-list">
    <app-nav-button
      [design]=""
      [icon]="'home'"
      [key]="'home'"
      [name]="!this.hovering ? 'Wochenplanung' : ''">
    </app-nav-button>
    <app-nav-button
      [design]=""
      [icon]="'description'"
      [key]="'project'"
      [name]="!this.hovering ? 'Projekte' : ''">
    </app-nav-button>
    <app-nav-button
      [design]=""
      [icon]="'business_center'"
      [key]="'client'"
      [name]="!this.hovering ? 'Kunden' : ''">
    </app-nav-button>


    <app-nav-button
      (click)="openHelp()"
      [design]=""
      [icon]="'report'"
      [name]="!this.hovering ? 'Fehler' : ''">
    </app-nav-button>

    <app-nav-button
      [design]=""
      [icon]="'turned_in'"
      [key]="'template'"
      [name]="!this.hovering ? 'Vorlagen' : ''">
    </app-nav-button>
    <app-nav-button
      (click)="openSharepoint()"
      [design]=""
      [icon]="'folder'"
      [name]="!this.hovering ? 'Sharepoint' : ''">
    </app-nav-button>
    <app-nav-button
      (click)="openDev()"
      [design]=""
      [icon]="'bug_report'"
      [name]="!this.hovering ? 'Testumgebung' : ''">
    </app-nav-button>
    <app-nav-button
      (click)="open_admin()"
      *ngIf="show_admin()"
      [design]=""
      [icon]="!admin_nav? 'security':'expand_less'"
      [name]="!this.hovering ? 'Admin' : ''"
      [prevent_navigation]="true">
    </app-nav-button>

    <app-nav-button
      *ngIf="admin_nav"
      [design]=""
      [icon]="'settings'"
      [key]="'admin'"
      [name]="!this.hovering ? 'Admin' : ''">
    </app-nav-button>

    <app-nav-button
      *ngIf="admin_nav"
      [design]=""
      [icon]="'person'"
      [key]="'user'"
      [name]="!this.hovering ? 'Nutzer' : ''">
    </app-nav-button>
    <app-nav-button
      *ngIf="admin_nav"
      [design]=""
      [icon]="'group'"
      [key]="'team'"
      [name]="!this.hovering ? 'Team' : ''">
    </app-nav-button>
  </ul>
  <div>
    <app-nav-button
      [design]=""
      [icon]="'settings'"
      [key]="'settings'"

      [name]="!this.hovering ? 'Einstellungen' : ''">
    </app-nav-button>
  </div>
</div>
