import {Component, Input, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-small-area',
  templateUrl: './small-area.component.html',
  styleUrls: ['./small-area.component.scss']
})
export class SmallAreaComponent implements OnInit {

  @Input() area: any;


  constructor() { }

  ngOnInit(): void {
  }

  formatDate(date) {
    if (date !== null) {
      return formatDate(date, 'dd.MM.yyyy', 'DE');
    } else {
      return 'nicht gesetzt';
    }
  }

}
