<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login100-pic js-tilt" data-tilt>
        <img alt="IMG" src="../../../assets/pictures/MS-Logo-White.png">
      </div>


      <app-login-user (openRegister)="openRegister()" (openReset)="openReset()" *ngIf="login"></app-login-user>

      <app-register (open_login)="openLogin($event)" *ngIf="register"></app-register>

      <app-reset (openLogin)="openLogin($event)" *ngIf="reset_password"></app-reset>

    </div>
  </div>
</div>


<!--
<div class="flex-frame-container">


  <div class="login-form-body"  style="align-self: center; max-width: 40vw">



    <app-login-user (openRegister)="openRegister()" (openReset)="openReset()" *ngIf="login"></app-login-user>

    <app-register (open_login)="openLogin($event)" *ngIf="register"></app-register>

    <app-reset (openLogin)="openLogin($event)" *ngIf="reset_password"></app-reset>


  </div>
</div>
-->
