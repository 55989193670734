<div>

  <div class="modal-header">
    <h4 class="modal-title">ToDo-Template erstellen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="margin-top: 1em">
      <app-input [(value)]="name" [request_focus]="true" text="Templatename"></app-input>
    </div>

    <div style="margin-top: 1em">
      <app-input [(value)]="shortcut" text="Kürzel (max. 4 Zeichen)"></app-input>
    </div>

    <div style="margin-top: 1em">
      <p>Text</p>
      <label>
        <textarea (input)="text = $event.target.value" placeholder="text"></textarea>
      </label>
    </div>

    <div style="margin-top: 1em">
      <app-input [(value)]="unit" text="Einheit"></app-input>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="createTodoTemplate()" class="button button-blue">
      Template hinzufügen
    </div>
  </div>
</div>
