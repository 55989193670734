import {Component, Input, OnInit} from '@angular/core';
import {Client} from "../../interfaces/ClientInterface";
import {Floor, Project, Section} from "../../interfaces/ProjectInterfaces";
import {CreateService} from "../../services/create/create.service";
import {DataService} from "../../services/data/data.service";
import {LoginService} from "../../services/login/login.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-create-advanced-documentation',
  templateUrl: './create-advanced-documentation.component.html',
  styleUrls: ['./create-advanced-documentation.component.scss']
})
export class CreateAdvancedDocumentationComponent implements OnInit {


  @Input() modal;

  client_name;
  client: Client = null;
  all_clients = [];

  project: Project = null;
  all_projects = [];

  section: Section = null;
  all_section = [];

  floor: Floor = null;
  all_floor = [];

  dispenser_name;
  dispenser_id: number;

  // dispenser Documentation
  auto_complete = [];
  list: Array<{ key: string, value: number }> = [{key: '', value: 0}];

  inputMainValue = true;

  // pressure Documentation
  temperature = 0;
  pressure = 3;
  pressure_time = 30;
  pressure_medium = 'Luft';
  pressure_passed = true;
  override_user_name = null;
  user_name = null;
  keyword = 'name';
  available = [];

  today;
  override_date;

  constructor(private createService: CreateService, private dataService: DataService,
              private loginService: LoginService, private modalService: NgbModal, public datePipe: DatePipe) {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.load_data()

  }

  async load_data() {
    this.auto_complete = await this.dataService.getDocumentationHK();
    this.all_clients = await this.dataService.getClients();


    const jwt_data = this.loginService.getUsername();
    this.override_user_name = jwt_data.name;
    this.user_name = jwt_data.name;
  }

  addRow() {
    this.list.push({key: '', value: 0});
  }

  removeRow() {
    this.list.pop();
  }

  createDocumentation() {
    this.createService.createDispenser(this.floor.id, this.dispenser_name).subscribe((data) => {
      this.dispenser_id = data.data;
      this.createService.createDispenserDocumentation(this.dispenser_id, this.list).subscribe((dispenser) => {

        this.createService.createPressureDocumentation(this.dispenser_id, this.temperature, this.pressure,
          this.pressure_time, this.pressure_medium
          , this.pressure_passed, this.override_user_name, this.override_date).subscribe((pressure) => {
          this.list = [{key: '', value: 0}];
          this.dispenser_name = '';
        }, error => {
          this.createService.errorHandler(error);
          this.createService.commonsService.showErrorToast(error, 'Die Dichtheitsprüfung konnte nicht erstellt werden. [Mehr Infos]');
        });


      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Verteilerdokumentation konnte nicht erstellt werden. [Mehr Infos]');
      });
    });


  }

  async selectClient(client: Client) {
    this.client = client;

    this.all_projects = await this.dataService.getProjectsByClient(client.id);

  }

  async selectProject(project: Project) {
    this.project = project;
    this.all_section = await this.dataService.getSectionByProject(project.id);
    this.all_floor = await this.dataService.getFloorByProject(project.id);
  }

  async selectSection(section: Section) {
    this.section = section;
    this.all_floor = await this.dataService.getFloorBySection(section.id);
  }

  selectFloor(floor: Floor) {
    this.floor = floor;
  }

  async onFocused(e) {
    this.available = await this.dataService.getAllUser();
  }

  selectEvent(item) {
    this.override_user_name = item.name;
  }

  openCreateFloor(content) {
    if (this.project == null) {
      window.alert('Bitte wähle zuerst ein Projekt aus');
    } else {
      this.modalService.open(content).result.then(async (result) => {
        if (this.section == null) {
          this.all_floor = await this.dataService.getFloorByProject(this.project.id);
        } else {
          this.all_floor = await this.dataService.getFloorByProject(this.project.id);
        }


      });
    }
  }

  openCreateSection(content) {
    if (this.project == null) {
      window.alert('Bitte wähle zuerst ein Projekt aus');
    } else {


      this.modalService.open(content).result.then(async (result) => {
        this.all_section = await this.dataService.getSectionByProject(this.project.id);
      });

    }
  }

  clearInput() {
    this.client = null;
    this.project = null;
    this.section = null;
    this.floor = null;
    this.dispenser_id = null;
  }

}
