<div>

  <div class="modal-header">
    <h4 class="modal-title">Ansprechpartner</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div *ngIf="!edit_mode">
      <div>
        <b>Vorname</b>
        <p>{{client_contact.first_name}}</p>
      </div>
      <br>
      <div>
        <b>Nachname</b>
        <p>{{client_contact.last_name}}</p>
      </div>
      <br>
      <div>
        <b>Handy</b>
        <p>{{client_contact.phone_number}}</p>
      </div>
      <br>
      <div>
        <b>E-mail</b>
        <p>{{client_contact.mail}}</p>
      </div>
    </div>
    <div *ngIf="edit_mode">
      <app-input [(value)]="first_name" [request_focus]="true" text="Vorname"></app-input>
      <app-input [(value)]="last_name" text="Nachname"></app-input>
      <app-input [(value)]="mail" text="E-Mail"></app-input>
      <app-input [(value)]="phone_number" text="Handy"></app-input>
    </div>
  </div>


  <div class="modal-footer">
    <div (click)="updateValues()" class="button button-blue">
      OK
    </div>
  </div>
</div>
