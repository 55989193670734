import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../interfaces/ProjectInterfaces';
import {UpdateService} from '../../services/update/update.service';
import {Client} from '../../interfaces/ClientInterface';
import {CreateService} from '../../services/create/create.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-project-update',
  templateUrl: './update-project.component.html',
  styleUrls: ['./update-project.component.scss']
})
export class UpdateProjectComponent implements OnInit {

  @Input() modal;
  @Input() project: Project;


  update_values: Map<string, any> = new Map<string, any>();

  list: Array<Client> = [];


  project_name;
  client_id;
  start_date;
  end_date;
  street;
  plz;
  description;
  current_client;

  constructor(private modalService: NgbModal, private updateService: UpdateService,
              private createService: CreateService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.loadClients();
    this.start_date = this.project.start_date;
    this.end_date = this.project.end_date;
  }


  valueInput(key, value) {
    this.update_values[key] = value;
  }

  update() {


    let a = 0;
    let b = 0;
    let c = true;
    for (const [k, v] of Object.entries(this.update_values)) {
      a++;

      this.updateService.updateProject(this.project.id, v, k).subscribe(data => {
          b++;
          if (a === b) {
            this.modal.close();
          }
        }, error => {
          this.updateService.errorHandler(error);
          this.updateService.commonsService.showErrorToast(error, 'Projekt konnte nicht bearbeitet werden [...]', -1);
        }
      );


      c = false;
    }
    if (c) {
      this.modal.close();
    }
  }

  formatDate(date) {
    return new Date(date);
  }


  autocompleteCleared() {
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    this.loadClients();
  }

  async loadClients() {


    this.list = [];
    for (let i of await this.dataService.getClients()) {
      if (this.project.client_id === i.id) {
        this.current_client = i;
      } else {
        this.list.push(i);
      }
    }


  }


  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    });
  }

  try_set_enddate() {
    if (this.end_date == null) {
      let tmp = this.start_date.toString().split("-");
      tmp.pop()
      this.end_date = tmp.join("-") + "-01"
    }
  }
}
