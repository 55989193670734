<div *ngIf="!areas_selected">
  <div *ngIf="week_item.week_plan_project_exist && already_exist_selected == null">
    In dieser Woche existiert bereits ein Plan für dieses Projekt. Sollen die fehlenden Bauabschnitte hinzugefügt
    werden?

    <div style="display: flex">
      <div (click)="already_exist_selected = true" class="button-blue button" style="margin: 1em">Ja, Bauabschitte
        hinzufügen
      </div>
      <div (click)="already_exist_selected = false" class="button-green button" style="margin: 1em"> Nein, extra Planung
        erstellen
      </div>
    </div>
  </div>

  <div *ngIf="!week_item.week_plan_project_exist || already_exist_selected != null">
    <h6>Bitte die Bauabschnitte auswählen</h6>


    <div>
      <p>Projekt: {{week_item.area.project.name}}</p>
      <ul>
        <li *ngFor="let area of week_item.area.sub_areas">

          <div *ngIf="area.section != null">
            <div class="hover-animation list-item">
              <div class="list-name">{{area.section.name}}</div>
              <app-switch [(value)]="section_ids[area.section.id][0]"></app-switch>
            </div>

            <div>
              <ul style="padding-left: 2em">
                <li *ngFor="let sub_area of area.sub_areas">
                  <div  class="hover-animation list-item">
                    <div class="list-name">{{sub_area.floor.floor_name}}</div>
                    <app-switch [(value)]="floor_ids[sub_area.floor.id][0]"></app-switch>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="area.floor != null">

            <div class="list-name">{{area.floor.floor_name}}</div>
            <div class="list-switch"><app-switch [(value)]="floor_ids[area.floor.id][0]"></app-switch></div>

          </div>

        </li>


      </ul>

    </div>


    <div (click)="area_select()" class="button-blue button">
      <p>{{already_exist_selected != null && already_exist_selected == true ? 'Fertig' : 'Weiter' }}</p>
    </div>
  </div>
</div>

<div *ngIf="areas_selected">
  <h6 style="font-size: 150%">Möchtest du die Daten aus der letzten Woche importieren?</h6>
  <div style="display: flex; justify-content: space-around; font-size: 150%">
    <button (click)="loadLastWeek.emit(false)" class="jn-button" style="color: black">Nein</button>
    <button (click)="loadLastWeek.emit(true)" class="jn-button" style="background-color: rgba(232, 84, 48, 0.8);">
      Ja
    </button>
  </div>
</div>
