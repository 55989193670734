import {Component, OnInit} from '@angular/core';
import {FileService} from "../../../../services/file/file.service";
import {CommonsService} from "../../../../services/commons/commons.service";

@Component({
  selector: 'app-update-signature',
  templateUrl: './update-signature.component.html',
  styleUrls: ['./update-signature.component.scss']
})
export class UpdateSignatureComponent implements OnInit {
  files: any[] = [];

  constructor(private fileService: FileService, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
  }

  onFileDropped($event) {
    this.uploadFiles($event);
  }

  fileBrowseHandler(files) {
    this.uploadFiles(files);
  }


  uploadFiles(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }

    for (const item of this.files) {
      this.fileService.uploadSignature(item).subscribe((data) => {
        if (data.success) {
        } else {
          this.commonsService.showInfoToast('Datei konnte nicht hochgeladen werden', 'Achtung', 0);
        }
      });
    }
    this.files = [];
  }
}
