<div *ngIf="user != undefined">
  <div class="box hover-animation"><b>Benutzername:</b> {{user.name}} </div>
  <div class="box hover-animation"><b>Nachname:</b> {{user.clear_last_name}} </div>
  <div class="box hover-animation"><b>Vorname:</b> {{user.clear_first_name}} </div>
  <div class="box hover-animation"><b>E-Mail:</b> {{user.mail}} </div>
  <div class="box hover-animation"><b>Telefonnummer:</b> {{user.phone_number}} </div>
  <div class="box hover-animation"><b>Team:</b> {{user.team_name}} </div>
  <div class="box hover-animation"><b>Account erstellt am:</b> {{user.joined_at}} </div>

  <div class="button-box">
    <div (click)="open(updateUser)" class="button button-grey align-mid-vertical">bearbeiten</div>
    <ng-template #updateUser let-modal>
      <app-user-update [modal]="modal" [update_mode]="1" [user]="user"></app-user-update>
    </ng-template>
  </div>
</div>
