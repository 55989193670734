import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateService} from '../../services/create/create.service';

@Component({
  selector: 'app-todo-value-input',
  templateUrl: './todo-value-input.component.html',
  styleUrls: ['./todo-value-input.component.scss']
})
export class TodoValueInputComponent implements OnInit {

  @Input() modal;
  @Input() template;
  @Output() value = new EventEmitter<number>();

  value_var: number = null;

  constructor(private createService: CreateService) {
  }

  inputValue(value) {
    // tslint:disable-next-line:radix
    this.value_var = parseInt(value);
  }


  ngOnInit(): void {
  }
}
