import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  modes = ['bearbeitet', 'erstellt', 'gelöscht'];

  constructor(private http: HttpClient, private toaster: ToastrService, private modalService: NgbModal) {
  }


  showInfoToast(message: string, title, number: number) {
    this.toaster.warning(message, title);
  }

  successHandlerWithData(data: any, re: any, type: string, mt: number) {
    re.next(data);
    this.showSuccessToast('Info', type + 'wurde erfolgreich' + this.modes[mt]);
  }


  showSuccessToast(title, message) {
    this.toaster.info(message, title);
  }

  showErrorToast(error, message, mode = null) {
    this.toaster.error(message, 'Fehler').onTap.subscribe((data) => {
      let error_message = 'Es gibt keine genaueren Informationen';
      if ((error.error !== null && error.error !== undefined) && (error.error.error !== null && error.error.error !== undefined)) {
        error_message = error.error.error;
      }

      this.modalService.open('Warnung\n\n' + message + '\n\n\n' + error_message);
    });
  }
}
