import {Component, Input, OnInit} from '@angular/core';
import {WeekItem} from '../../../../interfaces/WeekInterface';
import {UpdateService} from "../../../../services/update/update.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-hover-balls',
  templateUrl: './hover-balls.component.html',
  styleUrls: ['./hover-balls.component.scss']
})
export class HoverBallsComponent implements OnInit {

  @Input() weekItem: WeekItem;
  @Input() index: number;

  constructor(private updateService: UpdateService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `calendar_done`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`./../../../../../assets/icons/calendar_done.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `calendar_undone`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`./../../../../../assets/icons/calendar_undone.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `cart_done`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`./../../../../../assets/icons/cart_done.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `cart_undone`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`./../../../../../assets/icons/cart_undone.svg`)
    );
  }

  ngOnInit(): void {
  }

  update(key, value): boolean {
    if (this.index != null) {
      this.updateService.updateWeekPlan(this.weekItem.id, key, value).subscribe(data => {
        if (data.success) {
          return value;
        } else {
          return !value;
        }
      });
      return value;
    }
  }
}
