<div class="window-box">
  <div class="inline-box">
    <h4>Einstellungen</h4>
  </div>
  <div style="display: flex;">
    <div class="settings-nav">
      <div (click)="currently_active_component = 0"
           class="box hover-animation clickable-object {{ (currently_active_component == 0) ? 'dark-II' : 'dark-I' }}">
        Übersicht
      </div>
      <div (click)="currently_active_component = 1"
           class="box hover-animation clickable-object {{ (currently_active_component == 1) ? 'dark-II' : 'dark-I' }}">
        Kontodaten
        bearbeiten
      </div>
    </div>
    <div class="settings-content small-information-box hover-animation">
      <!-- TODO Konto bearbeiten einrichten -->
      <app-user-properties (request_reload)="refresh()"></app-user-properties>
    </div>
    <!-- TODO Name, Vorname, Nachname, telefonnummer, passwort -->
    <!-- TODO unterschrift -->
  </div>


</div>
