import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data/data.service';
import {DatePipe} from '@angular/common';

import {Day} from '../../../interfaces/DayInterface';
import {UpdateService} from '../../../services/update/update.service';
import {LoginService} from "../../../services/login/login.service";
import {TimeService} from "../../../services/time/time.service";


@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit, OnChanges {

  @Input() week_number;
  @Input() year;
  @Input() info_four_width;

  @Output() next_week = new EventEmitter<any>();
  @Output() previous_week = new EventEmitter<any>();
  @Output() open_week: EventEmitter<number> = new EventEmitter<number>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  dates = [];
  day_values = [];

  override_icon_visible = false
  days: Array<Day> = [];
  day_names = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  fix_week = false;

  constructor(private modalService: NgbModal, private dataService: DataService,
              public datePipe: DatePipe, private updateService: UpdateService,
              private authService: LoginService, private timeService: TimeService) {
  }

  ngOnInit(): void {
    this.loadData();
    this.check_fix_week();
  }

  check_fix_week() {
    return this.fix_week = this.authService.getValue("fix_week") != null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  async loadData() {
    if (this.year === null || this.week_number === null || this.year === undefined || this.week_number === undefined) {
      return;
    }

    this.dates = this.dataService.getDateRangeOfWeek(this.week_number, this.year);
    for (const date of this.dates) {
      if (isNaN(date.getTime())) {
        return;
      }
    }
    this.days = [];
    for (let i = 0; i < 7; i++) {
      this.days[i] = null;
    }

    const tmp = [];
    for (const i of await this.dataService.getDayInfoOfWeek(this.datePipe.transform(this.dates[0], 'yyyy-MM-dd'))) {
      if (i.info === '/') {
        i.info = '';
      }
      tmp.push(i);
    }
    this.days = tmp;
  }


  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  dayInput(index, value) {
    this.timeService.timed_update(index, value, this.updateService, this.updateService.updateDay,
      [this.datePipe.transform(this.dates[index], 'yyyy-MM-dd'), value]);
  }


  dateToString(date: any) {
    if (date != null) {
      const split = date.split('-');
      return split[2] + '.' + split[1] + '.' + split[0];
    } else {
      return '';
    }
  }

  openNewTab(str: string) {
    window.open(str, '_blank', 'width: 1000');
  }

  change_fix_week() {
    this.check_fix_week();
    if (this.fix_week) {
      this.authService.deleteValue('fix_week');
    } else {
      this.authService.saveValue('fix_week', this.year.toString() + ':' + this.week_number.toString());
    }
    this.check_fix_week();
  }
}
