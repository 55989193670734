import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Project, Section} from '../../../interfaces/ProjectInterfaces';
import {DataService} from '../../../services/data/data.service';
import {DeleteService} from '../../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {formatDate} from '@angular/common';
import {MatMenuTrigger} from "@angular/material/menu";
import {CreateService} from "../../../services/create/create.service";
import {CommonsService} from "../../../services/commons/commons.service";

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() section: Section;
  @Input() project: Project;
  @Output() delete = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<boolean>();
  @Output() channel_id = new EventEmitter<number>();
  is_open = false;

  constructor(private dataService: DataService, private createService: CreateService,
              private deleteService: DeleteService, private modalService: NgbModal, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
  }

  formatDate(date) {
    if (date !== null) {
      return formatDate(date, 'dd.MM.yyyy', 'DE');
    } else {
      return 'nicht gesetzt';
    }
  }

  deleteSection(sharepoint_delete) {
    this.deleteService.deleteSection(this.section.id, sharepoint_delete).subscribe(data => {
      if (data.success) {
        this.delete.emit(true);
      }
    }, error => {
      this.deleteService.coms.showErrorToast(error, 'Bauabschnitt', 2);
      this.deleteService.errorHandler(error);

    });
  }

  deleteFloor(floor) {
    this.section.floor = this.section.floor.filter(obj => obj !== floor);
  }

  verified(sharepoint_delete) {
    this.deleteSection(sharepoint_delete);
  }

  open(content, event, reload = false) {
    this.trigger.closeMenu();
    event.preventDefault();
    this.modalService.open(content).result.then((result) => {
      this.update.emit(reload);
      this.loadFloor();
    });
  }

  async loadFloor() {
    if (!this.is_open) {
      this.section.floor = await this.dataService.getFloorBySection(this.section.id);
    }
  }

  reUploadSection() {
    this.createService.reUploadSection(this.section.id).subscribe((data) => {
      this.commonsService.showInfoToast('Die Dokumentationen werden in kürze neu Hochgeladen', 'Hinweis', 0);
    }, error => {
      this.createService.errorHandler(error).then();
      this.createService.commonsService.showErrorToast(error, 'Die Dokumentationen können nicht hochgeladen werden! [Mehr Infos]');
    });
  }

  calc_dispenser() {
    let a = 0;
    if (this.section.floor != null) {
      for (const floor of this.section.floor) {
        if (floor.dispenser != null) {
          a = a + floor.dispenser.length;
        }
      }
    }
    return a;
  }

  calc_floors() {
    return this.section.floor != null ? this.section.floor.length : 0;
  }

}
