import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @Input() text;
  @Input() type;
  @Input() value;
  @Input() placeholder;
  @Input() change_value: EventEmitter<any>;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private _ngZone: NgZone) {
  }

  ngOnInit(): void {
    if (this.change_value != null) {
      this.change_value.subscribe(data => {
        this.value = data;
      });
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }


}
