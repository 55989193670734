import {Component, ElementRef, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {WeekItem} from '../../interfaces/WeekInterface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteService} from '../../services/delete/delete.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {UpdateService} from '../../services/update/update.service';
import {LoginService} from '../../services/login/login.service';

registerLocaleData(localeDe);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('update') updateDiv: ElementRef;

  days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  search_words: Array<any> = [];
  week_items: Array<WeekItem>;
  not_running_week_items: Array<WeekItem> = [];
  week_number;
  year;
  edit_item: WeekItem = null;
  current_search_term = '';

  reopen_area_id = null;
  info_four_width = 1;

  // don't ask why it's here. (its necessary for click-out-of-update-window-reload)
  update_security_click_count = 1;

  currently_editing_project: EventEmitter<WeekItem> = new EventEmitter<WeekItem>();
  tmp_currently_editing_project;

  constructor(private dataService: DataService, private modalService: NgbModal,
              private route: ActivatedRoute, private router: Router,
              private deleteService: DeleteService,
              private authService: LoginService,
              private updateService: UpdateService,
              private renderer: Renderer2) {
    this.currently_editing_project.subscribe(data => {
      this.tmp_currently_editing_project = data;
    });
    /*
    this.renderer.listen('window', 'click', (e: Event) => {
      // close update window if clicked out of it
      if (this.updateDiv !== undefined && e.target !== this.updateDiv.nativeElement &&
        this.tmp_currently_editing_project !== null && this.edit_item !== null) {
        if (!this.update_security_click_count) {
          this.update_security_click_count = 1;
          this.reload(9);
        } else {
          this.update_security_click_count = 0;
        }
      }
    });*/
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(data => {

      if (data.week == null || data.year == null) {
        let tmp_year = new Date().getFullYear();
        let tmp_week = this.dataService.getWeekNumber(new Date());


        if (this.authService.getValue('fix_week') != null) {
          const tmp = this.authService.getValue('fix_week');
          tmp_year = Number(tmp.split(':')[0]);
          tmp_week = Number(tmp.split(':')[1]);
        }

        if (data.week !== tmp_week || data.year !== tmp_year) {
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {year: tmp_year, week: tmp_week},
              queryParamsHandling: 'merge'
            });
          return;
        }
      } else {
        this.year = data.year;
        this.week_number = data.week;

        if (!(0 < data.week && data.week < 60)) {
          this.week_number = 1;

          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {week: this.week_number, year: this.year},
              queryParamsHandling: 'merge'
            });

        } else if (this.week_number === 52) {
          this.week_number = 1;
          this.year = this.year + 1;

          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {week: this.week_number, year: this.year},
              queryParamsHandling: 'merge'
            });
        }

        this.reload(1);
      }
    });


    this.currently_editing_project.subscribe(data => {
      if (data === null) {
        this.edit_item = null;
      }

      if (this.edit_item === null) {
        this.edit_item = data;
        return;
      }

      if (this.edit_item.project.id === data.project.id) {
        this.currently_editing_project.emit(null);
        return;
      }
    });
  }


  async reopen_area_popup(event: number) {
    if (event === null) {
      this.reopen_area_id = null;
      return;
    }
  }

  async reload(test) {
    this.edit_item = null;
    this.week_items = [];
    this.not_running_week_items = [];

    if (this.week_number == null) {
      this.week_number = this.dataService.getWeekNumber(new Date());
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {week: this.week_number},
          queryParamsHandling: 'merge'
        });
    } else {
      const running_week = await this.dataService.getRunning(this.week_number, this.year);
      const not_running_week = await this.dataService.getNotRunning(this.week_number, this.year);
      if (running_week != null) {
        this.week_items = running_week;
        this.week_items.forEach(item => {
          item.visible = true;
        });
      }

      if (this.current_search_term.length > 0) {
        this.search(this.current_search_term);
      }
      if (not_running_week != null) {
        this.not_running_week_items = [];
      }

      for (const w of not_running_week) {
        const tmp = this.get_empty_week();


        tmp.week_plan_project_exist = false;
        for (const weekItem of this.week_items) {
          if (weekItem.project.id === w.project.id) {
            tmp.week_plan_project_exist = true;
            tmp.week_plan_project_exist_week_plan_id = weekItem.id;
          }
        }

        tmp.area = w;
        tmp.project = w.project;
        tmp.project_id = w.project.id;
        this.not_running_week_items.push(
          tmp
        );
        this.not_running_week_items.sort(this.compare);
      }
      this.loadSearchArray();

      if (this.current_search_term.length > 0) {
        this.search(this.current_search_term);
      }
    }
  }


  compare(a: WeekItem, b: WeekItem) {
    if (a.project.id < b.project.id) {
      return -1;
    }
    if (a.project.id > b.project.id) {
      return 1;
    }
    // a muss gleich b sein
    return 0;
  }

  get_empty_week() {
    return {
      area_comment: null,
      client_contact: [],
      project_id: null,
      id: null,
      week_plan_set: false,
      trash: null,
      responsible_user_name: null,
      week: this.week_number,
      year: this.year,
      week_plan_project_exist_week_plan_id: null,
      note: null,
      project: null,
      info_completed: false,
      shifts: null,
      material_stock: false,
      material_order: false,
      material_ordered: false,
      date_confirmed: false,
      date_requested: false,
      documentation_done: false,
      visible: true,
      accounting_comment: null,
      continue_comment: null,
      special_tools: null,
      agreements: null,
      todo: [],
      manufacture: null,
      client_contact_id: null,

      client: null,
      client_id: null,
      secondary_client: null,
      secondary_client_id: null,
      monday_status: null,
      thursday_status: null,
      wednesday_status: null,
      friday_status: null,
      saturday_status: null,
      sunday_status: null,
      tuesday_status: null,
      week_plan_project_exist: false,

      area: null,
      create_area: {
        project: null,
        project_id: null,

        floor_id: null,
        floor: null,

        section_id: null,
        section: null
      }
    };
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.reload(4);
    });
  }

  loadSearchArray() {
    this.week_items.forEach(item => {
      this.search_words.push({name: item.project.name, id: item.project.id});
    });
    this.not_running_week_items.forEach(item => {
      this.search_words.push({name: item.project.name, id: item.project.id});
    });
  }

  previousWeek() {
    this.week_number = parseInt(this.week_number);
    this.week_number--;
    if (this.week_number === 0) {
      this.week_number = 53;
      this.year--;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {week: this.week_number},
        queryParamsHandling: 'merge'
      });
  }

  nextWeek() {
    this.week_number = parseInt(this.week_number);
    if (this.week_number + 1 > 53) {
      this.week_number = 1;
      this.year = this.year + 1;
    } else {
      this.week_number++;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {week: this.week_number},
        queryParamsHandling: 'merge'
      });
  }


  search(text) {
    this.current_search_term = text;
    this.week_items = this.setVisibility(this.week_items, text);
    this.not_running_week_items = this.setVisibility(this.not_running_week_items, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        item.visible = (
          item.description !== undefined && item.description !== null &&
          item.description.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||


          item.note !== undefined && item.note !== null
          && item.note.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||

          item.project.name !== undefined && item.project.name !== null &&
          item.project.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||

          item.project.street !== undefined && item.project.street !== null &&
          item.project.street.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||

          item.project.zip_code !== undefined && item.project.zip_code !== null &&
          item.project.zip_code.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||


          item.project.client !== undefined && item.project.client.name !== undefined && item.project.client.name !== null &&
          item.project.client.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||

          item.responsible_user_name !== undefined && item.responsible_user_name !== null &&
          item.responsible_user_name.toLowerCase().search(compareStr.toLowerCase()) >= 0
        );
      });
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.week_items, event.previousIndex, event.currentIndex);


    if (event.currentIndex === event.previousIndex) {
      return;
    }
    const moving_weekplan = this.week_items[event.previousIndex].id;
    const target_weekplan = this.week_items[event.currentIndex].id;


    this.updateService
      .updateWeekPlanOrder(moving_weekplan, target_weekplan)
      .subscribe((data) => {
        if (data.success) {
          this.reload(6);
        } else {
          window.alert('Fehler!');
        }
      }, error => {
        window.alert('Fehler!');
      });
  }


}
