import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClientContact} from '../../../interfaces/ClientContact';
import {DataService} from '../../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteService} from '../../../services/delete/delete.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  @Input() j: ClientContact;
  @Output() request_reload = new EventEmitter<boolean>();

  constructor(private dataService: DataService, private modalService: NgbModal, private deleteService: DeleteService) {
  }

  ngOnInit(): void {
  }

  deleteContact(verified, contact_id) {
    if (verified) {
      this.deleteService.deleteClientContact(contact_id).subscribe(data => {
        this.request_reload.emit(true);
      }, error => {
        this.deleteService.errorHandler(error);
        this.deleteService.coms.showErrorToast(error, 'Ansprechpartner', 2);
      });

    }
  }

  open(content, event) {
    event.preventDefault();
    this.modalService.open(content).result.then((result) => {
      this.request_reload.emit(true);
    });
  }

}
