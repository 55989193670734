<div style="height: 100%!important; width: 100%!important;">
  <app-navigation></app-navigation>


  <div style="padding-top: 50px; padding-left: 65px; width: calc(100%- 65px);">
    <router-outlet></router-outlet>
  </div>


</div>
