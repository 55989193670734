<div *ngIf="!use_round_design">
  <input
    (focusin)="onFocus.emit(true)"
    (keydown.enter)="onEnter.emit(my_control.value)"
    [formControl]="my_control"
    [matAutocomplete]="searchObjects? autoObjects:auto"
    matInput
    placeholder=" {{placeholder}}"
    type="text">

</div>

<div *ngIf="use_round_design" style="position: relative">
  <label class="pure-material-textfield-outlined">
    <input #autoInputElement
           (focusin)="onFocus.emit(true)"
           (keydown.enter)="onEnter.emit(my_control.value)"
           [formControl]="my_control"
           [matAutocomplete]="searchObjects? autoObjects:auto"
           matInput
           placeholder=" {{placeholder}}"
    >
    <span>{{text}}</span>
  </label>
  <div (click)="onCreate.emit(true)" *ngIf="createMode" style="position: absolute; top: 1.5em; right: 1em">
    <mat-icon>add_box</mat-icon>
  </div>
</div>


<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of filtered_options | async"
              [value]="option">
    {{option}}
  </mat-option>

</mat-autocomplete>


<mat-autocomplete #autoObjects="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let option of filtered_options | async"
              [value]="option">
    {{objectKey != null ?
    objectDisplayKeyList != null ?
      build_option(option) :
      option[objectKey] :
    option}}
  </mat-option>
</mat-autocomplete>
