import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data/data.service';
import {DaemonInterface} from '../../../interfaces/DaemonInterface';
import {UpdateService} from "../../../services/update/update.service";

@Component({
  selector: 'app-daemon',
  templateUrl: './daemon.component.html',
  styleUrls: ['./daemon.component.scss']
})
export class DaemonComponent implements OnInit {

  data: Array<DaemonInterface> = [];

  constructor(private dataService: DataService, private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.get_data()

  }

  async get_data() {
    const data = await this.dataService.getDaemonErrors();
    const tmp = [];
    for (const item of data) {
      if (item.project_id != null) {
        let project = await this.dataService.getProjectsByID(item.project_id);
        item.project = project;
        item.project.visible = true;

      }
      tmp.push(item);
    }

    this.data = tmp;
  }

  retry_task(id) {
    this.updateService.retry_daemon(id).subscribe((data) => {
      window.alert('Auftrag wird in kürze ausgeführt (' + data.success.toString() + ')');
    });
  }

}
