import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Floor, Project} from '../../../interfaces/ProjectInterfaces';
import {DataService} from '../../../services/data/data.service';
import {Constants} from '../../../Constants';
import {LoginService} from '../../../services/login/login.service';
import {DeleteService} from '../../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonsService} from '../../../services/commons/commons.service';
import {formatDate} from '@angular/common';
import {CreateService} from '../../../services/create/create.service';
import {MatMenuTrigger} from "@angular/material/menu";


@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss']
})
export class FloorComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  is_open = false;
  @Input() floor: Floor;
  @Input() project: Project;
  @Output() channel_id = new EventEmitter<number>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<boolean>();

  // tslint:disable-next-line:max-line-length
  constructor(private dataService: DataService, private loginService: LoginService,
              private deleteService: DeleteService, private modalService: NgbModal,
              private commonsService: CommonsService, private createService: CreateService) {
  }

  ngOnInit(): void {
    this.getDispenser();
  }


  formatDate(date) {
    if (date !== null && date !== undefined) {
      return formatDate(date, 'dd.MM.yyyy', 'DE');
    } else {
      return 'nicht gesetzt';
    }
  }

  reUpload() {
    this.createService.reUploadFloor(this.floor.id).subscribe((data) => {
      if (data.success) {
        this.commonsService.showInfoToast('Die Dokumentationen werden in kürze neu Hochgeladen', 'Hinweis', 0);
      }
    }, error => {
      this.createService.errorHandler(error);
      this.commonsService.showInfoToast('Die Dokumentationen können nicht hochgeladen werden!', 'Achtung', 1)
    });
  }

  deleteFloor(delete_sharepoint) {
    this.deleteService.deleteFloor(this.floor.id, delete_sharepoint).subscribe(data => {
      if (data.success) {
        this.delete.emit(true);
      }
    }, error => {
      this.commonsService.showErrorToast(error, 'Etage', 2);
      this.deleteService.errorHandler(error);

    });
  }


  async getDispenser(force = false) {
    if (!this.is_open || force) {
      this.floor.dispenser = await this.dataService.getDispenserByFloor(this.floor.id);
    }


    this.is_open = !this.is_open;
  }

  verified(delete_sharepoint) {
    this.deleteFloor(delete_sharepoint);
  }

  open(content, event, reload = false) {
    this.trigger.closeMenu();
    if (event != null) {
      event.preventDefault();
    }
    this.modalService.open(content).result.then(() => {
      this.getDispenser(true);
    });
  }

  openAllDispenserDocumentation() {
    const token = this.loginService.getSessionToken();
    const url = Constants.PYTHON_SERVER_URL + 'documentation/getByQuery?floor_id='
      + this.floor.id.toString() + '&documentation_type=0&session=' + token;
    window.open(url, '_blank');
  }

  dispenserPicNum(i) {
    return Math.floor(((i / 2) % 2));
  }


  openAllPressureDocumentation() {
    const token = this.loginService.getSessionToken();
    // tslint:disable-next-line:max-line-length
    const url = Constants.PYTHON_SERVER_URL + 'documentation/getByQuery?floor_id='
      + this.floor.id.toString() + '&documentation_type=1&session=' + token;
    window.open(url, '_blank');
  }
}
