<div class="login100-form ">
					<span>
            <h1>Mitglieder Login</h1>
					</span>


  <div *ngIf="index==1">
    <div class="wrap-input100 validate-input">

      <app-input [(value)]="pre_name" text="Vorname" type="text"></app-input>

    </div>

    <div class="wrap-input100 validate-input">

      <app-input [(value)]="name" text="Nachname" type="text"></app-input>

    </div>

  </div>

  <div *ngIf="index==2">
    <div class="wrap-input100 validate-input">

      <app-input [(value)]="email" text="Email" type="email"></app-input>

    </div>

    <div class="wrap-input100 validate-input">

      <app-input [(value)]="phone_number" text="Handynummer" type="tel"></app-input>

    </div>

  </div>

  <div *ngIf="index==3">
    <div class="wrap-input100 validate-input">

      <app-input [(value)]="password" text="Password" type="password"></app-input>

    </div>

    <div class="wrap-input100 validate-input">

      <app-input [(value)]="password_filler" text="Password wiederholen" type="password"></app-input>

    </div>

  </div>


  <div class="container-login100-form-btn" style="display: flex">
    <button (click)="back()" *ngIf="index > 1" class="login50-form-btn" style="background: red">
      Zurück
    </button>
    <button (click)="next()" class="{{index == 1? 'login100-form-btn': 'login50-form-btn'}}">
      {{ index == max_index ? 'Registrieren' : 'Weiter'  }} ({{index}} / {{max_index}})
    </button>
  </div>

</div>


<!--

<h1>Registrieren</h1>

<div style="width: 100%;">
  <label>Vorname</label>
  <input [(ngModel)]="pre_name" [ngModelOptions]="{standalone: true}" autocomplete="given-name" class="input-box"
         placeholder="Vorname" type="text">
</div>

<div style="width: 100%;">
  <label>Nachname</label>
  <input [(ngModel)]="name" [ngModelOptions]="{standalone: true}" autocomplete="family-name" class="input-box"
         placeholder="Nachname" type="text">
</div>

<div style="width: 100%;">
  <label>Email</label>
  <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" autocomplete="email" class="input-box"
         placeholder="E-Mail" type="text">
</div>
<div style="width: 100%;">
  <label>Telefonnummer</label>
  <input [(ngModel)]="phone_number" [ngModelOptions]="{standalone: true}" autocomplete="tel" class="input-box"
         placeholder="Telefonnummer" type="text">
</div>
<div style="width: 100%;">
  <label>Passwort</label>
  <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" autocomplete="new-password" class="input-box"
         placeholder="Passwort" type="password">
</div>
<div style="width: 100%;">
  <label>Passwort wiederholen</label>
  <input [(ngModel)]="password_filler" [ngModelOptions]="{standalone: true}" autocomplete="new-password"
         class="input-box"
         placeholder="Passwort wiederholen" type="password">
</div>

<div (click)="checkInputs()" class="btn-lg btn-block btn-login btn-register">
  <span>Registrieren</span>
</div>

<div (click)="back_to_login();" class="btn-lg btn-block btn-login btn-back">
  <span>zurück</span>
</div>

-->
