<div>

  <div class="modal-header">
    <h4 class="modal-title">Kunde hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="margin-top: 1em">


      <app-input (valueChange)="name = $event" [request_focus]="true" text="Kundenname"
      ></app-input>

    </div>

    <div style="display: flex;">
      <app-input (valueChange)="street = $event" style="width: 60%" text="Straße + Nr"></app-input>

      <div style="width: 1em;"></div>

      <app-input (valueChange)="zip_code = $event" text="PLZ + Ort"></app-input>


    </div>
    <div class="box hover-animation non-clickable-object">
      <div class="align-mid-vertical">Externe Dokumentation:</div>
      <app-switch (valueChange)="custom_documentation = $event"
                  [value]="custom_documentation == null? false:custom_documentation"></app-switch>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="createClient()" class="button button-blue">
      Kunde hinzufügen
    </div>
  </div>

</div>
