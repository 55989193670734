import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';

@Component({
  selector: 'app-section-create',
  templateUrl: './create-section.component.html',
  styleUrls: ['./create-section.component.scss']
})
export class CreateSectionComponent implements OnInit {

  @Input() modal;
  @Input() project_id;

  name: string;
  start_date;
  end_date;
  override_create_folder;
  already_requested = false;

  constructor(private createService: CreateService) {
  }

  ngOnInit(): void {
  }

  createSection() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createSection(this.project_id, this.name, this.start_date, this.end_date, this.override_create_folder).subscribe(data => {
        this.already_requested = false;
        this.createService.commonsService.showSuccessToast('Info', 'Der Bauabschnitt wurde erfolgreich erstellt')
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Bauabschnitt konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }

  switch_override_create_folder() {
    this.override_create_folder = this.override_create_folder == null ? false : !this.override_create_folder;
  }

  try_set_enddate() {
    if (this.end_date == null) {
      let tmp = this.start_date.toString().split("-");
      tmp.pop()
      this.end_date = tmp.join("-") + "-01"
    }
  }
}
