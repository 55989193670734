import {Injectable} from '@angular/core';
import {Dispenser, Floor, Manufacture, Project, ResponsibleUserName, Section} from '../../interfaces/ProjectInterfaces';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {GetNotRunningWeekAreas, TodoTemplate, WeekItem} from '../../interfaces/WeekInterface';
import {Constants} from '../../Constants';
import {Client} from '../../interfaces/ClientInterface';
import {formatDate} from '@angular/common';
import {Team} from '../../interfaces/TeamInterface';
import {User} from '../../interfaces/UserInterface';
import {ServerResponseArray, ServerResponseSingle} from '../../interfaces/DefaultResponse';
import {TrashTemplate} from '../../interfaces/TrashTemplateInterface';
import {ClientContact} from '../../interfaces/ClientContact';
import {Day} from '../../interfaces/DayInterface';
import {CommonsService} from '../commons/commons.service';
import {DaemonInterface} from '../../interfaces/DaemonInterface';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(private http: HttpClient, private loginService: LoginService, private commonsService: CommonsService) {
  }


  getCommands() {
    const re = new Subject<Array<string>>();

    const token = this.loginService.getSessionToken();

    this.http.post<ServerResponseArray<string>>(Constants.PYTHON_SERVER_URL + 'admin/get_commands', {
      session: token
    }).subscribe(
      (data) => {
        if (data.success) {
          re.next(data.data);
        } else {
          re.next(null);
        }
      }, error => this.commonsService.showErrorToast(error, '', -1)
    );

    return re.asObservable();
  }

  send_single_data_request<a>(sub_url, body, failed_value = null) {
    const re = new Subject<a>();

    this.http.post<ServerResponseSingle<a>>(Constants.PYTHON_SERVER_URL + sub_url, body).subscribe(
      (data) => {
        if (data.success) {
          re.next(data.data);
          re.complete();
        } else {
          re.next(failed_value);
          re.complete();
        }
      }, error => {
        re.next(failed_value);
        re.complete();
      }
    );
    return re.toPromise();
  }

  send_multiple_data_request<a>(sub_url, body, failed_value = null) {
    const re = new Subject<Array<a>>();

    this.http.post<ServerResponseArray<a>>(Constants.PYTHON_SERVER_URL + sub_url, body).subscribe(
      (data) => {
        if (data.success) {
          re.next(data.data);
          re.complete();
        } else {
          re.next(failed_value);
          re.complete();
        }
      }, error => {
        re.next(failed_value);
        re.complete();
      }
    );
    return re.toPromise();
  }

  async get_area_by_project_week(week_number: number, year_number: number, project_id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<GetNotRunningWeekAreas>('week_plan/get_area_by_project_week', {
      session: token,
      week: week_number,
      year: year_number,
      project_id
    });
  }

  async get_area_by_project(project_id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<GetNotRunningWeekAreas>('week_plan/get_area_by_project', {
      session: token,
      project_id
    });
  }

  getDaemonErrors() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<DaemonInterface>('daemon/getExceptions', {
      session: token,
    });
  }


  getTeamKeys() {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<{
      'permission_keys': Array<string>,
      'value_keys': Array<string>
    }>('team/get_keys', {
      session: token,
    });
  }


  getAllWeeksByProject(project_id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<WeekItem>('week_plan/getAllByProject', {
      session: token,
      project_id
    });
  }

  getAllResponsibleUserNames() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<ResponsibleUserName>('project/getAllResponsible', {
      session: token,
    });
  }


  getSharePointPath(project_name, project_street, project_zip_code, project_start_date) {
    return this.send_single_data_request<string>('sharepoint/buildPath', {
      project_name, project_street, project_zip_code, project_start_date
    });
  }


  getDayInfoOfWeek(date) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Day>('day/getWeek', {
      session: token,
      monday_date: date
    });
  }

  get_area(item) {
    if (item.floor != null) {
      return item.floor;
    }
    if (item.section != null) {
      return item.section;
    }
    if (item.project != null) {
      return item.project;
    }
    return null;
  }

  get_area_type(item) {
    if (item.floor != null) {
      return "floor";
    }
    if (item.section != null) {
      return "section";
    }
    if (item.project != null) {
      return "project";
    }
    return null;
  }

  get_area_id(item) {
    if (item.floor != null) {
      return item.floor.id;
    }
    if (item.section != null) {
      return item.section.id;
    }
    if (item.project != null) {
      return item.project.id;
    }
    return null;
  }

  getDocumentationHK() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<string>('documentation/getHK', {
      session: token
    });
  }

  getAllManufacture() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Manufacture>('project/getAllManufacture', {
      session: token
    });
  }

  getClientById(id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<Client>('client/getByID', {
      session: token,
      client_id: id
    });
  }

  getWeekString(id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<string>('week_plan/getFormatString', {
      session: token,
      week_plan_id: id
    });
  }


  getClientContactByClientId(id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<ClientContact>('client/contact/getByID', {
      session: token,
      id,
    });
  }


  getWeekNumber(d: Date): any {
    const date = d;

    const currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);

    const yearOfThursday = currentThursday.getFullYear();

    // tslint:disable-next-line:max-line-length
    const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000);

    return Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7);
  }

  getDateRangeOfWeek(weekNo, y) {

    let d1;
    d1 = new Date('' + y + '');
    // current day

    if (d1.getDay === 1) {
      // if today == monday. nothing happen
    } else if (d1.getDay() === 0) {
      // if day == sunday --> get back 1 week and 1 day forward (week dont start with sunday)
      d1.setDate(d1.getDate() + 1);
      weekNo = weekNo - 1;
    } else {
      // get back to monday
      d1.setDate(d1.getDate() + (8 - d1.getDay()));

    }
    const days = (weekNo - 1) * 7;

    d1.setDate(d1.getDate() + days);


    const tmp: Array<Date> = [];


    // add to list; d1 = monday
    for (let j = 0; j < 7; ++j) {
      tmp.push(new Date(d1.getTime()));
      d1.setDate(d1.getDate() + 1);

    }

    return tmp;

  }


  getRunning(week_number: number, year_number: number) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<WeekItem>('week_plan/getRunning', {
      session: token,
      week: week_number,
      year: year_number
    });
  }

  getNotRunning(week_number: number, year_number: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<any>('week_plan/getNotRunning', {
      session: token,
      week: week_number,
      year: year_number
    });
  }


  getLastWeekPlan(project_id: number) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<WeekItem>('week_plan/getLastByProject', {
      session: token,
      project_id
    });
  }

  getClients() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Client>('client/getAll', {
      session: token
    });
  }

  getAllProjects() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Project>('project/getAll', {
      session: token
    });
  }

  getProjectsByID(project_id) {
    const token = this.loginService.getSessionToken();
    return this.send_single_data_request<Project>('project/getByID', {
      session: token,
      project_id
    });
  }

  getProjectsByClient(client_id) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Project>('project/getByClient', {
      session: token,
      client_id
    });
  }


  getSectionByProject(project_id) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Section>('section/getByProject', {
      session: token,
      project_id
    });
  }

  getFloorByProject(project_id) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Floor>('floor/getByProject', {
      session: token,
      project_id
    });
  }

  getAvailable(date) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<User>('user/getAvailable', {
      session: token,
      date: formatDate(date, 'yyyy-MM-dd', 'DE')

    }, []);
  }

  getNotAvailable(date) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<User>('user/getNotAvailable', {
      session: token,
      date: formatDate(date, 'yyyy-MM-dd', 'DE')

    }, []);
  }

  getFloorBySection(section_id) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Floor>('floor/getBySection', {
      session: token,
      section_id
    });
  }

  getTeams() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Team>('team/getAll', {
      session: token
    });
  }

  getDispenserByFloor(floor_id) {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<Dispenser>('dispenser/getByFloor', {
      session: token,
      floor_id
    });
  }

  getAllUser() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<User>('user/getAll', {
      session: token
    });
  }

  getUser() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<User>('user/getSingle', {
      session: token
    });
  }


  getAllTodoTemplates() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<TodoTemplate>('week_plan/todo/template/getAll', {
      session: token
    });
  }

  getAllTrashTemplates() {
    const token = this.loginService.getSessionToken();
    return this.send_multiple_data_request<TrashTemplate>('week_plan/trash/template/getAll', {
      session: token
    });
  }
}
