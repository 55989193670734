import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {DataService} from "../../services/data/data.service";
import {Project} from "../../interfaces/ProjectInterfaces";

@Component({
  selector: 'app-create-dispenser-documentation',
  templateUrl: './create-dispenser-documentation.component.html',
  styleUrls: ['./create-dispenser-documentation.component.scss']
})
export class CreateDispenserDocumentationComponent implements OnInit {

  @Input() modal;
  @Input() dispenser_id;
  @Input() project: Project;
  list: Array<{ key: string, value: number }> = [{key: '', value: 0}];

  auto_complete = [];

  already_requested = false;

  constructor(private createService: CreateService, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.load_data();
  }

  async load_data() {
    this.auto_complete = await this.dataService.getDocumentationHK();
  }

  createDocumentation() {
    if (this.project.client != null && this.project.client.custom_documentation) {
      window.alert('Achtung! Dieser Auftraggeber benutzt eine eigene Dokumentation');
      return;
    }
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createDispenserDocumentation(this.dispenser_id, this.list).subscribe(data => {
        if (data.success) {
          this.modal.close();
        } else {
          this.already_requested = false;
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Verteilerdokumentation konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }


  addRow() {
    this.list.push({key: '', value: 0});
  }

  removeRow() {
    this.list.pop();
  }
}
