<div class="window-box">
  <div class="inline-box">
    <h4>Kunden</h4>

    <div class="inline-box">

      <div class="align-mid-vertical" style="display: flex">
        <span (click)="open(client_create, $event)" class="material-icons icon-big icon-grey"
              style="padding-right: 0.5em">add_box</span>
      </div>
      <label class="align-mid-vertical">
        <input (change)="search($event.target.value)" (input)="search($event.target.value)" [(ngModel)]="search_text"
               class="search-field"
               placeholder="suchen..."
               type="text">
      </label>
    </div>
  </div>
  <div *ngFor="let client of list">

    <details *ngIf="client.visible" class="summary-box blue hover-animation">
      <summary (click)="loadContact(client)" class="heading">
        <div (click)="open(showMore, $event)"
             *ngIf="client.name != null && client.name != ' '" class="align-mid-vertical">
          {{ client.name.length > 20 ? client.name.substr(0, 19) + ' [...] ' : client.name }}
        </div>
        <ng-template #showMore let-modal>
          <app-show-more [modal]="modal" [text]="'Name des Kunden: ' + client.name" [title]="'Name'"></app-show-more>
        </ng-template>

        <div>
          <span (click)="open(verify, $event)" class="material-icons">delete</span>
          <span (click)="open(modal, $event)" class="material-icons">create</span>
        </div>
      </summary>
      <div class="summary-content-box">

        <div class="small-information-box hover-animation">

          <b>Adresse:</b>
          <ul>
            <li>{{client.street}}</li>
            <li>{{client.zip_code}}</li>
          </ul>
          <br>
          <b>Dokumentationstyp:</b>
          <ul>
            <li>{{client.documentation_type}}</li>
          </ul>
          <br>

          <b>Ordnername:</b>
          <ul>
            <li>{{client.override_folder == null ? client.name : client.override_folder}}</li>
          </ul>
          <b>Kunden ID:</b>
          <ul>
            <li>{{client.id}}</li>
          </ul>
          <b>Externe Dokumentation:</b>
          <ul>
            <li>{{client.custom_documentation ? "Ja" : "Nein"}}</li>
          </ul>

          <div *ngIf="client.creator_name != null">
            <b>Kunden-Ersteller:</b>
            <ul>
              <li>{{client.creator_name}}</li>
            </ul>
          </div>
        </div>

        <div class="inline-box"><h3>Ansprechpartner:</h3>
          <span (click)="open(contact_create, $event)" class="material-icons">add_box</span>

          <ng-template #contact_create let-modal>
            <app-client-contact-create [client]="client" [modal]="modal"></app-client-contact-create>
          </ng-template>
        </div>

        <div *ngFor="let j of client.contacts">
          <app-contact (request_reload)="getData()" [j]="j"></app-contact>
        </div>


        <div class="inline-box"><h3>Projekte:</h3></div>

        <div *ngFor="let project of client.projects">
          <app-project-item (refresh)="this.getData()" [darken]="true" [project]="project"></app-project-item>
        </div>

        <ng-template #verify let-modal>
          <app-verify (verified)="verified($event, client.id)" [description]="('Kunden ' + client.name + ' löschen?')"
                      [modal]="modal"></app-verify>
        </ng-template>

        <ng-template #modal let-modal>
          <app-client-update [client]="client" [modal]="modal"></app-client-update>
        </ng-template>
      </div>
    </details>
  </div>
</div>


<!--<div (click)="open(client_create, $event)" class="client-create-button"> +</div>-->


<ng-template #client_create let-modal>
  <app-client-create [modal]="modal"></app-client-create>
</ng-template>
