import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Todo, WeekItem} from '../../../../interfaces/WeekInterface';
import {Project} from '../../../../interfaces/ProjectInterfaces';
import {DeleteService} from '../../../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-three',
  templateUrl: './info-three.component.html',
  styleUrls: ['./info-three.component.scss']
})
export class InfoThreeComponent implements OnInit {

  @Input() weekItem: WeekItem;
  @Input() pro: Project;
  @Input() week_number: number;
  @Input() year: number;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();

  index = 0;
  area_type;
  area_id;

  constructor(private deleteService: DeleteService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (!this.weekItem.week_plan_set) {
      this.area_type = this.weekItem.create_area.floor == null ? this.area_type : 'floor';
      this.area_type = this.weekItem.create_area.section == null ? this.area_type : 'section';
      this.area_type = this.weekItem.create_area.project == null ? this.area_type : 'project';


      this.area_id = this.weekItem.create_area.floor == null ? this.area_id : this.weekItem.create_area.floor.id;
      this.area_id = this.weekItem.create_area.section == null ? this.area_id : this.weekItem.create_area.section.id;
      this.area_id = this.weekItem.create_area.project == null ? this.area_id : this.weekItem.create_area.project.id;
    }
  }

  open(content, reload) {
    this.modalService.open(content).result.then((result) => {
      if (reload) {
        this.request_reload.emit(true);
      }
    });
  }


  onRightClick(e, menuTrigger) {
    menuTrigger.openMenu();
    e.preventDefault();
  }

  sort_template_array(tmp: Array<Todo>) {
    this.index = 0;
    if (tmp !== undefined) {
      return (tmp.sort((a, b) => {
        if (a.template.order < b.template.order) {
          return -1;
        } else if (a.template.order > b.template.order) {
          return 1;
        } else {
          return 0;
        }
      }));
    }
  }

  deleteWeekPlanTodo(todo) {
    this.deleteService.deleteWeekPlanTodo(todo.id).subscribe(data => {
      if (data.success) {
        const index = this.weekItem.todo.indexOf(todo, 0);
        this.weekItem.todo.splice(index, 1);
      }
    }, error => {
      this.deleteService.coms.showErrorToast(error, 'ToDo', 2);
      this.deleteService.errorHandler(error);
    });
  }
}
