import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login/login.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';


export interface LoginResponse {
  'success': boolean;
  'session': string;
  'error': string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  login = true;
  register = false;
  reset_password = false;

  special_character = ['+', '-', '&', '|', '!', '(', ')', '{', '}', '[', ']', '^',
    '~', '*', '?', ':', '"'];

  constructor(private loginService: LoginService, private http: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
    if (this.loginService.is_logged_in) {
      this.router.navigate(['/']);
    }

  }


  openRegister() {
    this.login = false;
    this.reset_password = false;
    this.register = true;
  }


  openLogin(registerSuccess) {
    this.reset_password = false;
    this.register = false;
    this.login = true;
  }

  openReset() {
    this.login = false;
    this.register = false;
    this.reset_password = true;
  }


  /*checkPassword() {
    let hasSC = false;
    this.special_character.forEach(sc => {
      if (!(this.password.search(sc) === -1)) {
        hasSC = true;
      }
    });
    let hasNum = false;
    for (let i = 0; i < 10; ++i) {
      if (!(this.password.search(i.toString()) === -1)) {
        hasNum = true;
      }
    }
    return hasSC && hasNum && this.password.length > 8;
  }*/
}
