<div class="top-nav">

  <div class="title" routerLink="/" style="padding-left: 175px">
    <img alt="Montagespezis" class="brand" src="../../../assets/pictures/Logo.png" style="z-index: 999"/>
  </div>

  <div class="nav-wrapper">
    <div class="user">
      <div>
        <span class="material-icons">account_box</span>
      </div>
      <div class="menu">
        <ul class="menu-list">
          <li class="menu-list-item">
            <div class="menu-list-item-btn" routerLink="/settings">
              <span class="material-icons">settings</span>
            </div>
          </li>
          <li (click)="logout()" class="menu-list-item logout">
            <div class="menu-list-item-btn logout">
              Logout
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
