<div>

  <div class="modal-header">
    <h4 class="modal-title">Verteilerdokumentation hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="margin-top: 1em">


      <div *ngFor="let row of list" style="display: flex; margin-top: .2em">
        <app-autocomplete (onChange)="row['key'] = $event" [data]="auto_complete" [request_focus]="true"
                          [use_round_design]="true"
                          text="Heizkreis"></app-autocomplete>
        <app-input (onEnter)="addRow()" (valueChange)="row['value'] = $event" style="margin-left: 1em"
                   text="Länge in m"></app-input>
      </div>

      <div style="display: flex; justify-content: space-between">
        <div (click)="addRow()" style="cursor:pointer;">
          <u>Reihe hinzufügen</u>
        </div>


        <div (click)="removeRow()" style="cursor:pointer;">
          <u>letzte Reihe löschen</u>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <div (click)="createDocumentation()" class="button button-blue">
      Dokumentation erstellen
    </div>
  </div>

</div>
