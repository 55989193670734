import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UpdateService} from '../../../services/update/update.service';
import {WeekItem} from '../../../interfaces/WeekInterface';
import {DeleteService} from '../../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../../services/data/data.service';
import {CreateService} from '../../../services/create/create.service';
import {ClientContact} from '../../../interfaces/ClientContact';
import {Manufacture, ResponsibleUserName} from '../../../interfaces/ProjectInterfaces';
import {Trash} from '../../../interfaces/TrashTemplateInterface';
import {TimeService} from "../../../services/time/time.service";

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  @Input() weekItem: WeekItem;
  @Input() currently_editing_project: EventEmitter<WeekItem>;

  @Output() request_reload: EventEmitter<any> = new EventEmitter();

  trash_templates = [];
  todo_templates = [];
  reload: boolean;
  all_client_contacts: Array<ClientContact> = [];
  active_client_contacts: Array<number> = [];
  active_client_trash: Array<number> = [];


  all_user_names: Array<ResponsibleUserName> = [];
  all_manufacture: Array<Manufacture> = [];

  trash_div_disabled = false;

  constructor(private updateService: UpdateService, private deleteService: DeleteService,
              private modalService: NgbModal, private dataService: DataService,
              private createService: CreateService, private timeService: TimeService) {
  }

  ngOnInit(): void {
    this.reloadTrashTemplates(true);
    this.reloadTodoTemplates(true);

    /* Disabled Scroll Right
    window.scrollTo({
      top: 0,
      left: 10000,
      behavior: 'smooth'
    });
    */

    for (const i of this.weekItem.client_contact) {
      this.active_client_contacts.push(parseInt(i.id.toString()));
    }

    for (const i of this.trashToTemplate()) {
      this.active_client_trash.push(parseInt(i.id.toString()));
    }
    this.reloadAll();

  }

  async reloadAll() {

    const client_contact_tmp = await this.dataService.getClientContactByClientId(this.weekItem.project.client_id);
    const responsible_user_tmp = await this.dataService.getAllResponsibleUserNames();
    const manufacture_tmp = await this.dataService.getAllManufacture();
    const trash_tmp = await this.dataService.getAllTrashTemplates();

    this.all_client_contacts = [];
    for (const i of client_contact_tmp) {
      if (this.active_client_contacts.indexOf(i.id) === -1) {
        this.all_client_contacts.push(i);
      }
    }

    this.all_user_names = [];
    for (const i of responsible_user_tmp) {
      if (i.responsible_user_name != null) {
        this.all_user_names.push(i);
      }
    }


    this.all_manufacture = [];
    for (const i of manufacture_tmp) {
      if (i.manufacture != null) {
        this.all_manufacture.push(i);
      }
    }


    this.trash_templates = [];
    for (const i of trash_tmp) {
      if (this.active_client_trash.indexOf(i.id) === -1) {
        this.trash_templates.push(i);
      }
    }
    this.trash_div_disabled = false;
  }


  reloadTrashTemplates(bool) {
    this.reloadAll();
  }

  async reloadTodoTemplates(bool) {
    if (bool) {
      this.todo_templates = await this.dataService.getAllTodoTemplates();
    }
  }


  close() {
    this.request_reload.emit(true);
  }

  async changeClientContact(contact: ClientContact, check: boolean) {
    if (check) {
      this.weekItem.client_contact.push(contact);
      this.createService.createClientContactWeek(this.weekItem.id, contact.id).subscribe((data) => {
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Ansprechpartner konnte nicht zugewiesen werden. [Mehr Infos]');
      });
      this.active_client_contacts.push(contact.id);
      this.reloadAll();
    } else {
      this.deleteService
        .deleteClientContactWeek(this.weekItem.id, contact.id)
        .subscribe((data) => {
          },
          error => {
            this.deleteService.coms.showErrorToast(error, 'Wochenansprechpartner', 2);
            this.deleteService.errorHandler(error);

          });
      this.active_client_contacts.splice(this.active_client_contacts.indexOf(contact.id), 1);
      this.weekItem.client_contact.splice(this.weekItem.client_contact.indexOf(contact), 1);

      this.reloadAll();
    }
  }


  trashToTemplate() {
    const tmp = [];
    for (const i of this.weekItem.trash) {
      tmp.push(i.template);
    }
    return tmp;
  }

  changeTrashTemplate(contact, check: boolean) {
    if (check) {
      this.createService.createWeekPlanTrash(this.weekItem.id, contact.id).subscribe((data) => {
        const t: Trash = {
          id: data.data,
          template: contact,
          trash_template_id: contact.id,
          week_plan_id: this.weekItem.id
        };

        this.weekItem.trash.push(t);
        this.active_client_trash.push(contact.id);
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Aufgabe konnte nicht erstellt werden. [Mehr Infos]');
        this.reloadAll();
      });
    } else {
      this.active_client_trash.splice(this.active_client_trash.indexOf(contact.id), 1);


      let index = 0;
      for (const element of this.trashToTemplate()) {
        if (contact.id === element.id) {

          this.deleteService.deleteWeekPlanTrash(this.weekItem.trash[index].id).subscribe((data) => {
            },
            error => {
              this.deleteService.errorHandler(error);
              this.deleteService.coms.showErrorToast(error, '', 2)
            });

          this.weekItem.trash.splice(index, 1);
        }
        index++;
      }
    }
  }


  valueInput(key, value) {
    if (key === 'square_meters') {
      try {
        // tslint:disable-next-line:radix
        const a = parseInt(value);
      } catch (e) {
        window.alert('Bitte gib eine valide Zahl ein!');
      }
    }
    this.timeService.timed_update(key, value, this.updateService,
      this.updateService.updateWeekPlan, [this.weekItem.id, key, value]);
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      // modal closed
    });
  }


  allUserNamesToList(list: Array<ResponsibleUserName>) {
    const tmp = [];
    for (const a of list) {
      tmp.push(a.responsible_user_name);
    }
    return tmp;
  }

  allManufactureNamesToList(list: Array<Manufacture>) {
    const tmp = [];
    for (const a of list) {
      tmp.push(a.manufacture);
    }
    return tmp;
  }
}
