import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CreateService} from "../../../services/create/create.service";
import {CommonsService} from "../../../services/commons/commons.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  name = '';
  pre_name = '';
  email = '';
  phone_number = '';
  password = '';
  password_filler = '';
  @Output() open_login: EventEmitter<boolean> = new EventEmitter();
  register_try = false;
  password_valid;
  success;
  index = 1;
  max_index = 3;

  constructor(private createService: CreateService, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
  }


  checkInputs() {
    this.register_try = true;
    if (this.name === '') {
      this.success = false;
      this.showToast('Name fehlt', 'Fehler', 2);

    }

    if (this.pre_name === '') {
      this.success = false;
      this.showToast('Vorname fehlt', 'Fehler', 2);

    }
    if (this.email === '') {
      this.success = false;
      this.showToast('E-Mail fehlt', 'Fehler', 2);

    }
    if (this.phone_number === '') {
      this.success = false;
      this.showToast('Telefonnummer fehlt', 'Fehler', 2);

    }

    if (this.password === '') {
      this.success = false;
      this.showToast('Passwort fehlt', 'Fehler', 2);

    }
    // TODO Passwort auf Sonderzeichen prüfen
    if (this.password !== this.password_filler || this.password === '') {
      this.showToast('Passwörter stimmen nicht überein', 'Fehler', 2);
    } else if (this.password === this.password_filler) {
      this.register();
    }
  }

  register() {
    this.createService.createUser(this.name, this.pre_name, this.email, this.phone_number, this.password).subscribe(
      (data) => {
        if (data.success) {
          this.success = true;
          this.open_login.emit(true);
        } else {
          this.success = false;
        }
      }, error => {
        this.createService.commonsService.showErrorToast(error, 'Der Nutzer konnte nicht erstellt werden. [Mehr Infos]');
        this.success = false;
      }
    );

  }

  showToast(text: string, title: string, type: number) {
    this.commonsService.showInfoToast(text, title, type);
  }

  back_to_login() {
    this.open_login.emit(true);
  }

  next() {
    if (this.index === this.max_index) {
      this.register();
    } else {
      this.index++;
    }
  }

  back() {
    this.index--;
  }
}
