import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Constants} from '../../Constants';
import {ServerResponse} from '../../interfaces/DefaultResponse';
import jwt_decode from 'jwt-decode';
import {CommonsService} from "../commons/commons.service";


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  is_logged_in = false;
  is_admin_user = false;
  is_admin_user_checked = false;
  is_admin_running = false;

  isDebugMode = false;
  usrname: string;

  logged_in_state: EventEmitter<boolean> = new EventEmitter<boolean>();
  debugMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  username: EventEmitter<string> = new EventEmitter<string>();

  constructor(private http: HttpClient, private router: Router, private commonsService: CommonsService) {
    this.logged_in_state.subscribe(data => {
      this.is_logged_in = data;
    });
    this.debugMode.subscribe(data => {
      this.isDebugMode = data;
    });
    this.username.subscribe(data => {
      this.usrname = data;
    });
  }


  is_maintenance_user() {
    if (this.is_admin_user_checked) {
      return this.is_admin_user;
    } else {
      this.check_is_admin_user();
      return false;
    }
  }

  async check_is_admin_user() {
    if (!this.is_admin_running) {
      this.is_admin_running = true;
      const token = this.getValue('session_token');
      this.http.post<boolean>(Constants.PYTHON_SERVER_URL + 'user/is_admin', {
        session: token
      }).subscribe(
        (data) => {
          this.is_admin_user = data;
          this.is_admin_user_checked = true;
          this.is_admin_running = false;
        }, error => {
          this.is_admin_user = false;
          this.is_admin_user_checked = true;
          this.is_admin_running = false;
        }
      );
    }

  }

  verifyToken() {
    const re = new Subject<boolean>();
    // TODO checkSession system
    this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'auth/checkSession', {
      session: this.getValue('session_token')
    }).subscribe(
      (data) => {
        re.next(data.success);
        re.complete();
      }, (error1 => {
        re.next(false);
        re.complete();
      }));
    return re.toPromise();
  }

  server_logout() {
    this.logged_in_state.emit(false);
    let body = {
      session: this.getValue('session_token')
    };
    this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'auth/logout', body).subscribe(
      (data) => {
        if (!data.success) {
          this.showToast(data.error, 'Fehler: Logout fehlgeschlagen', 0);
        } else {
          this.logout();
          this.deleteValue('session_token');
        }
      }, error => {
        this.logout();
      }
    );

    this.router.navigate(['/login']);
  }

  getUsername(): any {
    return jwt_decode(this.getValue('session_token'));
  }

  getSessionToken() {
    const token = this.getValue('session_token');
    const ttoken = jwt_decode(token);


    if (Date.now() >= ttoken['exp'] * 1000) {
      // token already invalid an no need to call server_logout()

      this.logout();

      return null;
    }
    return token;
  }

  getValue(key) {
    return window.localStorage.getItem(key);
  }

  saveValue(key, value) {
    return window.localStorage.setItem(key, value);
  }

  deleteValue(key) {
    window.localStorage.removeItem(key);
  }

  // password reset requests
  sendResetMail(mail) {
    this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'auth/sendResetMail', {
      mail
    }).subscribe(
      (data) => {
        if (!data.success) {
          if (this.isDebugMode) {
            console.log('ERROR(login.service -> sendResetMail): ' + data.error);
          }
          this.showToast(data.error, 'Fehler: Mail nicht versand', 1);
        } else {
          this.showToast('', 'Mail wurde versand', 0);
        }
      }
    );
  }

  resetPassword(token, password, password_repeat) {
    this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'auth/reset_password', {
      token,
      password,
      password_repeat
    }).subscribe(
      (data) => {
        if (!data.success) {
          if (this.isDebugMode) {
            console.log('ERROR(login.service -> reset_password): ' + data.error);
          }
          this.showToast(data.error, 'Fehler: Passwort nicht zurückgesetzt', 1);
        } else {
          this.showToast('', 'Passwort erfolgreich zurückgesetzt', 0);
        }
      }
    );
  }

  showToast(text: string, title: string, type: number) {
    this.commonsService.showInfoToast(text, title, type);
  }

  private logout() {
    this.logged_in_state.emit(false);
    this.router.navigate(['/login']);
    this.deleteValue('session_token');
  }
}
