import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../../../services/update/update.service';
import {TrashTemplate} from '../../../../interfaces/TrashTemplateInterface';

@Component({
  selector: 'app-single-trash-template',
  templateUrl: './single-trash-template.component.html',
  styleUrls: ['./single-trash-template.component.scss']
})
export class SingleTrashTemplateComponent implements OnInit {

  @Input() item: TrashTemplate;

  old: Map<string, any>;
  tmp_keys = {};

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.old = new Map<string, any>();

    Object.keys(this.item).forEach(key => {
      this.old.set(key, this.item[key]);
    });
  }


  update_key(key, value) {
    this.tmp_keys[key] = value;
    const wordSearch = value;
    setTimeout(() => {
      if (wordSearch === this.tmp_keys[key]) {
        this.updateService.updateTrashTemplate(this.item.id, key, value).subscribe(data => {
            if (!data.success) {
              window.alert('Error: TrashTemplateUpdate');
            }
            this.old.set(key, value);
          },
          error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Vorlage konnte nicht bearbeitet werden [...]', -1);
          }
        );
      }
    }, 2000);
  }
}
