import {Component, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {LoginService} from '../../services/login/login.service';
import {DataService} from "../../services/data/data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private createServie: CreateService, private loginService: LoginService,
              private dataService: DataService, private modalService: NgbModal) {
  }

  ngOnInit(): void {

  }


}
