import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './routes/home/home.component';
import {LoginComponent} from './routes/login/login.component';
import {AuthGuard} from './security/auth.guard';
import {DashBoardComponent} from './routes/dash-board/dash-board.component';
import {ClientComponent} from './routes/client/client.component';

import {ProjectComponent} from './routes/project/project.component';
import {TeamComponent} from './routes/team/team.component';
import {UserComponent} from './routes/user/user.component';
import {AvailableUserTabComponent} from './routes/home/available-user-tab/available-user-tab.component';
import {SettingsComponent} from './routes/settings/settings.component';
import {AdminComponent} from './routes/admin/admin.component';
import {TemplatesComponent} from './routes/templates/templates.component';


const routes: Routes = [
  {path: '', component: DashBoardComponent, canActivate: [AuthGuard]},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'client', component: ClientComponent},
  {path: 'available-user', component: AvailableUserTabComponent},

  {path: 'project', component: ProjectComponent, canActivate: [AuthGuard]},

  {path: 'team', component: TeamComponent, canActivate: [AuthGuard]},
  {path: 'user', component: UserComponent, canActivate: [AuthGuard]},

  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path: 'template', component: TemplatesComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},

  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
