<details class="summary-box dark-I hover-animation align-mid-vertical">
  <summary class="summary-heading">
    <div class="align-mid-vertical">
      {{j.first_name}} {{j.last_name}}
    </div>


    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="open(showClientContact, $event)" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Ansprechpartner bearbeiten</span>
      </button>

      <button (click)="open(verifyContactDelete, $event)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Ansprechpartner löschen</span>
      </button>
    </mat-menu>


    <ng-template #showClientContact let-modal>
      <app-show-client-contact [client_contact]="j"
                               [edit_mode]="true"
                               [modal]="modal"></app-show-client-contact>
    </ng-template>
    <ng-template #verifyContactDelete let-modal>
      <app-verify (verified)="deleteContact( $event, j.id)"
                  [description]="('Ansprechpartner ' + j.last_name + ' löschen?')"
                  [modal]="modal"></app-verify>
    </ng-template>
  </summary>

  <div class="summary-content-box">
    <div class="small-information-box hover-animation">
      <p style="font-weight: bold">Nachname:</p>
      <ul>
        <li>{{j.last_name}}</li>
      </ul>

      <p style="font-weight: bold">Vorname:</p>
      <ul>
        <li>{{j.first_name}}</li>
      </ul>

      <div *ngIf="j.phone_number !== ''">
        <p style="font-weight: bold">Mobil:</p>
        <ul>
          <li>{{j.phone_number}}</li>
        </ul>
      </div>

      <div *ngIf="j.mail !== ''">
        <p style="font-weight: bold">Mail</p>
        <ul>
          <li>{{j.mail}}</li>
        </ul>
      </div>
    </div>
  </div>

</details>
