<div class="week-plan-update">

  <div class="modal-header">
    <h4 *ngIf="importData != null" class="modal-title">Wochenplan erstellen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <div *ngIf="importData == null">


      <div>
        <app-import-weekplan (loadLastWeek)="loadLastWeek($event)" (select_area)="select_area($event)"
                             [modal]="modal" [week_item]="week_plan"
                             [override_selected_area_id]="override_selected_area_id"
                             [override_selected_area_type]="override_selected_area_type"
                             [override_selected_area_list]="override_selected_area_list"></app-import-weekplan>
      </div>


    </div>


    <div *ngIf="importData != null">


      <div *ngIf="!finishedLoading">
        <h6 style="font-size: 125%">Die Daten der letzten KW werden geladen!</h6>
      </div>

      <div *ngIf="finishedLoading">

        <h5>Projektstatus:</h5>
        <div style="margin-left: .5em">

          <div class="checkbox-frame">
            <h6>Material bestellen</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('material_order', $event.target.checked)" [checked]="material_order"
                     id="confirm0"
                     type="checkbox">
              <label class="confirm" for="confirm0"></label>
            </div>
          </div>

          <div class="checkbox-frame">
            <h6>Material bestellt</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('material_ordered', $event.target.checked)" [checked]="material_ordered"
                     id="confirm1"
                     type="checkbox">
              <label class="confirm" for="confirm1"></label>
            </div>
          </div>

          <div class="checkbox-frame">
            <h6>Material aus Lager</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('material_stock', $event.target.checked)" [checked]="material_stock"
                     id="confirm2"
                     type="checkbox">
              <label class="confirm" for="confirm2"></label>
            </div>
          </div>

          <div class="checkbox-frame">
            <h6>Termin angefragt</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('date_requested', $event.target.checked)" [checked]="date_requested"
                     id="confirm3"
                     type="checkbox">
              <label class="confirm" for="confirm3"></label>
            </div>
          </div>

          <div class="checkbox-frame">
            <h6>Termin bestätigt</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('date_confirmed', $event.target.checked)" [checked]="date_confirmed"
                     id="confirm4"
                     type="checkbox">
              <label class="confirm" for="confirm4"></label>
            </div>
          </div>

          <div class="checkbox-frame">
            <h6>Infos vollständig</h6>
            <div class="checkbox-container">
              <input (input)="valueInput('info_completed', $event.target.checked)" [checked]="info_completed"
                     id="confirm5"
                     type="checkbox">
              <label class="confirm" for="confirm5"></label>
            </div>
          </div>
        </div>


        <h5>Projektbeschreibung:</h5>
        <div>
          <div class="content-div">
            <h6>Ansprechpartner:</h6>
            <div class="template-container">
              <div *ngFor="let contact of selected_client_contacts; let j = index">
                <div
                  (click)="selectClientContact(false, contact)"
                  class="template-box template-active hover-animation"
                  style="cursor: pointer;">

                  {{contact.first_name}} {{contact.last_name}}
                </div>
              </div>

              <div *ngFor="let contact of all_client_contacts; let j = index">
                <div
                  (click)="selectClientContact(true, contact)"
                  class="template-box template-inactive hover-animation"
                  style="cursor: pointer;">

                  {{contact.first_name}} {{contact.last_name}}
                </div>
              </div>


              <div (click)="open(newClientContact)" class="template-box template-inactive hover-animation"
                   style="cursor: pointer">+
              </div>
            </div>
          </div>

          <div class="content-div">
            <h6>Projektleiter:</h6>
            <div class="input-autocomplete-width">
              <app-autocomplete (onChange)="valueInput('responsible_user_name', $event)"
                                [data]="allUserNamesToList(this.all_user_names)" [finish_loading]="finishedLoading"
                                [init_value]="lastWeek.responsible_user_name">

              </app-autocomplete>
            </div>
          </div>

          <div class="content-div">
            <h6>Fabrikat:</h6>
            <div class="input-autocomplete-width">

              <div *ngIf="!updateManufacture" class="inline-box">
                <ul>
                  <li>{{lastWeek.manufacture}}</li>
                </ul>
                <mat-icon (click)="updateManufacture = !updateManufacture">edit</mat-icon>
              </div>

              <app-autocomplete (onChange)="valueInput('manufacture', $event)"
                                *ngIf="updateManufacture"
                                [data]="allManufactureNamesToList(this.all_manufacture)"
                                [finish_loading]="finishedLoading"
                                [init_value]="lastWeek.manufacture">

              </app-autocomplete>
            </div>
          </div>

          <div class="content-div">
            <h6 style="margin-top: 1em; font-weight: bold">ToDo:</h6>
            <label class="align-mid-vertical" style="margin-bottom: 0.5em;">
              <input (change)="search($event.target.value)" (input)="search($event.target.value)"
                     [(ngModel)]="todo_search_text"
                     class="search-field"
                     placeholder="suchen..."
                     style="padding: 0.5em !important"
                     type="text"
              >
            </label>
            <div class="template-container">
              <div class=" div-scrollable" style="height: 20vh">
                <div *ngFor="let todo of todos; let j = index">

                  <div *ngIf="todo.visible" class="template-box template-active">
                    <div class="template-text">
                      {{ todo.name > 200 ? todo.name.substr(0, 10) + ' [...] ' : todo.name }}
                    </div>
                    <div class="template-text">
                      {{ todo.shortcut }}
                    </div>
                    <div>
                      <span (click)="open(showTodoTemplateText)"
                            class="material-icons icon-white">
                        remove_red_eye
                      </span>
                      <span (click)="deleteWeekPlanTodo(todo)"
                            class="material-icons icon-white">
                        delete
                      </span>
                    </div>
                  </div>

                  <ng-template #showTodoTemplateText let-modal>
                    <app-show-more [modal]="modal"
                                   [text]="'Todo: ' + todo.name + '<br> Inhalt: ' + todo.text + '<br> Anzahl in ' + todo.unit + ': ' + todo.value"
                                   [title]="'Mehr anzeigen'"></app-show-more>
                  </ng-template>
                </div>
                <div *ngFor="let template of todoTemplates; let j = index">

                  <div *ngIf="!isTodoInWeek(template) && template.visible">
                    <div (click)="open(todoValueInput)"
                         class="template-box template-inactive hover-animation {{template.greyed ?'highlight':'' }}">


                      <div class="template-text">
                        {{ template.name > 200 ? template.name.substr(0, 10) + ' [...] ' : template.name }}
                      </div>
                      <div class="template-text">
                        {{ template.shortcut }}
                      </div>
                      <div>
                          <span (click)="open(showTodoTemplateText); $event.stopPropagation()"
                                class="material-icons icon-blue">
                            remove_red_eye
                          </span>

                        <ng-template #todoValueInput let-modal>
                          <app-todo-value-input
                            (value)="addWeekPlanTodo($event, template,$event)" [modal]="modal"
                            [template]="template"></app-todo-value-input>
                        </ng-template>
                        <ng-template #showTodoTemplateText let-modal>
                          <app-show-more [modal]="modal"
                                         [text]="'Todo: ' + template.text + '<br> Inhalt: ' + template.text"
                                         [title]="'Mehr anzeigen'"></app-show-more>
                        </ng-template>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
              <div style="display: flex; width: 100%; justify-content: space-between">

                <div (click)="open(newTodoTemplate)"
                     class="smallBox align-mid-vertical hover-animation"
                     style="justify-content: space-between; display: flex; cursor: pointer; width: 50%; margin-right: 0.5em">
                  <div style="margin: auto;">Template hinzufügen</div>
                  <div class="material-icons">add</div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="project.description != null && project.description != ' '" class="content-div">
            <h6 style="margin-top: 1em">Beschreibung</h6>
            <div>
              {{ project.description }}
            </div>
          </div>


          <div class="content-div">
            <h6 style="margin-top: 1em">Absprachen</h6>
            <div style="width: 100%">
              <textarea (input)="valueInput('agreements', $event.target.value)"
                        [value]="lastWeek.agreements"
                        placeholder="Absprachen"
              ></textarea>
            </div>
          </div>

          <div class="content-div">
            <h6 style="margin-top: 1em">Spezialwerkzeug</h6>
            <div style="width: 100%">
            <textarea (input)="valueInput('special_tools', $event.target.value)"
                      [value]="lastWeek.special_tools"
                      placeholder="Spezialwerkzeug z.B. Roth-Tacker">
            </textarea>
            </div>
          </div>


          <div class="content-div">
            <h6 style="margin-top: 1em">Materialbedarf:</h6>
            <div style="width: 100%">
          <textarea (input)="note= $event.target.value" [value]="note"
                    placeholder="Material z.B. Tackernadeln aus Lager" style="width: 100%; white-space: pre-wrap;">
          </textarea>
            </div>
          </div>

          <div class="content-div">
            <h6 style="margin-top: 1em; font-weight: bold">Müllbeseitigung:</h6>
            <app-template-selector
              (add_action)="clickTrash(true, $event)"
              (create_action)="open(newTrashTemplate)"
              (remove_action)="clickTrash(false,$event)"
              [available]="trashTemplates"
              [selected]="trash"
              search_word="name"
            >

            </app-template-selector>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div (click)="createWeekPlan()" class="button button-blue">
          Wochenplan erstellen
        </div>
      </div>

    </div>

  </div>


  <ng-template #newTrashTemplate let-modal>
    <app-trash-template-create (done)="reloadTrashTemplates($event)" [modal]="modal"></app-trash-template-create>
  </ng-template>
  <ng-template #newTodoTemplate let-modal>
    <app-todo-template-create (done)="reloadTodoTemplates($event)" [modal]="modal"></app-todo-template-create>
  </ng-template>
  <ng-template #newClientContact let-modal>
    <app-client-contact-create (done)="reloadData()" [client_id]="project.client_id"
                               [modal]="modal"></app-client-contact-create>
  </ng-template>
</div>
