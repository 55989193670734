import {Injectable} from '@angular/core';
import {Constants} from '../../Constants';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {ServerResponse} from '../../interfaces/DefaultResponse';
import {Channel, Message} from '../../interfaces/ChatInterfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient, private loginService: LoginService) {
  }

  getMessage(channel_id, smaller_then) {
    const token = this.loginService.getSessionToken();

    return this.http.post<{ 'success': boolean; 'data': Array<Message> }>(Constants.PYTHON_SERVER_URL + 'chat/getMessages', {
      session: token,
      channel_id,
      smaller_then
    })
  }

  getChannel(channel_id) {

    const token = this.loginService.getSessionToken();

    return this.http.post<{ 'success': boolean, 'data': Channel }>(Constants.PYTHON_SERVER_URL + 'chat/getChannel', {
      session: token,
      channel_id
    });
  }

  sendMessage(channel_id, message) {
    const token = this.loginService.getSessionToken();


    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'chat/sendMessage', {
      session: token,
      channel_id,
      message
    })
  }

  async errorhandler(error) {
    if (error.status === 401) {
      if (!await this.loginService.verifyToken()) {
        this.loginService.server_logout();
      } else {
        // TODO send User Message with no Permission
      }
    }
  }
}
