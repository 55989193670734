<div
  class="inline-box"
  style="grid-area: head; min-width: 13em; border-right: solid grey 1px; height: 100%; padding: 0.25em; flex-direction: column">
  <div>
    <div>
      <p (click)="open(showMoreProjectName, false)"
         *ngIf="pro.name != null && pro.name != ' '"
         style="width: 100%;">
        {{ pro.name.length > 20 ? pro.name.substr(0, 20) + ' [...] ' : pro.name }}
      </p>
    </div>

    {{pro.street}}<br>
    {{pro.zip_code}}

    <div style="width: 100%;margin-top: 10px"></div>

  </div>


  <div *ngIf="show_more_data" style="border-top: 1px solid grey; margin-top: 1em; padding-top: 1em">
    <app-week-description (request_reload)="request_reload.emit(true)" [weekItem]="weekItem"></app-week-description>
  </div>


  <div style="display: flex; justify-content: space-between;">
    <span (click)="switchMore()" *ngIf="!show_more_data" class="material-icons" style="width: 80%; text-align: center;">
        expand_more
    </span>
    <span (click)="switchMore()" *ngIf="show_more_data" class="material-icons" style="width: 80%; text-align: center;">
        expand_less
    </span>

    <div style="cursor: pointer; display: flex">

      <span (click)="openProjectTab()" class="material-icons icon-grey" title="Zur Projekt Ansicht">
        description
      </span>
      <p style="width: 10px"></p>

      <span (click)="openSharepointFolder()" class="material-icons icon-grey" title="Zum Sharepoint Ordner">
        folder
      </span>
      <p style="width: 10px"></p>
      <span (click)="loadPlan(fileManager)" class="material-icons icon-grey" title="Pläne Laden">
        cloud_download
      </span>
    </div>


    <ng-template #fileManager let-modal>
      <app-file-manager [modal]="modal" [project]="pro"></app-file-manager>
    </ng-template>
  </div>


</div>


<ng-template #showMoreProjectName let-modal>
  <app-show-more [modal]="modal" [text]="pro.name" [title]="'Mehr anzeigen'"></app-show-more>
</ng-template>

<ng-template #showMoreDescription let-modal>
  <app-show-more [modal]="modal" [text]="pro.description" [title]="pro.name"></app-show-more>
</ng-template>
