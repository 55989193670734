import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {GetNotRunningWeekAreas, WeekItem} from '../../interfaces/WeekInterface';
import {UpdateService} from '../../services/update/update.service';
import {DataService} from '../../services/data/data.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs";

@Component({
  selector: 'app-update-week-plan-area',
  templateUrl: './update-week-plan-area.component.html',
  styleUrls: ['./update-week-plan-area.component.scss']
})
export class UpdateWeekPlanAreaComponent implements OnInit {

  @Input() modal;
  @Input() weekPlan: WeekItem;
  @Input() weekList: [WeekItem];
  @Input() reopenArea = false;
  @Input() reopen_area_popup: EventEmitter<number> = new EventEmitter<number>();

  week_list = [];

  query_area_list: Array<any> = [];

  queued_actions: Array<any> = [];

  override_area_list = [];
  override_area_type;
  override_area_id;

  tmp_week_item: WeekItem;

  not_running_areas: GetNotRunningWeekAreas = {
    project_id: null,
    project: null,
    sub_areas: [],
  };


  constructor(private updateService: UpdateService, private dataService: DataService, private modalService: NgbModal,) {
  }

  ngOnInit(): void {
    this.tmp_week_item = this.get_empty_week();
    this.load_data().then();
  }


  async load_data() {
    this.week_list = this.weekList.filter(week => week.project_id === this.weekPlan.project_id);
    this.not_running_areas = await this.dataService
      .get_area_by_project_week(this.weekPlan.week, this.weekPlan.year, this.weekPlan.project_id);
    this.query_area_list = [];
    this.query_area_list.push([-1, this.not_running_areas]);

    for (const weekListElement of this.week_list) {
      this.query_area_list.push([weekListElement.id, weekListElement.area]);
    }
  }

  async addWeekPlanArea(type, id, event) {
    let re = new Subject<boolean>();
    this.updateService.addWeekPlanArea(this.weekPlan.id, type, id).subscribe((data) => {
      this.updateService.commonsService.showSuccessToast('Info', 'Der Wochenplan wurde erfolgreich bearbeitet');
      re.next(true);
      re.complete();
    }, error => {
      this.updateService.errorHandler(error).then();
      this.updateService.commonsService.showErrorToast(error, 'Der Wochenplan konnte nicht bearbeitet werden');
      re.next(false);
      re.complete();
    });
    return re.toPromise();
  }

  async moveWeekPlanArea(primary_week_plan_id, secondary_week_plan_id, area_type, area_id) {
    let re = new Subject<boolean>();


    this.updateService.removeWeekPlanArea(primary_week_plan_id, area_type, area_id).subscribe((data) => {

      this.updateService.addWeekPlanArea(secondary_week_plan_id, area_type, area_id).subscribe((data2) => {
        this.updateService.commonsService.showSuccessToast('Info', 'Der Wochenplan wurde erfolgreich bearbeitet');
        re.next(true);
        re.complete();

      }, error => {
        re.next(false);
        re.complete();
        this.updateService.errorHandler(error).then();
        this.addWeekPlanArea(primary_week_plan_id, area_type, area_id);
        this.updateService.commonsService.showErrorToast(error, 'Der Wochenplan konnte nicht bearbeitet werden');
      });
    }, error => {
      re.next(false);
      re.complete();
      this.updateService.errorHandler(error).then();
      this.updateService.commonsService.showErrorToast(error, 'Der Wochenplan konnte nicht bearbeitet werden');
    });
    return re.toPromise();
  }

  async removeWeekPlanArea(type, area_id, event, week_plan_id) {

    let re = new Subject<boolean>();
    this.updateService.removeWeekPlanArea(week_plan_id, type, area_id).subscribe((data) => {
      this.updateService.commonsService.showSuccessToast('Info', 'Der Wochenplan wurde erfolgreich bearbeitet');
      re.next(true);
      re.complete();
    }, error => {
      re.next(false);
      re.complete();
      this.updateService.errorHandler(error).then();
      this.updateService.commonsService.showErrorToast(error, 'Der Wochenplan konnte nicht bearbeitet werden');
    });
    return re.toPromise();
  }

  drop(event: CdkDragDrop<any>) {
    const item = event.item.data[1];
    const type = event.item.data[2];

    if (event.container.data[0] !== event.previousContainer.data[0]) {
      if (event.previousContainer.data[0] === -1) {
        // Area was not running
        const id = this.get_id_of_area(item);


        this.queued_actions.push([this.addWeekPlanArea, [type, id, event]]);
      } else if (event.container.data[0] === -1) {
        // Area was running but gets removed

        const week_plan_id = event.previousContainer.data[0];
        const id = this.get_id_of_area(item);
        this.queued_actions.push([this.removeWeekPlanArea, [type, id, event, week_plan_id]]);
      } else {
        // Area gets moved between WeekPlans

        const id = this.get_id_of_area(item);
        this.queued_actions.push([this.moveWeekPlanArea, [event.previousContainer.data[0], event.container.data[0], type, id]]);
      }

      this.move_item(event);
    }
  }

  move_item(event: CdkDragDrop<any>) {
    const item = event.item.data[1];
    const type = event.item.data[2];
    const id = this.get_id_of_area(item);

    let first = event.previousContainer.data[1];
    let second = event.container.data[1];


    for (let i = 0; i < first.sub_areas.length; i++) {
      if (this.get_id_of_area(first.sub_areas[i]) === id) {
        // move to second
        second.sub_areas.push(first.sub_areas[i]);
        first.sub_areas.splice(i, 1);
        break;
      } else {
        for (let j = 0; j < first.sub_areas[i].sub_areas.length; j++) {
          // searching on current i on 2nd level
          if (this.get_id_of_area(first.sub_areas[i].sub_areas[j]) === id) {
            // found it on 2nd llevel

            // move to second
            let moved = false;
            // check if area already exist in second
            for (let k = 0; k < second.sub_areas.length; k++) {
              if (this.get_id_of_area(second.sub_areas[k]) === this.get_id_of_area(first.sub_areas[i])) {
                // if exist move to second
                second.sub_areas[k].sub_areas.push(first.sub_areas[i].sub_areas[j]);
                first.sub_areas[i].sub_areas.splice(j, 1);
                moved = true;
              }
            }
            // if not exist create new
            if (!moved) {
              const tmp_item = Object.assign({}, first.sub_areas[i]);
              tmp_item.sub_areas = [item];
              second.sub_areas.push(tmp_item);
            }
          }
        }
      }
    }
  }


  get_id_of_area(area) {
    if (area.dispenser != null) {
      return area.dispenser.id;
    }
    if (area.floor != null) {
      return area.floor.id;
    }
    if (area.section != null) {
      return area.section.id;
    }
    if (area.project != null) {
      return area.project.id;
    }
  }

  get_area_by_id(week_plan_id) {
    for (const weekListElement of this.week_list) {
      if (weekListElement.id === week_plan_id) {
        return weekListElement.area;
      }
    }
    return null;
  }

  get_area_count_of_three(three) {
    if (three == null) {
      return 0;
    }

    let count = 0;
    if (three.has_date) {
      count++;
    }
    if (three.sub_areas != null && three.sub_areas.length > 0) {
      for (const subArea of three.sub_areas) {
        count += this.get_area_count_of_three(subArea);
      }
    }
    return count;
  }

  get_empty_week() {
    return {
      area_comment: '',
      client_contact: [],
      project_id: this.weekPlan.project_id,
      id: null,
      week_plan_set: false,
      trash: null,
      responsible_user_name: '',
      week: this.weekPlan.week,
      year: this.weekPlan.year,
      week_plan_project_exist_week_plan_id: null,
      note: '',
      project: this.weekPlan.project,
      info_completed: false,
      shifts: null,
      material_stock: false,
      material_order: false,
      material_ordered: false,
      date_confirmed: false,
      date_requested: false,
      documentation_done: false,
      visible: true,
      accounting_comment: '',
      continue_comment: '',
      special_tools: '',
      agreements: '',
      todo: [],
      manufacture: '',
      client_contact_id: null,

      client: null,
      client_id: null,
      secondary_client: null,
      secondary_client_id: null,
      monday_status: null,
      thursday_status: null,
      wednesday_status: null,
      friday_status: null,
      saturday_status: null,
      sunday_status: null,
      tuesday_status: null,
      week_plan_project_exist: false,

      area: null,
      create_area: {
        project: null,
        project_id: null,

        floor_id: null,
        floor: null,

        section_id: null,
        section: null
      }
    };
  }

  async close() {
    for (const queuedAction of this.queued_actions) {
      await queuedAction[0].apply(this, queuedAction[1]);
    }
    this.modal.close(false);
  }

  dropCreate(event: CdkDragDrop<any>, content) {

    this.tmp_week_item = this.get_empty_week();
    this.override_area_type = event.item.data[2];
    if (this.override_area_type === 'floor') {
      this.override_area_id = event.item.data[1].floor.id;
    } else if (this.override_area_type === 'section') {
      this.override_area_id = event.item.data[1].section.id;
    } else {
      window.alert('Dieser Bereich kann nicht hinzugefügt werden');
      return;
    }


    this.modalService.open(content).result.then((result) => {
      const previous_week_plan = event.previousContainer.data;
      if (result && previous_week_plan != null && previous_week_plan !== -1) {
        this.removeWeekPlanArea(this.override_area_type, this.override_area_id, event, event.previousContainer.data);
      }
    });
  }
}
