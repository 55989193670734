import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {DataService} from '../../../services/data/data.service';
import {WeekItem} from '../../../interfaces/WeekInterface';
import {Project} from '../../../interfaces/ProjectInterfaces';
import {CreateService} from '../../../services/create/create.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {DeleteService} from '../../../services/delete/delete.service';
import {CommonsService} from '../../../services/commons/commons.service';
import {LoginService} from '../../../services/login/login.service';
import {UpdateService} from '../../../services/update/update.service';


@Component({
  selector: 'app-project-week',
  templateUrl: './project-week.component.html',
  styleUrls: ['./project-week.component.scss']
})


export class ProjectWeekComponent implements OnInit, AfterViewInit {
  @ViewChild('editSection')
  private editSection: TemplateRef<any>;

  @Input() weekItem: WeekItem;
  @Input() weekList: [WeekItem];
  @Input() week_number: number;
  @Input() year: number;
  @Input() info_four_width: number;
  @Input() index: number;
  @Input() currently_editing_project: EventEmitter<WeekItem>;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();
  @Output() reopen_area_popup: EventEmitter<number> = new EventEmitter<number>();


  @Input() reopenAreaPopup: boolean;
  area_popup_open = false;

  start_date;
  end_date;
  current_edit_mode = true;
  currently_edition_weekItem: WeekItem = null;
  ok_pressed = false;
  pro: Project;

  stringify_week;
  extend = false;


  constructor(public dataService: DataService, private createService: CreateService, private modalService: NgbModal,
              private deleteService: DeleteService, private commonsService: CommonsService,
              private loginService: LoginService, private updateService: UpdateService,
              private config: NgbModalConfig) {
  }

  ngOnInit(): void {

    this.currently_editing_project.subscribe(data => {
      this.currently_edition_weekItem = data;
      if (data === null) {
        return;
      }
      this.current_edit_mode = data.project.id === this.pro.id;
    });
    this.pro = this.weekItem.project;

    let date_area;

    date_area = this.weekItem.area;


    if (this.weekItem.create_area != null && date_area == null) {
      date_area = this.weekItem.create_area;
    }

    if (date_area.project != null) {
      this.start_date = date_area.project.start_date;
      this.end_date = date_area.project.end_date;
    } else if (date_area.section != null) {
      this.start_date = date_area.section.start_date;
      this.end_date = date_area.section.end_date;
    } else if (date_area.floor != null) {
      this.start_date = date_area.floor.start_date;
      this.end_date = date_area.floor.end_date;
    }
  }

  ngAfterViewInit(){
    if (this.reopenAreaPopup && !this.area_popup_open){
      this.reopen_area_popup.emit(null);

      if (document.getElementsByClassName('modal').length === 0){
        this.openArea(this.editSection);
      }

    }
  }


  switchEditMode() {
    if (this.currently_edition_weekItem != null && this.currently_edition_weekItem.project.id === this.pro.id) {
      this.currently_editing_project.emit(null);
    } else {
      this.currently_editing_project.emit(null);
      this.currently_editing_project.emit(this.weekItem);
    }
  }

  async openStringify(content) {
    this.stringify_week = await this.dataService.getWeekString(this.weekItem.id);
    this.open(content);
  }

  verified(bool) {
    if (bool) {
      this.deleteService.deleteWeekPlan(this.weekItem.id)
        .subscribe((data) => {
          this.request_reload.emit(true);
        }, error => {
          this.deleteService.errorHandler(error);

          this.commonsService.showErrorToast(error, 'Wochenplan', 2);
        });
    }
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
    });
  }

  openArea(content) {
    this.modalService.open(content, {size: 'xl', backdrop: 'static', keyboard: false}).result.then((result) => {
      if (result == null || result == false) {
        this.request_reload.emit(true);
      }
    });
  }

  moveWeek(week) {
    this.updateService.updateWeekPlaWeek(this.weekItem.id, week).subscribe((data) => {
        if (data.success) {
          this.request_reload.emit(true);
          this.updateService.commonsService.showSuccessToast("Info", 'Wochenplan wurde erfolgreich verschoben');
        }
      },
      error => {
        this.updateService.errorHandler(error);
        this.updateService.commonsService.showErrorToast(error, 'Wochenplan konnte nicht bearbeitet werden [...]', -1);
      }
    );
  }

}
