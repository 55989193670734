import {Component, OnInit} from '@angular/core';
import {LoginService} from "../../../services/login/login.service";

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  constructor(private loginService: LoginService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.server_logout();
  }
}
