import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Constants} from '../../Constants';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {DefaultCreateInterface} from '../../interfaces/DefaultCreateInterface';
import {ServerResponse} from '../../interfaces/DefaultResponse';
import {CommonsService} from '../commons/commons.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  updateMode = 0;

  constructor(private http: HttpClient, private loginService: LoginService, public commonsService: CommonsService) {
  }

  async errorHandler(error) {
    if (error.status === 401) {
      if (!(await this.loginService.verifyToken())) {
        this.loginService.server_logout();
      }
    }
  }

  updateTeam(team_id, update_key, update_value) {
    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'team/update', {
      session: token,
      team_id,
      key: update_key,
      value: update_value
    });
  }

  retry_daemon(failure_id) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'daemon/retry_task', {
      session: token,
      failure_id,
    });
  }

  addWeekPlanArea(week_plan_id, area_type, area_id) {

    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/addWeekPlanArea', {
      session: token,
      week_plan_id,
      area_type,
      area_id
    });
  }


  removeWeekPlanArea(week_plan_id, area_type, area_id) {

    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/removeWeekPlanArea', {
      session: token,
      week_plan_id,
      area_type,
      area_id
    });
  }

  updateProject(update_id, update_value, update_key) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'project/update', {
      session: token,
      project_id: update_id,
      key: update_key,
      value: update_value
    });
  }

  updateWeekPlan(week_plan_id, key, value) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/update', {
      session: token,
      week_plan_id,
      key,
      value,
    });
  }


  updateWeekPlanOrder(moving_week_plan_id, target_week_plan_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/updateOrder', {
      session: token,
      moving_week_plan_id,
      target_week_plan_id,
    });
  }

  updateToDoOrder(moving_todo_id, target_todo_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/updateOrder', {
      session: token,
      moving_todo_id,
      target_todo_id
    });
  }

  updateWeekPlaWeek(week_plan_id, week_number) {

    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/updateWeek', {
      session: token,
      week_plan_id,
      week_number,
    });
  }

  updateSection(id, key, value) {

    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'section/update', {
      session: token,
      section_id: id,
      key,
      value,
    });
  }

  updateFloor(id, key, value) {
    const token = this.loginService.getSessionToken();

    return this.http.post<DefaultCreateInterface>(Constants.PYTHON_SERVER_URL + 'floor/update', {
      session: token,
      floor_id: id,
      key,
      value,

    });

  }

  updateUser(user, key, value) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'user/update', {
      session: token,
      name: user,
      key,
      value
    });
  }

  updateDay(date, info) {
    const re = new Subject<DefaultCreateInterface>();

    const token = this.loginService.getSessionToken();
    this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'day/update', {
      session: token,
      date,
      info

    }).subscribe(
      (data: ServerResponse) => {
        re.next(data);
      },
      (error: HttpErrorResponse) => {
        re.error(null);
      }
    );
    return re;
  }

  updateClient(id, key, update_value) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/update', {
      session: token,
      client_id: id,
      key,
      value: update_value
    });
  }

  updateClientContact(id, key, update_value) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/contact/update', {
      session: token,
      client_contact_id: id,
      key,
      value: update_value
    });
  }

  updateWeekPlanTodo(value, week_plan_todo_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/update', {
      session: token,
      value,
      week_plan_todo_id

    });
  }

  updateTodoTemplate(todo_template_id, key, value) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/template/update', {
      session: token,
      todo_template_id,
      key,
      value

    });
  }

  updateTrashTemplate(trash_template_id, key, value) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/trash/template/update', {
      session: token,
      trash_template_id,
      key,
      value

    });
  }


  updateShiftStatus(shift_id, status) {

    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'shift/status', {
      session: token,
      shift_id,
      status

    });
  }

}
