<details class="summary-box blue hover-animation">

  <summary><h2>SharePoint Synchronisierung</h2></summary>
  <div class="summary-content-box">
    <div class="small-information-box">

      <div *ngFor="let item of data">
        <details class="summary-box blue">
          <summary>

            <h3>{{item.title}}</h3>
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="retry_task(item.id)" mat-menu-item>
                <mat-icon>refresh</mat-icon>
                <span>Erneut versuchen</span>
              </button>
            </mat-menu>

          </summary>
          <div class="summary-content-box">
            <div class="small-information-box">
              {{item.content}}

              <p><b>Projekt ID: </b>{{item.project_id}}</p>


              <div *ngIf="item.project != null && item.project != undefined">
                <app-project-item [project]="item.project"></app-project-item>
              </div>


              <p><b>TaskType: </b>{{item.task_type}}</p>
              <p><b>Dateiname (falls vorhanden): </b>{{item.task_file_name}}</p>
              <p><b>Nutzername: </b>{{item.user_name}}</p>
            </div>
          </div>
        </details>
      </div>

      <div *ngIf="data.length == 0">
        <p>Keine Probleme Probleme</p>
      </div>

    </div>
  </div>
</details>
