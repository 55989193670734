import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Project} from '../../../interfaces/ProjectInterfaces';
import {DataService} from '../../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteService} from '../../../services/delete/delete.service';
import {WeekItem} from '../../../interfaces/WeekInterface';
import {formatDate} from '@angular/common';
import {Constants} from '../../../Constants';
import {LoginService} from '../../../services/login/login.service';
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss']
})
export class ProjectItemComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() darken = false;
  @Input() project: Project;
  project_weeks: Array<WeekItem> = [];
  @Output() selected_channel: EventEmitter<number> = new EventEmitter<number>();
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>();
  tmp_choose_week;
  tmp_choose_year;
  is_open = false;

  constructor(private dataService: DataService, private modalService: NgbModal, private deleteService: DeleteService, private loginService: LoginService) {
  }

  ngOnInit(): void {
  }


  async requestProjectData(force = false) {
    if (!this.is_open || force) {

      this.project.section = await this.dataService.getSectionByProject(this.project.id);


      this.project.client = await this.dataService.getClientById(this.project.client_id);

      if (this.project.secondary_client_id != null) {
        this.project.secondary_client = await this.dataService.getClientById(this.project.secondary_client_id);
      }
      this.project.floor = await this.dataService.getFloorByProject(this.project.id);

      this.project_weeks = await this.dataService.getAllWeeksByProject(this.project.id)
    }

    this.is_open = !this.is_open;
  }

  async loadFloor(force = false) {
    if (!this.is_open || force) {
      this.project.floor = await this.dataService.getFloorByProject(this.project.id);
    }
  }

  async loadSection(force = false) {
    if (!this.is_open || force) {
      this.project.section = await this.dataService.getSectionByProject(this.project.id);
    }
  }

  formatDate(date) {
    if (date !== null) {
      return formatDate(date, 'dd.MM.yyyy', 'DE');
    } else {
      return 'nicht gesetzt';
    }
  }

  noteTooLong(note) {
    if (note.length < 200) {
      return [note, false];
    } else {
      return [note.slice(0, 200) + '...', true];
    }
  }

  open(content, event, reload = false) {
    this.trigger.closeMenu();

    if (event != null) {
      event.preventDefault();
    }
    this.modalService.open(content).result.then(() => {
      this.requestProjectData(reload);
    });
  }

  deleteProject(project_id) {
    this.deleteService.deleteProject(project_id).subscribe(data => {
      if (data.success) {
        this.delete.emit(true);
      }
    }, error => {
      this.deleteService.coms.showErrorToast(error, 'Projekt', 2);
      this.deleteService.errorHandler(error);

    });
  }

  verified(bool, project_id) {
    if (bool) {
      this.deleteProject(project_id);
    }
  }

  deleteSection(section) {
    this.project.section = this.project.section.filter(obj => obj !== section);
  }

  deleteFloor(floor) {
    this.project.floor = this.project.floor.filter(obj => obj !== floor);
  }

  openSharepointFolder() {
    const token = this.loginService.getSessionToken();

    const url = 'sharepoint/getPath?client_id=' + this.project.client_id.toString()
      + '&project_id=' + this.project.id.toString() + '&session=' + token;


    window.open(Constants.PYTHON_SERVER_URL + url);
  }
}
