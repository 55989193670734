<div class="hover-balls" style="display: flex; font-size: 125%">

  <span cdkDragHandle class="number-circle">
         {{index != null ? index + 1 : "-"}}
  </span>

  <div (click)="weekItem.material_order = update('material_order', !weekItem.material_order)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.material_order ? 'background-color: red; border-color: red;' : ''}}">
    <span class="{{weekItem.material_order ? 'white' : 'black'}}" style="font-size: 24px" title="Material bestellen"><mat-icon
      svgIcon="cart_undone"></mat-icon></span>
  </div>

  <div (click)="weekItem.material_ordered = update('material_ordered', !weekItem.material_ordered)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.material_ordered ? 'background-color: yellow; border-color: yellow;' : ''}}">
    <span class="black" style="font-size: 24px" title="Material bestellt"><mat-icon
      svgIcon="cart_done"></mat-icon></span>
  </div>

  <div (click)="weekItem.material_stock = update('material_stock', !weekItem.material_stock)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.material_stock ? 'background-color: black;' : ''}}">
    <span class="material-icons {{weekItem.material_stock ? 'white' : 'black'}}" title="Material aus Lager">store</span>
  </div>

  <div (click)="weekItem.date_requested = update('date_requested', !weekItem.date_requested)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.date_requested ? 'background-color: grey; border-color: grey;' : ''}}">
    <!-- <span title="Termin angefragt" class="material-icons {{weekItem.date_requested ? 'white' : 'black'}}">schedule</span>-->
    <span class="{{weekItem.date_requested ? 'white' : 'black'}}" title="Termin angefragt"><mat-icon
      svgIcon="calendar_undone"></mat-icon></span>
  </div>

  <div (click)="weekItem.date_confirmed = update('date_confirmed', !weekItem.date_confirmed)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.date_confirmed ? 'background-color: green; border-color: green;' : ''}}">
    <span class="{{weekItem.date_confirmed ? 'white' : 'black'}}" title="Termin bestätigt"><mat-icon
      svgIcon="calendar_done"></mat-icon></span>
  </div>

  <div (click)="weekItem.info_completed = update('info_completed', !weekItem.info_completed)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.info_completed ? 'background-color: orange; border-color: orange;' : ''}}">
    <span class="material-icons {{weekItem.info_completed ? 'white' : 'black'}}"
          title="Infos vollständig">insert_chart</span>
  </div>

  <div (click)="weekItem.documentation_done = update('documentation_done', !weekItem.documentation_done)"
       class="number-circle {{index != null ? 'clickable-object hover-animation' : ''}}"
       style="{{weekItem.documentation_done ? 'background-color: blue; border-color: blue;' : ''}}">
    <span class="material-icons {{weekItem.documentation_done ? 'white' : 'black'}}"
          title="Dokumentation erledigt">done</span>
  </div>

</div>
