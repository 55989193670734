import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoginService} from '../../../services/login/login.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Constants} from '../../../Constants';
import {LoginResponse} from '../login.component';
import {CommonsService} from '../../../services/commons/commons.service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  @Output() openRegister: EventEmitter<any> = new EventEmitter();
  @Output() openReset: EventEmitter<any> = new EventEmitter();
  username;
  password;

  success;

  constructor(private loginService: LoginService, private http: HttpClient, private router: Router, private commonsService: CommonsService) {
  }


  ngOnInit(): void {
  }


  checkInputs() {
    if (this.password !== '' && this.username !== '') {
      this.login();
    }
  }


  login() {
    this.http.post<LoginResponse>(Constants.PYTHON_SERVER_URL + 'auth/login', {
      user: this.username,
      password: this.password
    }).subscribe(
      (data) => {
        if (data.success) {
          this.loginService.logged_in_state.emit(true);
          this.loginService.username.emit(this.username);
          this.router.navigate(['/']);
          this.loginService.saveValue('session_token', data.session);
        } else {
          this.commonsService.showErrorToast(data.error, 'Login fehlgeschlagen', -1);
          this.loginService.logged_in_state.emit(false);
        }
        this.success = data.success;
      }, (error1 => {
        this.commonsService.showErrorToast(error1, 'Login fehlgeschlagen', -1);
        this.success = false;
      })
    );
  }
}
