import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login/login.service';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {


  constructor(private loginService: LoginService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.server_logout();
  }
}
