import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DataService} from "../../../services/data/data.service";

@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.scss']
})
export class UserPropertiesComponent implements OnInit {
  user: any = null;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.refresh().then(r => this.user = r[0]);
  }

  async refresh() {
    return await this.dataService.getUser();
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.request_reload.emit();
    });
  }
}
