import {Component, OnInit} from '@angular/core';
import {CreateService} from "../../../services/create/create.service";
import {LoginService} from "../../../services/login/login.service";
import {DataService} from '../../../services/data/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-send-command',
  templateUrl: './send-command.component.html',
  styleUrls: ['./send-command.component.scss']
})
export class SendCommandComponent implements OnInit {

  admin_token;
  client_id = -1;
  project_id = -1;
  section_id = -1;
  floor_id = -1;
  dispenser_id = -1;
  documentation_id = -1;
  week_plan_id = -1;
  prevent_rename = true;

  finished_loading = false;

  commands = [];
  command;

  constructor(private createService: CreateService, private loginService: LoginService,
              private dataService: DataService, private modalService: NgbModal) {
  }


  check_command(command) {
    for (const cmd of this.commands) {
      if (command === cmd) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.dataService.getCommands().subscribe((data) => {
      this.commands = data;
      this.finished_loading = true;
    });
  }


  send_command() {
    this.createService.sendAdminCommand(this.command,
      this.admin_token,
      this.client_id,
      this.project_id,
      this.section_id,
      this.floor_id,
      this.dispenser_id,
      this.documentation_id,
      this.week_plan_id,
      this.prevent_rename).subscribe((data) => {
    }, error => {
      this.createService.errorHandler(error);
      this.createService.commonsService.showErrorToast(error, 'Der Befehl konnte nicht geschickt werden!. [Mehr Infos]');
    });
  }

  openPicker(content) {
    this.modalService.open(content).result.then((result) => {
      this.client_id = result.client_id;
      this.project_id = result.project_id;
      this.section_id = result.section_id;
      this.floor_id = result.floor_id;

    });
  }

}
