<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Unterschrift hochladen</h4>
  <button (click)="modal.close()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div (fileDropped)="onFileDropped($event)" appDnd class="container" style="margin-top: 3em">
    <input #fileDropRef (change)="fileBrowseHandler($event.target.files)" id="fileDropRef" multiple type="file"/>
    <svg height="64" viewBox="0 0 63 64" width="63" xmlns="http://www.w3.org/2000/svg">
      <g fill="#3B454F" fill-rule="nonzero">
        <path
          d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"/>
        <path
          d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"/>
        <path
          d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"/>
      </g>
    </svg>
    <div>

    </div>
    <h3>Drag and drop file here</h3>
    <h3>or</h3>
  </div>
  <div style="margin: 1em 0; text-align: center">
    <label class="button button-grey" for="fileDropRef" style="max-width: 448px;">Datei Auswählen</label>
  </div>
</div>
<div class="modal-footer">
  <button (click)="modal.close()" class="btn btn-outline-dark" type="button">Schließen</button>
</div>
