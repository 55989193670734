<div class="window-box">
  <div class="inline-box">
    <h4>Nutzer</h4>
    <div class="inline-box">
      <div class="align-mid-vertical" style="display: flex">
            <span (click)="open(createUser, $event)" class="material-icons icon-grey icon-big"
                  style="padding-right: 0.5em;">
            add_box
            </span>
      </div>
      <ng-template #createUser let-modal>
        <app-create-user [modal]="modal"></app-create-user>
      </ng-template>
      <label class="align-mid-vertical">
        <input (change)="search($event.target.value)" (input)="search($event.target.value)" [(ngModel)]="search_word"
               class="search-field"
               placeholder="suchen..."
               type="text">
      </label>
    </div>
  </div>
  <div *ngFor="let u of user; let i = index">
    <details *ngIf="u.visible" class="summary-box hover-animation"
             style="background-color: {{u.default_color}}1A; color: {{u.default_color}};">
      <summary class="heading">
        <div class="align-mid-vertical">
          {{ u.clear_first_name == null && u.clear_last_name == null ? '' : u.name }}
          {{ u.clear_first_name }} {{u.clear_last_name}}
        </div>

        <div>
          <span (click)="open(verify, $event)" class="material-icons">delete</span>
        </div>

        <ng-template #verify let-modal>
          <app-verify (verified)="deleteUser($event, u.name)" [description]="('Nutzer ' + u.name + ' löschen?')"
                      [modal]="modal"></app-verify>
        </ng-template>
      </summary>
      <div class="summary-content-box">
        <div class="small-information-box hover-animation">
          <div class="hover-animation box">
            <b>Vorname:</b>
            {{u.clear_first_name}}<br>
          </div>
          <div class="hover-animation box">
            <b>Nachname:</b>
            {{u.clear_last_name}}<br>
          </div>
          <div class="hover-animation box">
            <b>Mail:</b>
            {{u.mail}}<br>
          </div>
          <div class="hover-animation box">
            <b>Handy:</b>
            {{u.phone_number}}<br>
          </div>
          <div class="hover-animation box">
            <b>Aktuelles Team: </b>
            {{u['team_name']}}<br>
          </div>

          <div class="button-box">
            <!--<button (click)="open(updateUser)">User bearbeiten</button>
            <button>Password zurücksetzen</button>-->
            <div (click)="open(updateTeam, $event)" class="button button-grey" style="width: 30%">Team zuweißen</div>
            <div (click)="open(updateUser, $event)" class="button button-grey" style="width: 30%">Benutzer bearbeiten
            </div>
            <!--<button>Nutzer kündigen</button>-->

            <ng-template #updateTeam let-modal>
              <app-user-update [modal]="modal" [update_mode]="0" [user]="u"></app-user-update>
            </ng-template>
            <ng-template #updateUser let-modal>
              <app-user-update [modal]="modal" [update_mode]="1" [user]="u"></app-user-update>
            </ng-template>
          </div>
        </div>
      </div>
    </details>
  </div>

</div>
