import {Component, Input, OnInit} from '@angular/core';
import {WeekItem} from '../../../../interfaces/WeekInterface';

@Component({
  selector: 'app-info-five',
  templateUrl: './info-five.component.html',
  styleUrls: ['./info-five.component.scss']
})
export class InfoFiveComponent implements OnInit {

  @Input() weekItem: WeekItem;

  constructor() {
  }

  ngOnInit(): void {
  }


}
