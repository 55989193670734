import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {Manufacture, Project, ResponsibleUserName} from '../../interfaces/ProjectInterfaces';
import {UpdateService} from '../../services/update/update.service';
import {TodoTemplate, WeekItem} from '../../interfaces/WeekInterface';
import {CreateService} from '../../services/create/create.service';
import {DeleteService} from '../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientContact} from '../../interfaces/ClientContact';
import {TrashTemplate} from '../../interfaces/TrashTemplateInterface';

@Component({
  selector: 'app-create-week-plan',
  templateUrl: './create-week-plan.component.html',
  styleUrls: ['./create-week-plan.component.scss']
})
export class CreateWeekPlanComponent implements OnInit {


  @Input() modal;
  @Input() project: Project;
  @Input() year: number;
  @Input() week: number;
  @Input() week_plan;

  @Input() override_selected_area_type;
  @Input() override_selected_area_id;
  @Input() override_selected_area_list = [];

  material_order = false;
  material_ordered = false;
  material_stock = false;
  date_requested = false;
  date_confirmed = false;
  info_completed = false;

  responsible_user_name = '';
  area_description = '';
  square_meters = '';
  description = '';
  note = '';
  todo_search_text = '';


  manufactureSearchKeyword = 'manufacture';

  all_user_names: Array<ResponsibleUserName> = [];
  all_manufacture: Array<Manufacture> = [];

  all_client_contacts: Array<ClientContact> = [];
  selected_client_contacts: Array<ClientContact> = [];

  add_clients_contacts: Array<number> = [];

  lastWeek: WeekItem = {
    id: null,
    project_id: null,
    week: null,
    year: null,
    note: null,
    client_contact: null,
    project: null,
    shifts: null,
    todo: null,
    client_id: null,
    client: null,
    secondary_client_id: null,

    secondary_client: null,
    continue_comment: null,
    accounting_comment: null,
    info_completed: null,
    trash: null,
    area_comment: null,
    material_order: null,
    material_ordered: null,
    material_stock: null,
    date_requested: null,
    date_confirmed: null,
    documentation_done: null,
    visible: false,
    responsible_user_name: '',
    week_plan_set: false,
    week_plan_project_exist: false,
    special_tools: '',
    agreements: '',
    manufacture: '',
    client_contact_id: null,


    monday_status: null,
    thursday_status: null,
    wednesday_status: null,
    friday_status: null,
    saturday_status: null,
    sunday_status: null,
    tuesday_status: null,
    area: null,
    create_area: null,
    week_plan_project_exist_week_plan_id: null
  };


  importData;
  finishedLoading;
  updateValues: Map<string, any> = new Map<string, any>();

  updateManufacture = true;
  updateResponsibleUserName = true;

  trashTemplates: Array<TrashTemplate> = [];
  todoTemplates: Array<TodoTemplate> = [];
  reload: boolean;
  todos = [];
  trash = [];
  continue_load_last = false;

  selected_area_type;
  selected_area_id;
  selected_area_list;


  already_requested = false;

  constructor(private dataService: DataService, private updateService: UpdateService,
              private createService: CreateService, private deleteService: DeleteService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.override_selected_area_type != null && this.override_selected_area_id != null) {
      this.selected_area_type = this.override_selected_area_type;
      this.selected_area_id = this.override_selected_area_id;
      this.selected_area_list = this.override_selected_area_list;
    }
    this.reloadTodoTemplates(true);
    this.reloadClientContact(true);
    this.reloadData();
  }


  async reloadData() {
    const data = await this.dataService.getAllResponsibleUserNames();
    this.all_user_names = [];
    for (const i of data) {
      if (i.responsible_user_name != null) {
        this.all_user_names.push(i);
      }
    }


    this.all_manufacture = [];
    for (const i of await this.dataService.getAllManufacture()) {
      if (i.manufacture != null) {
        this.all_manufacture.push(i);
      }
    }

  }

  deleteWeekPlanTodoTemplate(todo_template_id) {
    // deaktiviert
    this.deleteService.deleteTodoTemplate(todo_template_id).subscribe(data => {
      if (data.success) {
        const index = this.todoTemplates.indexOf(todo_template_id, 0);
        this.todoTemplates.splice(index, 1);
      }
    }, error => {
      this.deleteService.coms.showErrorToast(error, 'ToDo-Vorlage', 2);
      this.deleteService.errorHandler(error);

    });
  }

  deleteWeekPlanTodo(todo) {
    this.todos.splice(this.todos.indexOf(todo), 1);

    /* this.deleteService.deleteWeekPlanTodo(todo_id).subscribe(data => {
      if (data.success) {
        const index = this.todos.indexOf(todo_id, 0);
        this.todos.splice(index, 1);
      }
    });*/
  }

  async reloadClientContact(bool) {
    if (bool) {
      const tmp = await this.dataService.getClientContactByClientId(this.project.client_id);
      this.all_client_contacts = [];
      if (this.importData) {
        for (const allClientContact of tmp) {
          let added = false;


          for (const contact of this.lastWeek.client_contact) {
            if (contact.id === allClientContact.id) {
              this.selected_client_contacts.push(allClientContact)
              added = true;
            }
          }
          if (!added) {
            this.all_client_contacts.push(allClientContact)
          }
        }
      } else {
        this.all_client_contacts = tmp;
      }
    }
  }


  async reloadTrashTemplates(bool) {
    if (bool) {
      this.trashTemplates = await this.dataService.getAllTrashTemplates();
      this.trashTemplates.forEach(item => {

        if (this.importData) {
          for (const trash of this.lastWeek.trash) {
            if (item.id === trash.trash_template_id) {
              this.clickTrash(true, item);
            }
          }
        }

      });
    }
  }

  async reloadTodoTemplates(bool) {
    if (bool) {
      this.todoTemplates = await this.dataService.getAllTodoTemplates();
      this.todoTemplates.forEach(item => {
        item.visible = true;
        item.greyed = false;
        if (this.importData) {
          for (const todo of this.lastWeek.todo) {
            if (item.id === todo.todo_template_id) {
              item.greyed = true;
            }
          }
        }

      });
    }
  }


  addWeekPlanTodo(bool, template, value) {
    if (bool) {
      const todo = {
        id: null,
        value,
        visible: template.visible,
        shortcut: template.shortcut,
        name: template.name,
        text: template.text,
        unit: template.unit,
        todo_template_id: +template.id,
        week_plan_id: null
      };
      this.todos.push(todo);
    }
  }

  async loadLastWeek(load: boolean) {
    this.importData = load;
    this.finishedLoading = false;
    try {
      const data = await this.dataService.getLastWeekPlan(this.project.id);
      if (data == null) {
        return;
      }

      if (load) {

        this.material_order = data.material_order;
        this.material_stock = data.material_stock;

        if (data.responsible_user_name != null) {
          this.responsible_user_name = data.responsible_user_name;
        }


        if (data.note != null && data.note.length > 0) {
          this.note = data.note;
          this.updateValues.set('note', data.note);
        }

        this.lastWeek = data;
        this.finishedLoading = true;


      }


      if (data.agreements !== null && data.agreements.length > 0) {
        this.lastWeek.agreements = data.agreements;
        this.updateValues.set('agreements', data.agreements);
      }


      if (data.special_tools != null && data.special_tools.length > 0) {
        this.updateValues.set('special_tools', data.special_tools);
      }

      if (this.lastWeek.manufacture != null && this.lastWeek.manufacture.length > 0) {
        this.updateValues.set('manufacture', data.manufacture);
        this.updateManufacture = false;
      }
      if (this.lastWeek.responsible_user_name != null && this.lastWeek.responsible_user_name.length > 0) {
        this.updateResponsibleUserName = false;
      }

    } finally {
      this.finishedLoading = true;
      this.reloadTrashTemplates(true).then();
      this.reloadTodoTemplates(true).then();
      this.reloadClientContact(true).then();
    }
  }

  selectClientContact(bool, contact) {
    if (bool) {
      this.all_client_contacts.splice(this.all_client_contacts.indexOf(contact), 1);
      this.selected_client_contacts.push(contact);
    } else {
      this.selected_client_contacts.splice(this.selected_client_contacts.indexOf(contact), 1);
      this.all_client_contacts.push(contact);
    }
  }


  isTodoInWeek(template) {
    let bool = false;
    this.todos.some((todo) => {
      if (+todo.todo_template_id === +template.id) {
        bool = true;
        return;
      }
    });
    return bool;
  }

  valueInput(key, value) {
    this.updateValues.set(key, value);
  }


  createWeekPlan() {
    if (this.selected_area_type == null || this.selected_area_id == null) {
      window.alert('Fehler bitte Fenster schließen und erneut Probieren');
      return;
    }
    if (!this.already_requested) {
      this.already_requested = true;
      // First create Plan
      this.createService.createWeekPlan(this.project.id, this.week, this.description,
        this.area_description, this.responsible_user_name, this.year, this.note, this.selected_area_type, this.selected_area_id).subscribe((data) => {
        if (data.success) {
          this.add_contacts(data.data);
          this.update(data.data);
        }
        this.already_requested = false;
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Wochenplan konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }

  }

  update(id) {

    let a = 0;
    let b = 0;

    if (this.material_order) {
      this.updateValues.set('material_order', true);
    }
    if (this.material_ordered) {
      this.updateValues.set('material_ordered', true);
    }
    if (this.material_stock) {
      this.updateValues.set('material_stock', true);
    }
    if (this.date_requested) {
      this.updateValues.set('date_requested', true);
    }
    if (this.date_confirmed) {
      this.updateValues.set('date_confirmed', true);
    }
    if (this.info_completed) {
      this.updateValues.set('info_completed', true);
    }


    this.todos.forEach(item => {
      this.createService.createWeekPlanTodo(id, item.todo_template_id, parseInt(item.value)).subscribe((data) => {
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Aufgabe konnte nicht erstellt werden. [Mehr Infos]');
      });
    });

    this.trash.forEach(item => {
      this.createService.createWeekPlanTrash(id, item.id).subscribe((data) => {
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Aufgabe konnte nicht zugewiesen werden. [Mehr Infos]');
      });
    });

    if (this.updateValues.size === 0) {
      this.modal.close();
    }

    a = this.updateValues.size;
    for (const area of this.selected_area_list) {
      this.updateService.addWeekPlanArea(id, this.dataService.get_area_type(area), this.dataService.get_area_id(area)).subscribe(
        (tdata) => {
          this.updateService.commonsService.showSuccessToast('Info', 'Wochenplan wurde erfolgreich bearbeitet');
        }, error => {
          this.updateService.errorHandler(error);
          this.updateService.commonsService.showErrorToast(error, 'Wochenplan konnte nicht bearbeitet werden [...]', -1);
        }
      );
    }

    this.updateValues.forEach((value, key) => {

      this.updateService.updateWeekPlan(id, key, value).subscribe(data => {
        // TODO implement if success is false
        b++;
        if (a === b) {
          this.modal.close();
        }
      });
    });
  }

  add_contacts(week_plan_id) {
    for (const i of this.selected_client_contacts) {
      this.createService.createClientContactWeek(week_plan_id, i.id).subscribe((data) => {
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Ansprechpartner konnte nicht zugewiesen werden. [Mehr Infos]');
      });
    }

  }


  clickTrash(bool, template: TrashTemplate) {
    if (bool) {
      this.trashTemplates.splice(this.trashTemplates.indexOf(template), 1);
      this.trash.push(template);
    } else {
      this.trash.splice(this.trash.indexOf(template), 1);
      this.trashTemplates.push(template);
    }
  }


  open(content) {
    this.modalService.open(content).result.then((result) => {
      // modal closed
    });
  }

  allUserNamesToList(list: Array<ResponsibleUserName>) {
    const tmp = [];
    for (const a of list) {
      tmp.push(a.responsible_user_name);
    }
    return tmp;
  }

  allManufactureNamesToList(list: Array<Manufacture>) {
    const tmp = [];
    for (const a of list) {
      tmp.push(a.manufacture);
    }
    return tmp;
  }

  search(text) {
    this.todoTemplates = this.setVisibility(this.todoTemplates, text);
    this.todos = this.setVisibility(this.todos, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        item.visible = (
          item.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.text.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
          item.shortcut.toLowerCase().search(compareStr.toLowerCase()) >= 0);
      });
      arr.sort(this.compare);
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  compare(a: TodoTemplate, b: TodoTemplate) {

    if (a.greyed && !b.greyed) {
      return -1;
    }
    if (!a.greyed && b.greyed) {
      return 1;
    }
    return 0;
  }

  select_area(e) {
    const primary = e[0];
    this.selected_area_list = e[1];
    this.selected_area_type = this.dataService.get_area_type(primary);
    this.selected_area_id = this.dataService.get_area_id(primary);
  }
}
