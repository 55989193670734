<div class="window-box inline-box">
  <div *ngFor="let day of available; let i = index" style="width: 100%; text-align: center">
    <p>{{day_names[i]}}</p>
    <div *ngFor="let item of day">
      <div *ngIf="item.available" class="text-box hover-animation"
           style="background-color: {{item.default_color}}1A; color: {{item.default_color}};">
        {{item.name}}
      </div>
      <div *ngIf="!item.available" class="text-box hover-animation not-available">
        {{item.name}}
      </div>
    </div>
  </div>
</div>
