<div>

  <div class="modal-header">
    <h4 class="modal-title">Team hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">
    <div style="margin-top: 1em">

      <app-input [(value)]="team_name" [request_focus]="true"
                 text="Teamname">
      </app-input>

    </div>
    <div style="margin-top: 1em">

      <app-input (onEnter)="createTeam()" [(value)]="default_color" [request_focus]="false"
                 text="Teamfarbe (als Hexadezimalzahl z.B. #62B7DF)">
      </app-input>

    </div>

    <div> So würde das dann aussehen:
      <details class="summary-box hover-animation" style="background-color: {{default_color}}1A;
  color: {{default_color}};">
        <summary class="heading">
          <div class="align-mid-vertical">
            {{team_name}}
          </div>
        </summary>
      </details>
    </div>

  </div>
  <div class="modal-footer">
    <div (click)="createTeam()" class="button button-blue">
      Team hinzufügen
    </div>
  </div>

</div>
