import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {Client} from '../../interfaces/ClientInterface';

@Component({
  selector: 'app-client-contact-create',
  templateUrl: './create-client-contact.component.html',
  styleUrls: ['./create-client-contact.component.scss']
})
export class CreateClientContactComponent implements OnInit {

  @Input() modal;
  @Input() client: Client;
  @Input() client_id: number;
  @Output() done = new EventEmitter<boolean>();
  first_name = '';
  last_name = '';
  phone_number = '';
  mail = '';

  already_requested = false;

  constructor(private createService: CreateService) {
  }

  ngOnInit(): void {
    if (this.client != null) {
      this.client_id = this.client.id;
    }
  }

  createClient() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createClientContact(this.client_id, this.first_name,
        this.last_name, this.phone_number, this.mail).subscribe(data => {
        if (data.success) {
          this.createService.commonsService.showSuccessToast('Info', 'Der Ansprechpartner wurde erfolgreich erstellt');
          this.done.emit(true);
          this.modal.close();
        } else {
          this.already_requested = false;
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Der Ansprechpartner konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }
}
