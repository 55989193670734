import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreateService} from '../../services/create/create.service';

@Component({
  selector: 'app-todo-template-create',
  templateUrl: './create-todo-template.component.html',
  styleUrls: ['./create-todo-template.component.scss']
})
export class CreateTodoTemplateComponent implements OnInit {

  @Input() modal;
  @Output() done = new EventEmitter<boolean>();
  name = '';
  shortcut = '';
  text = '';
  unit = '';

  already_requested = false;

  constructor(private createService: CreateService) {
  }

  ngOnInit(): void {
    this.done.emit(false);
  }

  createTodoTemplate() {
    if (!this.already_requested) {
      this.already_requested = true;
      this.createService.createTodoTemplate(this.name, this.shortcut, this.text, this.unit).subscribe(data => {
        if (data.success) {
          this.done.emit(true);
          this.modal.close();
        } else {
          this.already_requested = false;
          window.alert('Error: Template Create');
        }
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Vorlage konnte nicht erstellt werden. [Mehr Infos]');
        this.already_requested = false;
      });
    }
  }
}
