import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Client} from '../../interfaces/ClientInterface';
import {CreateService} from '../../services/create/create.service';
import {DataService} from '../../services/data/data.service';
import {CommonsService} from "../../services/commons/commons.service";

@Component({
  selector: 'app-project-create',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {

  @Input() modal;
  @Input() project;

  list: Array<Client> = [];

  project_name;
  client;
  secondary_client;
  start_date;
  end_date;
  street;
  plz;
  description;
  lastClick = 0;
  delay = 500;

  sharepoint_path = "click mich";

  already_requested = false;

  constructor(private modalService: NgbModal, private createService: CreateService,
              private dataService: DataService, public commonsService: CommonsService) {
  }

  ngOnInit(): void {
    this.loadClients();
  }

  async getSharepointPath() {
    if (this.lastClick >= (Date.now() - this.delay)) {
      return;
    }
    this.lastClick = Date.now();

    this.sharepoint_path = await this.dataService.getSharePointPath(
      this.project_name == null ? ' ' : this.project_name,
      this.street == null ? ' ' : this.street,
      this.plz == null ? ' ' : this.plz, this.start_date);
  }

  async loadClients() {
    this.list = await this.dataService.getClients();
  }

  submit() {
    if (this.project_name != null && this.client != null && !this.already_requested) {
      this.street = this.street == null ? '' : this.street;
      this.plz = this.plz == null ? '' : this.plz;

      const l = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];
      for (const s of l) {
        if (this.project_name.includes(s) || this.street.toString().includes(s) || this.plz.toString().includes(s)) {

          this.commonsService.showInfoToast('Der Projektname, Straße & PLZ dürfen folgende Zeichen nicht enthalten: \n \\ / : * ? \" < > |', 'Achtung', 1)
          return;
        }


      }
      this.already_requested = true;

      this.createService.createProject(this.project_name, this.client, this.start_date,
        this.end_date, this.street, this.plz, this.description, this.secondary_client)
        .subscribe(data => {
            this.commonsService.showSuccessToast('Info', 'Das Projekt wurde erfolgreich erstellt!');
            if (data.success) {
              this.modal.close();
            } else {
              this.already_requested = false;
            }
          }, error => {
            this.createService.errorHandler(error);
            this.createService.commonsService.showErrorToast(error, 'Das Projekt konnte nicht erstellt werden. [Mehr Infos]');
            this.already_requested = false;
          }
        );

    } else {
      window.alert('Folgende Werte müssen mindestens ausgefüllt sein: \nName, Kunde, Start-Datum, End-Datum');
    }
  }

  selectEvent(item) {
    this.client = item.id;
  }

  selectSecondEvent(item) {
    this.secondary_client = item.id;
  }

  autocompleteCleared() {
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    this.loadClients();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    });
  }


}
