<details class="summary-box dark-I hover-animation">
  <summary (click)="getDispenser(); channel_id.emit(floor.channel_id);">
    <div class="align-mid-vertical">
      {{floor.floor_name}}
    </div>

    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="open(createDispenser, $event)" mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>Verteiler erstellen</span>
      </button>

      <button (click)="open(floorDelete, $event)" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Etage löschen</span>
      </button>
      <button (click)="reUpload()" mat-menu-item>
        <mat-icon>cached</mat-icon>
        <span>Protokolle erneut hochladen</span>
      </button>

      <button (click)="open(updateFloor, $event, true)" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Etage bearbeiten</span>
      </button>


    </mat-menu>
  </summary>

  <div class="summary-content-box">
    <div class="small-information-box hover-animation">
      <b>Vom</b> {{formatDate(floor.start_date)}} <b>bis zum</b> {{formatDate(floor.end_date)}}<br>
      <br>
      <b>Etagen ID:</b>
      <ul>
        <li>
          {{floor.id}}
        </li>
      </ul>

      <div *ngIf="floor.creator_name != null">
        <b>Etagen-Ersteller:</b>
        <ul>
          <li>{{floor.creator_name}}</li>
        </ul>
      </div>
    </div>

    <div style="padding: 1em">
      <div style="display: flex; justify-content: space-evenly">
        <div (click)="openAllDispenserDocumentation()" class="button button-grey" style="width: 30% !important">Alle
          Verteilerdokumentationen
        </div>
        <div (click)="openAllPressureDocumentation()" class="button button-grey" style="width: 30% !important">Alle
          Dichtheitsprüfungen
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; width: 100%; flex-wrap: wrap">
        <div *ngFor="let d of floor.dispenser; index as i" style="width: 48%">
          <app-dispenser (reload)="getDispenser(true)" [dispenser]="d" [dispenser_picture]="dispenserPicNum(i)"
                         [floor]="floor"
                         [project]="project"></app-dispenser>
        </div>
      </div>
    </div>

  </div>

</details>

<ng-template #floorDelete let-modal>
  <app-sharepoint-delete (sharepoint_delete)="verified($event)" [modal]="modal"
                         [type]="'Etage (' + floor.floor_name + ')'"></app-sharepoint-delete>
</ng-template>


<ng-template #createDispenser let-modal>
  <app-create-dispenser [modal]="modal" floor_id="{{floor.id}}"></app-create-dispenser>
</ng-template>


<ng-template #updateFloor let-modal>
  <app-update-floor [floor]="floor"
                    [modal]="modal"></app-update-floor>
</ng-template>
