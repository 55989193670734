import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from "../../services/create/create.service";
import {CommonsService} from "../../services/commons/commons.service";

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  @Input() modal;

  name = '';
  pre_name = '';
  email = '';
  phone_number = '';
  password = '';
  password_filler = '';

  constructor(private commonsService: CommonsService, private createService: CreateService) {
  }

  ngOnInit(): void {
  }

  checkInputs() {
    if (this.name === '') {
      this.showToast('Name fehlt', 'Fehler', 2);
    }

    if (this.pre_name === '') {
      this.showToast('Vorname fehlt', 'Fehler', 2);
    }

    if (this.email === '') {
      this.showToast('E-Mail fehlt', 'Fehler', 2);
    }

    if (this.phone_number === '') {
      this.showToast('Telefonnummer fehlt', 'Fehler', 2);
    }

    if (this.password === '') {
      this.showToast('Passwort fehlt', 'Fehler', 2);
    }

    if (!((this.name === '') && (this.pre_name === '') && (this.email === '') && (this.phone_number === '') && (this.password === ''))) {
      // TODO Passwort auf Sonderzeichen prüfen
      if (this.password !== this.password_filler || this.password === '') {
        this.showToast('Passwörter stimmen nicht überein', 'Fehler', 2);
      } else if (this.password === this.password_filler) {
        this.createUser();
      }
    }
  }

  createUser() {
    this.createService.createUser(this.name, this.pre_name, this.email, this.phone_number, this.password).subscribe(
      (data) => {
        if (data.success) {
          this.modal.close();
        }
      }, error => {
        this.createService.commonsService.showErrorToast(error, 'Der Nutzer konnte nicht erstellt werden. [Mehr Infos]');
      }
    );

  }

  showToast(text: string, title: string, type: number) {
    this.commonsService.showInfoToast(text, title, type)
  }
}
