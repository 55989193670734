<div>

  <div class="modal-header">
    <h4 class="modal-title">Werteingabe</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <app-input (onEnter)="value.emit(value_var); modal.close()" (valueChange)="inputValue($event)"
               [request_focus]="true"
               text="Menge in {{template.unit}} eingeben" type="number"></app-input>
  </div>


  <div class="modal-footer">
    <div (click)="value.emit(value_var); modal.close()"
         class="button button-blue" style="width: unset;">
      OK
    </div>
  </div>

</div>
