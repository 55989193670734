<details class="summary-box blue hover-animation">

  <summary><h2>Projekt - Kunden Testreihe erstellen</h2></summary>
  <div class="summary-content-box">
    <div class=" small-information-box">

      <div style="width: 50%; display: flex">
        <app-input [(value)]="client_name" text="Kundenname"></app-input>
        <div style="width: 1em"></div>
        <app-input [(value)]="project_name" text="Projectname"></app-input>
      </div>

      <div (click)="createTestRow()" class="button button-blue" style="width: 18em; height: 3em">
        Test Projekt erstellen
      </div>
    </div>
  </div>
</details>
