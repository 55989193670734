import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {Client} from '../../interfaces/ClientInterface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteService} from '../../services/delete/delete.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {


  list: Array<Client> = [];
  search_text = '';

  constructor(private dataService: DataService, private modalService: NgbModal, private deleteService: DeleteService) {
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.list = await this.dataService.getClients();
    this.list.forEach(item => {
      item.visible = true;
    });

    if (this.search_text.length > 0) {
      this.search(this.search_text);
    }

  }

  deleteClient(client_id) {
    this.deleteService.deleteClient(client_id).subscribe(data => {
      if (data) {
        this.getData();
      }
    }, error => {
      this.deleteService.coms.showErrorToast(error, 'Kunde', 2);
      this.deleteService.errorHandler(error);

    });
  }

  verified(bool, client_id) {
    if (bool) {
      this.deleteClient(client_id);
    }
  }


  open(content, event) {
    event.preventDefault();
    this.modalService.open(content).result.then((result) => {
      this.getData();
    });
  }

  async loadContact(client) {

    client.contacts = await this.dataService.getClientContactByClientId(client.id);


    const tmp = [];
    for (const i of await this.dataService.getProjectsByClient(client.id)) {
      i.visible = true;
      tmp.push(i);
    }
    client.projects = tmp;

  }

  search(text) {
    this.list = this.setVisibility(this.list, text);
  }

  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
          item.visible = (
            (item.name != null && item.name.toLowerCase().search(compareStr.toLowerCase()) >= 0) ||
            (item.street != null && item.street.toLowerCase().search(compareStr.toLowerCase()) >= 0) ||
            (item.zip_code != null && item.zip_code.toLowerCase().search(compareStr.toLowerCase()) >= 0) ||
            'id:' + item.id.toString() === compareStr.toLowerCase() ||
            item.id.toString() === compareStr.toLowerCase()
          );
        }
      );
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }
}
