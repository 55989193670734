import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeekItem} from '../../../interfaces/WeekInterface';
import {UpdateService} from '../../../services/update/update.service';
import {DataService} from '../../../services/data/data.service';

@Component({
  selector: 'app-import-weekplan',
  templateUrl: './import-week-plan.component.html',
  styleUrls: ['./import-week-plan.component.scss']
})
export class ImportWeekPlanComponent implements OnInit {

  @Output() loadLastWeek: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() select_area: EventEmitter<any> = new EventEmitter<any>();
  @Input() week_item: WeekItem;
  @Input() modal;

  @Input() override_selected_area_type;
  @Input() override_selected_area_id;
  @Input() override_selected_area_list;

  areas = [];

  floor_ids = {};
  section_ids = {};


  areas_selected = false;
  already_exist_selected;


  constructor(private updateService: UpdateService, private dataService: DataService) {
  }

  ngOnInit(): void {

    if (this.override_selected_area_type != null && this.override_selected_area_id != null) {
      this.areas_selected = true;
      this.already_exist_selected = true;
    }else{
      this.set_ids(this.week_item.area)
      const size = this.calc_three_size(this.week_item.area);
      if (size == 1 && !this.week_item.week_plan_project_exist) {
        this.area_select();
      }

      if (size === 0) {
        window.alert('Ein Fehler ist aufgetreten!');
      }
    }

  }

  calc_three_size(three) {
    if (three == null) {
      return 0;
    }

    let size = 1;
    if (three.sub_areas == null) {
      return size;
    }


    for (const subArea of three.sub_areas) {
      size++;

      if (subArea.sub_areas != null) {
        size += this.calc_three_size(subArea);
      }
    }
    return size;
  }

  set_ids(three) {
    if (three == null) {
      return;
    }

    for (const subArea of three.sub_areas) {
      if (subArea.section != null) {
        this.section_ids[subArea.section.id] = [true, subArea];
      }
      if (subArea.floor != null) {
        this.floor_ids[subArea.floor.id] = [true, subArea];
      }
      if (subArea.sub_areas != null) {
        this.set_ids(subArea);
      }
    }
  }

  area_select() {
    const tmp = [];

    for (const floorIdsKey in this.floor_ids) {
      tmp.push(this.floor_ids[floorIdsKey][1]);
    }
    for (const sectionIdsKey in this.section_ids) {
      tmp.push(this.section_ids[sectionIdsKey][1]);
    }


    if (this.already_exist_selected == null || !this.already_exist_selected) {
      const a = tmp[0];
      tmp.reverse();
      tmp.pop();
      this.select_area.emit([a, tmp]);
      this.areas_selected = !this.areas_selected;
    } else {
      for (const tmpElement of tmp) {
        this.updateService.addWeekPlanArea(this.week_item.week_plan_project_exist_week_plan_id,
          this.dataService.get_area_type(tmpElement), this.dataService.get_area_id(tmpElement)).subscribe(
          (tdata) => {
            this.updateService.commonsService.showSuccessToast('Info', 'Wochenplan wurde erfolgreich bearbeitet');
          }, error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Wochenplan konnte nicht bearbeitet werden [...]', -1);
          }
        );
      }
      this.modal.close();
    }
  }
}
