import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ChatService} from '../../../services/chat/chat.service';
import {Channel, Message} from '../../../interfaces/ChatInterfaces';
import {LoginService} from '../../../services/login/login.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {

  @Input() channel_id;

  message = '';
  messages: Array<Message>;
  username = '';
  channel: Channel;
  sending = false;
  thread;
  last_message = -1;
  scroll_container;

  constructor(private chatService: ChatService, private loginService: LoginService) {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    this.refreshMessages();


    const jwt_data: object = this.loginService.getUsername();
    // @ts-ignore
    if (jwt_data.name != null) {
      // @ts-ignore
      this.username = jwt_data.name;
    }
    this.refreshMessages();
    this.startThread();
    this.scroll_container = document.getElementById('scrollbar-container');
  }

  ngOnChanges() {
    this.chatService.getChannel(this.channel_id).subscribe(data => {
      this.channel = data.data;
    });
    this.refreshMessages();
  }

  refreshMessages() {
    this.refreshChannel();
    this.chatService.getMessage(this.channel_id, this.last_message).subscribe(data => {
        this.messages = data.data.reverse();

        // it's 1000000 because then its higher then 100% and then automatically 100%
        this.scroll_container.scrollTo(0, 1000000);
      }
    );


  }

  sendMessage() {
    if (this.message !== '' && !this.sending) {
      this.sending = true;
      this.chatService.sendMessage(this.channel_id, this.message).subscribe(data => {
        if (data.success) {

          this.message = '';
        } else {

          window.alert('Nachricht konnte nicht gesendet werden!');
        }
        this.refreshMessages();
        this.sending = false;
      });
    }
  }

  refreshChannel() {
    this.chatService.getChannel(this.channel_id).subscribe(data => {
      this.channel = data.data;
    });
  }

  checkForNewMessages() {
    this.chatService.getChannel(this.channel_id).subscribe(data => {
      if (data !== null) {
        if (data.data.channel_count > this.channel.channel_count) {
          this.refreshMessages();
        }
      }
    });
  }


  startThread() {
    if (typeof Worker !== 'undefined') {
      this.thread = new Worker(new URL('./chat.worker', import .meta.url), {type: 'module'});
      this.thread.onmessage = ({data}) => {
        // every 10s chat message refresh
        this.checkForNewMessages();
      };
      this.thread.postMessage('start_refresh');
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }
}
