import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {

  @Input() search_word: string;
  @Input() second_search_word: string;
  @Input() selected: Array<any> = [];
  @Input() available: Array<any> = [];
  @Output() add_action: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove_action: EventEmitter<any> = new EventEmitter<any>();
  @Output() create_action: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }


}
