<div class="modal-header">
  <h4 class="modal-title">Nutzer erstellen</h4>
  <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">


  <app-input [(value)]="pre_name" text="Vorname" type="text"></app-input>


  <app-input [(value)]="name" text="Nachname" type="text"></app-input>


  <app-input [(value)]="email" text="Email" type="email"></app-input>


  <app-input [(value)]="phone_number" text="Handynummer" type="tel"></app-input>


  <app-input [(value)]="password" text="Password" type="password"></app-input>


  <app-input [(value)]="password_filler" text="Password wiederholen" type="password"></app-input>


</div>
<div class="modal-footer">
  <div (click)="checkInputs()" class="button button-blue">
    Nutzer erstellen
  </div>
</div>
