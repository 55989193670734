<div
  style="grid-area: head; border-right: solid grey 1px; height: 100% ;width: 100%;padding: 0.25em;">
  Bauabschnitt:<br>
  <b>


    <div *ngIf="weekItem.area != null">
      <div *ngIf="weekItem.area.project != null">
        {{weekItem.area.project.name}}
      </div>

      <ul>

        <li *ngFor="let item of weekItem.area.sub_areas">

          <div *ngIf="item.section != null">
            {{item.section.name}}

            <ul>
              <li *ngFor="let sub_item of item.sub_areas">
                {{sub_item.floor.floor_name}}
              </li>
            </ul>
          </div>

          <div *ngIf="item.floor != null">
            {{item.floor.floor_name}}
          </div>

        </li>

      </ul>


    </div>


    <div *ngIf="weekItem.area_comment == null"></div>
    <div *ngIf="weekItem.area_comment != null">
      <p [innerHTML]="weekItem.area_comment | Nl2br"></p>
    </div>


  </b>
</div>
