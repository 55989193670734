<div>

  <div class="modal-header">
    <h4 class="modal-title">Wochenplan ToDo bearbeiten</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

    <b>Titel/Name:</b>
    <ul>
      <li>{{todo.template.name}}</li>
    </ul>

    <b>Inhalt:</b>
    <ul>
      <li>{{todo.template.text}}</li>
    </ul>


    <app-input (onEnter)="updateTodo()" (valueChange)="old.set('value', $event)"
               [request_focus]="true" text="Anzahl in {{todo.template.unit}}" value="{{old.get('value')}}"></app-input>

  </div>
  <div class="modal-footer">
    <div (click)="updateTodo()" class="button button-blue">
      OK
    </div>
  </div>
</div>
