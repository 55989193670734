<div>

  <div style="display: flex; flex-direction: column; max-width: 100vh">
    <h4 style="white-space: normal;width: 100%">{{channel != undefined ? channel.channel_name : ''}}</h4>
    <div class="message-container" id="scrollbar-container">

      <table id="chat-table" style="border-collapse: separate">
        <tr>
          <!--<td><button (click)="last_message = messages[0].id; refreshMessages()">mehr Laden</button></td>-->
        </tr>
        <tr *ngFor="let item of messages">
          <td *ngIf="username !== item.username" colspan="3">
            <div class="message">{{item.content}}
              <div class="message-information-box">
                <div>{{item.time}}</div>
                <div>{{item.username}}</div>
              </div>
            </div>
          </td>
          <td colspan="1" style="width: 25%"></td>
          <td *ngIf="username == item.username" colspan="3">
            <div class="message-user">{{item.content}}
              <div class="message-information-box">
                <div>{{item.time}}</div>
                <div>{{item.username}}</div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>


    <div class="chat-bottom-container">
      <label>
        <textarea [(ngModel)]="message"></textarea>
      </label>


      <div style="display: flex; justify-content: end">
        <div (click)="sendMessage();" class="button button-blue">Senden</div>
      </div>

    </div>
  </div>
</div>
