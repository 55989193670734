import {Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../../interfaces/ClientInterface';
import {WeekItem} from '../../../../interfaces/WeekInterface';

@Component({
  selector: 'app-info-two',
  templateUrl: './info-two.component.html',
  styleUrls: ['./info-two.component.scss']
})
export class InfoTwoComponent implements OnInit {

  @Input() client: Client;
  @Input() secondary_client: Client;
  @Input() weekItem: WeekItem;

  constructor() {
  }

  ngOnInit(): void {
  }

}
