import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeekItem} from "../../../../interfaces/WeekInterface";
import {UpdateService} from "../../../../services/update/update.service";

@Component({
  selector: 'app-info-four',
  templateUrl: './info-four.component.html',
  styleUrls: ['./info-four.component.scss']
})
export class InfoFourComponent implements OnInit {

  @Input() weekItem: WeekItem;
  @Output() request_reload: EventEmitter<any> = new EventEmitter();
  tmp_accounting = '';

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
  }

  valueInput(value) {

    this.tmp_accounting = value;

    const wordSearch = value;
    setTimeout(() => {
      if (wordSearch === this.tmp_accounting) {
        this.updateService.updateWeekPlan(this.weekItem.id, 'accounting_comment', wordSearch).subscribe(data => {
          if (data.success) {
            this.weekItem.accounting_comment = wordSearch;
          }
        });
      }

    }, 2000);
  }

  updateNextKW(value) {
    this.updateService.updateWeekPlan(this.weekItem.id, 'continue_comment', value).subscribe(data => {
      if (data.success) {
        this.weekItem.continue_comment = value;
      }
    });
  }

}
