import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../../services/data/data.service';
import {User} from '../../../interfaces/UserInterface';

@Component({
  selector: 'app-available-user-tab',
  templateUrl: './available-user-tab.component.html',
  styleUrls: ['./available-user-tab.component.scss']
})
export class AvailableUserTabComponent implements OnInit, OnDestroy {

  week_number: number;
  year: number;

  day_names = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

  available: Array<Array<User>> = [];

  interval_id;

  constructor(private route: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(data => {
      this.year = data.year;
      this.week_number = data.week_number;
      this.refresh();
    });
    this.interval_id = setInterval(() => {
      this.refresh();
    }, 60000);
  }

  ngOnDestroy() {
    clearInterval(this.interval_id);
  }

  refresh(): void {
    const dates = this.dataService.getDateRangeOfWeek(this.week_number, this.year);


    dates.forEach(async (item: any, index) => {
      const tmp = await this.dataService.getAvailable(item);

      for (const a of await this.dataService.getNotAvailable(item)) {
        tmp.push(a);
      }
      this.available[index] = tmp;
    });

  }

}
