import {Component, Input, OnInit} from '@angular/core';
import {CreateService} from '../../services/create/create.service';
import {DataService} from '../../services/data/data.service';
import {DeleteService} from '../../services/delete/delete.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TodoTemplate} from '../../interfaces/WeekInterface';

@Component({
  selector: 'app-create-todo-week-plan-create',
  templateUrl: './create-todo-week-plan.component.html',
  styleUrls: ['./create-todo-week-plan.component.scss']
})
export class CreateTodoWeekPlanComponent implements OnInit {

  @Input() modal;
  @Input() weekItem;
  todo_templates = [];
  todo_value: number;
  todo_search_text = '';
  already_requested = [false, false, false];

  constructor(private dataService: DataService, private deleteService: DeleteService,
              private createService: CreateService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.reloadTodoTemplates(true).then(r => {
      this.todo_templates.forEach(item => {
        item.visible = true;
        item.greyed = false;
        if (this.todo_templates) {
          for (const todo of this.weekItem.todo) {
            if (item.id === todo.todo_template_id) {
              item.greyed = true;
            }
          }
        }

      });
    });
    this.weekItem.todo.forEach(item => {
      item.visible = true;
    });
  }

  async reloadTodoTemplates(bool) {
    if (bool) {
      this.todo_templates = await this.dataService.getAllTodoTemplates();
    }
  }

  deleteWeekPlanTodoTemplate(todo_template_id) {
    if (!this.already_requested[0]) {
      this.already_requested[0] = true;
      this.deleteService.deleteTodoTemplate(todo_template_id).subscribe(data => {
        if (data.success) {
          const index = this.todo_templates.indexOf(todo_template_id, 0);
          this.todo_templates.splice(index, 1);
        }
        this.already_requested[0] = false;
      }, error => {
        this.deleteService.errorHandler(error);
        this.deleteService.coms.showErrorToast(error, 'ToDo-Vorlage', 2);
      });
    }
  }

  deleteWeekPlanTodo(todo_id) {
    if (!this.already_requested[1]) {
      this.already_requested[1] = true;
      this.deleteService.deleteWeekPlanTodo(todo_id).subscribe(data => {
        if (data.success) {
          // const index = this.weekItem.todo.indexOf(todo_id, 0);
          let ind = null;
          this.weekItem.todo.forEach((value, index) => {
            if (value.id === todo_id) {
              ind = index;
            }
          });
          this.weekItem.todo.splice(ind, 1);
        }
        this.already_requested[1] = false;
      }, error => {
        this.deleteService.errorHandler(error);
        this.deleteService.coms.showErrorToast(error, 'ToDo', 2);
      });
    }
  }

  isTodoInWeek(template) {
    let bool = false;
    this.weekItem.todo.some((todo) => {
      if (+todo.template.id === +template.id) {
        bool = true;
        return;
      }
    });
    return bool;
  }

  setValue(value) {
    this.todo_value = value;
  }

  addWeekPlanTodo(bool, template, value) {
    if (bool && !this.already_requested[2]) {
      this.already_requested[2] = true;
      this.createService.createWeekPlanTodo(this.weekItem.id, template.id, value).subscribe(data => {
        this.weekItem.todo.push({
          id: data.data,
          value: String(value),
          visible: true,
          template: {
            id: +template.id,
            shortcut: template.shortcut,
            order: template.order,
            name: template.name,
            text: template.text,
            unit: template.unit,
            sharepoint_link: template.sharepoint_link
          },
          week_plan_id: data.data
        });
        this.already_requested[2] = false;
      }, error => {
        this.createService.errorHandler(error);
        this.createService.commonsService.showErrorToast(error, 'Die Aufgabe konnte nicht zugewiesen werden. [Mehr Infos]');
      });
    }
  }

  closeModal() {
    this.modal.close();
  }

  open(content, event) {
    event.stopPropagation();
    this.modalService.open(content).result.then((result) => {
      // modal closed
    });
  }

  search(text) {
    this.todo_templates = this.setVisibility(this.todo_templates, text);
    this.weekItem.todo = this.setVisibility(this.weekItem.todo, text);
  }


  setVisibility(arr, compareStr) {
    if (compareStr !== '') {
      arr.forEach(item => {
        if (item.template === undefined) {
          item.visible = (
            item.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
            item.text.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
            item.shortcut.toLowerCase().search(compareStr.toLowerCase()) >= 0);
        } else {
          item.visible = (
            item.template.name.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
            item.template.text.toLowerCase().search(compareStr.toLowerCase()) >= 0 ||
            item.template.shortcut.toLowerCase().search(compareStr.toLowerCase()) >= 0);
        }
      });
      arr.sort(this.compare);
    } else {
      arr.forEach(item => {
        item.visible = true;
      });
    }
    return arr;
  }

  compare(a: TodoTemplate, b: TodoTemplate) {

    if (a.greyed && !b.greyed) {
      return -1;
    }
    if (!a.greyed && b.greyed) {
      return 1;
    }
    return 0;
  }
}
