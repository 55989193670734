import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

  @Input() start_date: Date = new Date();
  @Input() start_date_string: string;
  @Output() start_dateChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() end_date: Date = new Date();
  @Input() end_date_string: string;
  @Output() end_dateChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    if (this.start_date_string != null) {
      const tmp = this.start_date_string.split('-');
      this.start_date.setUTCFullYear(Number(tmp[0]), Number(tmp[1]) - 1, Number(tmp[2]));
    }
    if (this.end_date_string != null) {

      const tmp2 = this.end_date_string.split('-');
      this.end_date.setUTCFullYear(Number(tmp2[0]), Number(tmp2[1]) - 1, Number(tmp2[2]));
    }
  }

  try_set_end_date() {
    if (this.end_date == null) {
      this.end_date = new Date();
      this.end_date.setFullYear(this.start_date.getFullYear(), this.start_date.getMonth(), this.start_date.getDate());
      this.end_date.setDate(this.end_date.getDate() + 1);
    }
  }

  input_start_date(event) {
    this.start_date = event.value;
    this.start_dateChange.emit(this.datePipe.transform(event.value, 'yyyy-MM-dd'));
    this.try_set_end_date();
  }

  input_end_date(event) {
    const data = event.value;
    this.end_dateChange.emit(this.datePipe.transform(event.value, 'yyyy-MM-dd'));
  }
}
