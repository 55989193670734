<div style="display: flex; justify-content: space-between">
  <div>
    <div class="window-box" style="width: 75vw">
      <div class="inline-box">
        <h4>Projekt Übersicht</h4>

        <div class="inline-box">

          <div class="align-mid-vertical" style="display: flex">
            <span (click)="open(advancedDocumentation, $event)" class="material-icons icon-grey icon-big"
                  style="padding-right: 0.5em;">
            addchart
            </span>
          </div>
          <div class="align-mid-vertical" style="display: flex">
            <span (click)="open(projectCreate, $event)" class="material-icons icon-grey icon-big"
                  style="padding-right: 0.5em;">
            add_box
            </span>
          </div>
          <label class="align-mid-vertical">
            <input (change)="search($event.target.value)" (input)="search($event.target.value)"
                   [(ngModel)]="search_text"
                   class="search-field"
                   placeholder="suchen..."
                   type="text">
          </label>
        </div>
      </div>
      <div *ngFor="let project of list; let i = index">
        <app-project-item (delete)="this.deleteProject(project)" [project]="project"></app-project-item>
      </div>
    </div>

    <!--<div *ngIf="selected_channel != undefined" class="chat-box" style="width: 20vw;">
      <app-chat [channel_id]="selected_channel"></app-chat>
    </div>-->
  </div>

</div>


<ng-template #projectCreate let-modal>
  <app-project-create [modal]="modal"></app-project-create>
</ng-template>
<ng-template #advancedDocumentation let-modal>
  <app-create-advanced-documentation [modal]="modal"></app-create-advanced-documentation>
</ng-template>
