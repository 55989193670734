<div class="div-scrollable" style="display: flex; height: 100%; max-height: 10em">
  <div *ngFor="let day of days; let i = index" style="width: 14%; padding: 0;">

    <div class="{{ available_days[i] == '2'? 'color':''}} {{ available_days[i] == '3'? 'grey-shift-field':''}}"
         style="height: 100%; min-height: calc({{checkShiftOfDay(day)}}em + 6em)">

      <div style="display: flex; justify-content: center">

        <span (click)="handleCheck(clearAllShifts, $event, i, available_days[i])"
              *ngIf="weekItem.week_plan_set"
              class="material-icons" style="color: green; cursor: pointer">
          {{ available_days[i] == '1' ? 'check_box_outline_blank' : ''}}
          {{ available_days[i] == '2' ? 'check' : ''}}
          {{ available_days[i] == '3' ? 'do_disturb_on' : ''}}
          </span>


      </div>


      <div *ngIf="available_days[i] == '2' || available_days[i] == '3'" style="padding: 0.6em;">
        <div style="display:flex; justify-content: center">

        </div>
        <div *ngFor="let shift of day">
          <div (contextmenu)="onRightClick($event, menuTrigger)" *ngIf="shift != null && shift"
               [ngClass]="{'grey-shift-field': shift.canceled}"
               class="red-shift-field hover-animation">
            <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>
            <div style="text-align: end; cursor: pointer">


              <p
                style="text-align: center">{{shift.clear_first_name == null ?
                shift.user_name :
                shift.clear_first_name + " " + shift.clear_last_name[0] + "."}}</p>


              <mat-menu #menu="matMenu" style="color: black">
                <button (click)="shiftStatus(shift.id,3)" *ngIf="!shift.canceled" mat-menu-item
                        style="background-color: transparent">
                  <span style="color: black; background-color: transparent">Monteur ausgefallen</span>
                </button>

                <button (click)="shiftStatus(shift.id,0)" *ngIf="shift.canceled" mat-menu-item
                        style="background-color: transparent">
                  <span style="color: black; background-color: transparent">Monteur nicht ausgefallen</span>
                </button>

                <button (click)="deleteShift(shift, i)" at-menu-item style="background-color: transparent">
                  <span style="color: black; background-color: transparent">Schicht löschen</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <div *ngIf="edit_mode">
          <div *ngFor="let a of this.selected_user[i];">
            <div class="ng-autocomplete" style="margin-bottom: 0.25em">
              <app-autocomplete
                (onEnter)="selectEvent($event,i)"
                (onFocus)="onFocused($event, i)"
                (onObject)="selectEvent($event.name, i)"
                [objectDisplayKeyList]="['clear_first_name','clear_last_name']"
                [objectKeyList]="['name','clear_first_name','clear_last_name']"
                [objectList]="this.available[i]"
                [searchObjects]="true"
                [use_round_design]="false"
                objectKey="name">
              </app-autocomplete>

            </div>
          </div>
        </div>
      </div>

      <div *ngIf="available_days[i] == '0'">

      </div>
    </div>
  </div>
  <!--<div style="width: 20px;"></div> do we rly need this?-->
</div>

<ng-template #clearAllShifts let-modal>
  <app-verify [description]="('Alle Schichten werden entfernt!')"
              [modal]="modal"></app-verify>
</ng-template>
