import {Component, OnInit} from '@angular/core';
import {Constants} from '../../../Constants';
import {LoginService} from "../../../services/login/login.service";
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  hovering = true;
  admin_nav = false;

  constructor(private loginService: LoginService) {
  }

  ngOnInit(): void {
  }

  openSharepoint() {
    window.open(Constants.SHAREPOINT_URL);
  }

  openDev(){
    window.open('https://dev.montagespezis.app');
  }

  navHovered(bool: boolean) {
    this.hovering = bool;
  }

  show_admin() {
    return this.loginService.is_maintenance_user();
  }

  openHelp() {
    console.log('Try Open Error page');
    const error = Sentry.captureException('Fehler');
    Sentry.init({
      dsn: Constants.SENTRY_URL,

    });
    Sentry.showReportDialog({
      title: "Probleme melden",
      subtitle: 'Bitte beschreibe das Problem so gut wie möglich.',
      subtitle2: ''

    });
  }

  open_admin() {
    this.admin_nav = !this.admin_nav;
  }
}
