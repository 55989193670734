import {Injectable} from '@angular/core';
import {Constants} from '../../Constants';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../login/login.service';
import {DeleteWeekplanInterface} from '../../interfaces/WeekInterface';
import {ServerResponse} from '../../interfaces/DefaultResponse';
import {CommonsService} from '../commons/commons.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  deleteMode = 2;
  coms: CommonsService;

  constructor(private http: HttpClient, private loginService: LoginService, private commonsService: CommonsService) {
    this.coms = commonsService;
  }

  async errorHandler(error) {
    if (error.status === 401) {
      if (!(await this.loginService.verifyToken())) {
        this.loginService.server_logout();
      }
    }
  }

  deleteShift(shift_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'shift/delete', {
      session: token,
      shift_id
    });
  }

  deleteWeekPlan(week_plan_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<DeleteWeekplanInterface>(Constants.PYTHON_SERVER_URL + 'week_plan/delete', {
      session: token,
      week_plan_id
    });
  }

  deleteClientContactWeek(week_plan_id, contact_id) {
    const token = this.loginService.getSessionToken();
    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/contact/removeWeekPlan', {
      session: token,
      week_plan_id,
      contact_id
    });
  }

  deleteClientContact(contact_id) {
    const token = this.loginService.getSessionToken();
    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/contact/delete', {
      session: token,
      contact_id
    });
  }

  deleteFloor(floor_id, delete_data = true) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'floor/delete', {
      session: token,
      floor_id,
      no_sharepoint_delete: !delete_data
    });
  }

  deleteSection(section_id, delete_data = true) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'section/delete', {
      session: token,
      section_id, no_sharepoint_delete: !delete_data

    });
  }

  deleteProject(project_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'project/delete', {
      session: token,
      project_id
    });
  }

  deleteClient(client_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'client/delete', {
      session: token,
      client_id
    });
  }

  deleteUser(user_name) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'user/delete', {
      session: token,
      user_name
    });
  }

  deleteDispenser(dispenser_id, delete_data = true) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'dispenser/delete', {
      session: token,
      dispenser_id,
      no_sharepoint_delete: !delete_data
    });
  }

  deleteWeekPlanTodo(week_plan_todo_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/delete', {
      session: token,
      week_plan_todo_id
    });
  }

  deleteWeekPlanTrash(week_plan_trash_id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/trash/delete', {
      session: token,
      week_plan_trash_id
    });
  }


  deleteTodoTemplate(id) {
    const token = this.loginService.getSessionToken();

    return this.http.post<ServerResponse>(Constants.PYTHON_SERVER_URL + 'week_plan/todo/template/delete', {
      session: token,
      id
    });
  }
}
