import {Component, Input, OnInit} from '@angular/core';
import {ClientContact} from "../../interfaces/ClientContact";
import {UpdateService} from "../../services/update/update.service";

@Component({
  selector: 'app-show-client-contact',
  templateUrl: './show-client-contact.component.html',
  styleUrls: ['./show-client-contact.component.scss']
})
export class ShowClientContactComponent implements OnInit {

  @Input() modal;
  @Input() client_contact: ClientContact;
  @Input() edit_mode = false;

  first_name: string;
  last_name: string;
  phone_number: string;
  mail: string;

  constructor(private updateService: UpdateService) {
  }

  ngOnInit(): void {
    this.first_name = this.client_contact.first_name;
    this.last_name = this.client_contact.last_name;
    this.phone_number = this.client_contact.phone_number;
    this.mail = this.client_contact.mail;
  }


  updateValues() {
    if (!this.edit_mode) {
      this.modal.close();
    } else {
      let a = 0;
      let b = 0;

      if (this.first_name !== this.client_contact.first_name) {
        a++;
        this.updateService.updateClientContact(this.client_contact.id, 'first_name', this.first_name).subscribe(
          (tdata) => {
            b++;
            this.checkFinish(a, b);
            this.updateService.commonsService.showSuccessToast('Info', 'Ansprechpartner wurde erfolgreich bearbeitet');
          }, error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Ansprechpartner konnte nicht bearbeitet werden [...]', -1);
          }
        );

      }
      if (this.last_name !== this.client_contact.last_name) {
        a++;
        this.updateService.updateClientContact(this.client_contact.id, 'last_name', this.last_name).subscribe(
          (tdata) => {
            b++;
            this.checkFinish(a, b);
            this.updateService.commonsService.showSuccessToast('Info', 'Ansprechpartner wurde erfolgreich bearbeitet');
          }, error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Ansprechpartner konnte nicht bearbeitet werden [...]', -1);
          }
        );

      }
      if (this.phone_number !== this.client_contact.phone_number) {
        a++;
        this.updateService.updateClientContact(this.client_contact.id, 'phone_number', this.phone_number).subscribe(
          (tdata) => {
            b++;
            this.checkFinish(a, b);
            this.updateService.commonsService.showSuccessToast('Info', 'Ansprechpartner wurde erfolgreich bearbeitet');
          }, error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Ansprechpartner konnte nicht bearbeitet werden [...]', -1);
          }
        );

      }
      if (this.mail !== this.client_contact.mail) {
        a++;
        this.updateService.updateClientContact(this.client_contact.id, 'mail', this.mail).subscribe(
          (tdata) => {
            b++;
            this.checkFinish(a, b);
            this.updateService.commonsService.showSuccessToast('Info', 'Ansprechpartner wurde erfolgreich bearbeitet');
          }, error => {
            this.updateService.errorHandler(error);
            this.updateService.commonsService.showErrorToast(error, 'Ansprechpartner konnte nicht bearbeitet werden [...]', -1);
          }
        );

      }
      this.checkFinish(a, b);
    }


  }

  checkFinish(a, b) {
    if (a === b) {
      this.modal.close();
    }
  }
}
