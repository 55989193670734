<div>

  <div class="modal-header">
    <h4 class="modal-title">Verteiler hinzufügen</h4>
    <button (click)="modal.close()" aria-label="Close" class="close" style="outline: 0; color: #24292e" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">
    <div style="margin-top: 1em">
      <app-input (onEnter)="createDispenser()" [(value)]="name" [manual_request_focus]="request_focus"
                 [request_focus]="true" text="Verteilername"></app-input>

      <div class="box hover-animation non-clickable-object">
        <div class="align-mid-vertical">Sharepoint Foto Ordner erstellen</div>
        <app-switch (valueChange)="switch_override_create_folder()"
                    [value]="override_create_folder== null? true:override_create_folder "></app-switch>
      </div>
    </div>

  </div>
  <div class="modal-footer" style="display: flex; justify-content: space-between; ">
    <div (click)="createDispenser()" class="button button-blue" style="width: 47%">
      Verteiler hinzufügen
    </div>
    <div (click)="modal.close();" class="button button-blue" style="width: 47%">
      Schließen
    </div>
  </div>

</div>
