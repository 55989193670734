import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  save = {};

  constructor() {
  }


  timed_update(index, value, context, callback, args) {
    this.save[index] = value;
    const tmp_value = value;
    setTimeout(() => {
      if (tmp_value === this.save[index]) {
        callback.apply(context, args);
      }
    }, 2000);
  }
}
