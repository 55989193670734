import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeekItem} from '../../../../interfaces/WeekInterface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Project} from '../../../../interfaces/ProjectInterfaces';

@Component({
  selector: 'app-week-description',
  templateUrl: './week-description.component.html',
  styleUrls: ['./week-description.component.scss']
})
export class WeekDescriptionComponent implements OnInit {


  @Output() request_reload: EventEmitter<any> = new EventEmitter();

  @Input() weekItem: WeekItem;
  @Input() project: Project;
  show_more_text;
  show_more_title;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (this.project == null && this.weekItem.project != null) {
      this.project = this.weekItem.project;
    }
  }

  noteTooLong(note) {
    if (note.length < 200) {
      return [note, false];
    } else {
      return [note.slice(0, 200) + '...', true];
    }
  }

  open(content, reload) {
    this.modalService.open(content).result.then((result) => {
      if (reload) {
        this.request_reload.emit(true);
      }
    });
  }

  getWidthOfDiv(name: string) {
    return document.getElementById(name).offsetWidth;
  }

}
