<details class="summary-box dark-III hover-animation">
  <summary class="{{ dispenser_picture ? 'dispenser_0' : 'dispenser_1'}}">
    <div style="display: flex; justify-content: space-between; width: 100%">
      <div class="align-mid-vertical">
        {{dispenser.name}} <br>
        <div>
          <div class="align-mid-vertical"
               style="display: flex;justify-content: start; font-size: 200%; margin-right: 0.75em">
            <span *ngIf="!dispenser.dispenser_documentation">○</span><span *ngIf="dispenser.dispenser_documentation"
                                                                           style="color: green">●</span>
            <span *ngIf="!dispenser.pressure_documentation">○</span><span *ngIf="dispenser.pressure_documentation"
                                                                          style="color: green">●</span>
          </div>
        </div>


      </div>
      <button (click)="open(verify, $event)" class="material-icons delete-button">delete</button>
    </div>


  </summary>

  <div style="padding: 1em">
    <div style="display: flex; justify-content: space-between; margin-top: 20px;">


      <div (click)="open(createDispenserDocumentation, $event)" *ngIf="!dispenser.dispenser_documentation"
           class="button button-grey" style="width: 30% !important">
        Verteilerdokumentation erstellen
      </div>


      <div (click)="openDispenserDocumentation()" *ngIf="dispenser.dispenser_documentation" class="button button-grey"
           style="width: 30% !important">
        Verteilerdokumentation öffnen
      </div>


      <div (click)="open(createPressureDocumentation, $event)" *ngIf="!dispenser.pressure_documentation"
           class="button button-grey" style="width: 30% !important">
        Dichtheitsprüfung erstellen
      </div>

      <div (click)="openPressureDocumentation(dispenser.id)" *ngIf="dispenser.pressure_documentation"
           class="button button-grey" style="width: 30% !important">
        Dichtheitsprüfung öffnen
      </div>


      <div (click)="openPictures()" class="button button-grey" style="width: 30% !important">Fotos öffnen</div>
    </div>
    <br>
    <div class="small-information-box hover-animation">
      <b>Vom</b> {{formatDate(dispenser.start_date)}} <b>bis zum</b> {{formatDate(dispenser.end_date)}}<br><br>
      <b>Druckdokumentation:</b>

      <p *ngIf="dispenser.pressure_documentation">Erledigt</p>
      <p *ngIf="!dispenser.pressure_documentation">noch offen</p>
      <br>
      <b>Verteilerdokumentation:</b>
      <p *ngIf="dispenser.dispenser_documentation">Erledigt</p>
      <p *ngIf="!dispenser.dispenser_documentation">noch offen</p>

      <div *ngIf="dispenser.creator_name != null">
        <br>
        <b>Verteiler-Ersteller:</b>
        <ul>
          <li>{{dispenser.creator_name}}</li>
        </ul>
      </div>
      <div *ngIf="dispenser.documentation_user_name != null">
        <br>
        <b>Verteilerdokumentation-Ersteller:</b>
        <ul>
          <li>{{dispenser.documentation_user_name}}</li>
        </ul>
      </div>
    </div>

  </div>
</details>

<ng-template #verify let-modal>
  <app-sharepoint-delete (sharepoint_delete)="verified($event)" [modal]="modal"
                         [type]="'Verteiler (' + dispenser.name + ')'"></app-sharepoint-delete>
</ng-template>


<ng-template #createDispenserDocumentation let-modal>
  <app-create-dispenser-documentation [dispenser_id]="dispenser.id" [modal]="modal"
                                      [project]="project"></app-create-dispenser-documentation>
</ng-template>

<ng-template #createPressureDocumentation let-modal>
  <app-create-pressure-documentation
    [dispenser_id]="dispenser.id"
    [modal]="modal"
    [project]="project"></app-create-pressure-documentation>
</ng-template>
