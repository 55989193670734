import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../services/data/data.service';

@Component({
  selector: 'app-week-select',
  templateUrl: './week-select.component.html',
  styleUrls: ['./week-select.component.scss']
})
export class WeekSelectComponent implements OnInit {

  @Input() modal;
  @Input() week;
  @Output() choose_week: EventEmitter<number> = new EventEmitter<number>();

  changeNumber = new EventEmitter<number>();
  week_number;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.week_number = this.week;
  }


  calculateWeek(date) {
    this.week_number = this.dataService.getWeekNumber(new Date(date));
    this.changeNumber.emit(this.week_number);
    return this.week_number;

  }

  submit() {

    this.choose_week.emit(this.week_number);
    this.modal.close();
  }
}
