import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from './services/login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Constants} from './Constants';
import {CommonsService} from "./services/commons/commons.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit, OnDestroy {
  queryParams;
  ws: WebSocket;
  reconnect = true;
  reconnectFails = 0;

  constructor(private loginService: LoginService, private router: Router, private route: ActivatedRoute, private commonsService: CommonsService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async data => {
      this.queryParams = data;

      let incoming_route = window.location.pathname;

      if (await this.loginService.verifyToken()) {
        // this.connectWebsocket().then();
        this.loginService.logged_in_state.emit(true);


        // dont navigate to login page, because token is valid
        if (incoming_route === '/login') {
          incoming_route = this.router.url;
        }

        if (incoming_route !== this.router.url) {
          await this.router.navigate([incoming_route], {queryParams: this.queryParams});
        }
      } else {
        await this.router.navigate(['/login']);
      }
    });


  }

  async connectWebsocket() {
    return false;
    // TODO change URL
    this.reconnectFails = this.reconnectFails + 1;
    if (this.reconnectFails >= 5) {
      return;
    }
    this.ws = new WebSocket(Constants.WS_SERVER_URL + 'ws');
    this.ws.onmessage = (event) => this.handleCommand(event);

    this.ws.onopen = (data) => {
      console.log('WS open');
    };
    this.ws.onclose = (data) => {
      this.connectWebsocket();
      console.log('WS closed');
    };
  }

  async handleCommand(text) {
    const command = JSON.parse(text.data);
    if (command.hasOwnProperty('command')) {
      if (command.command === 'REQUIRE_AUTH') {
        await this.ws.send(JSON.stringify({
          command: 'LOGIN_SESSION',
          session: this.loginService.getValue('session_token')
        }));
      } else if (command.command === 'AUTH_FAILED') {
        this.reconnectFails = 10;
      } else if (command.command === 'NOT_AVAILIABLE_YET') {
        this.reconnectFails = 10;
      } else if (command.command === 'AUTH_SUCCESSFUL') {
        this.reconnectFails = 0;
      } else if (command.command === 'UPDATE_PROJECT') {
        // TODO handle this
      } else if (command.command === 'UPDATE_WEEK_PLAN') {
        // TODO handle this
      } else if (command.command === 'UPDATE_FLOOR') {
        // TODO handle this
      }
    } else {
      console.log('Maleformed Server response');
    }
  }

  ngOnDestroy() {
    if (this.ws != null) {
      this.ws.close();
    }
  }

}
